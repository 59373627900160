import React, { useEffect, useState } from "react";
import zxcvbn from "zxcvbn";
import { useFormik } from "formik";
import * as yup from "yup";
import linkedinBtn from "@/_metronic/assets/registerlogin/linkedinBtn.svg";
import see from "@/_metronic/assets/registerlogin/see.svg";
import hide from "@/_metronic/assets/registerlogin/hide.svg";
import attach from "@/_metronic/assets/registerlogin/attach.svg";

interface CandidateRegistrationProps {
  onNext: (data: any) => void;
}

const validationSchema = yup.object({
  resume: yup.mixed().required("Please upload your resume"),
  fullname: yup.string().required("Please enter your full name"),
  username: yup.string().required("Please enter your username"),
  email: yup
    .string()
    .email("Please enter an email address")
    .required("Email is required"),
  confirmEmail: yup
    .string()
    .oneOf(
      [yup.ref("email"), undefined],
      "The confirm email address confirmation does not match"
    )
    .required("Confirm Email is required"),
  password: yup
    .string()
    .min(8, "Password length must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), undefined],
      "The confirm password confirmation does not match"
    )
    .required("Confirm Password is required"),
  termsAccepted: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions"),
});

interface PasswordStrengthBarProps {
  password: string;
}

const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({
  password,
}) => {
  const [passwordStrength, setPasswordStrength] = useState(0);

  useEffect(() => {
    if (password) {
      const result = zxcvbn(password);
      setPasswordStrength(result.score);
    } else {
      setPasswordStrength(0);
    }
  }, [password]);

  const getBarStyle = (index: number) => {
    const styles = [
      { width: "18.22%", backgroundColor: "#FE0000" },
      { width: "11.68%", backgroundColor: "#FF8E01" },
      { width: "11.68%", backgroundColor: "#36C5F1" },
      { width: "18.22%", backgroundColor: "#34B53A" },
    ];

    const activeStyle = styles[index];
    return {
      width: activeStyle.width,
      backgroundColor:
        passwordStrength >= index + 1 ? activeStyle.backgroundColor : "#D9D9D9",
      transition: "background-color 0.4s ease",
    };
  };

  return (
    <div className="w-full h-[6px] mt-[7px] flex gap-[10px]">
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          className="h-full rounded-[20px]"
          style={getBarStyle(index)}
        ></div>
      ))}
    </div>
  );
};

const CandidateRegistration: React.FC<CandidateRegistrationProps> = ({
  onNext,
}) => {
  const formik = useFormik({
    initialValues: {
      resume: "test_resume.pdf",
      fullname: "John Doe",
      username: "john_doe",
      email: "johndoe@example.com",
      confirmEmail: "johndoe@example.com",
      password: "password1234",
      confirmPassword: "password1234",
      termsAccepted: true,
    },
    validationSchema,
    onSubmit: (values) => {
      onNext(values);
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col h-max mt-[36px] ">
      <form
        onSubmit={formik.handleSubmit}
        className="h-max px-[10px] 2xl:px-[0]"
      >
        <div className="mb-4">
          <label
            htmlFor="resume"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Resume/CV *
          </label>
          <div className="flex flex-col sm:flex-row sm:items-center gap-[10px] sm:gap-[42px] mt-2">
            <div className="relative">
              <input
                type="file"
                id="resume"
                name="resume"
                onChange={(event) =>
                  formik.setFieldValue(
                    "resume",
                    event.target.files?.[0] || null
                  )
                }
                className={`nunitoregular text-[14px] block w-full sm:w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                  formik.errors.resume ? "border-[#FE0000]" : "border-[#ADADAD]"
                }`}
              />
              <img
                src={attach}
                alt="Attach Icon"
                className="absolute right-[16px] top-1/2 transform -translate-y-1/2"
              />
            </div>
            <span className="nunitoregular text-[14px] text-[#B0B0B0]">or</span>
            <div className="flex items-center gap-[8px]">
              <span className="nunitoregular text-[14px] text-[#2B2F42]">
                Fill with;
              </span>
              <button
                type="button"
                className="w-[183px] h-[46px] rounded-[70px]"
              >
                <img
                  className="w-full h-full"
                  src={linkedinBtn}
                  alt="Linkedin Button"
                />
              </button>
            </div>
          </div>
          {formik.errors.resume && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.resume}
            </div>
          )}
        </div>

        <div className="mb-4 w-full">
          <label
            htmlFor="fullname"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Fullname *
          </label>
          <input
            id="fullname"
            name="fullname"
            type="text"
            placeholder="Emre Yıldız"
            value={formik.values.fullname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-100 md:w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.fullname && formik.errors.fullname
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.fullname && formik.errors.fullname && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.fullname}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Username *
          </label>
          <input
            id="username"
            name="username"
            type="text"
            placeholder="meyildiz_"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.username && formik.errors.username
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.username && formik.errors.username && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.username}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Email Address *
          </label>
          <input
            id="email"
            name="email"
            type="email"
            placeholder="meyildiz@example.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.email && formik.errors.email
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.email}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="confirmEmail"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Confirm Email Address *
          </label>
          <input
            id="confirmEmail"
            name="confirmEmail"
            type="email"
            placeholder="meyildiz@example.com"
            value={formik.values.confirmEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.confirmEmail && formik.errors.confirmEmail
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.confirmEmail && formik.errors.confirmEmail && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.confirmEmail}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Password *
          </label>
          <div className="relative">
            <input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="+8 characters"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.password && formik.errors.password
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            <img
              className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                showPassword ? "hidden" : "block"
              }`}
              src={see}
              alt="Show password"
              onClick={togglePasswordVisibility}
            />
            <img
              className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                showPassword ? "block" : "hidden"
              }`}
              src={hide}
              alt="Hide password"
              onClick={togglePasswordVisibility}
            />
          </div>
          <PasswordStrengthBar password={formik.values.password} />
          {formik.touched.password && formik.errors.password && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.password}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="confirmPassword"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Confirm Password *
          </label>
          <div className="relative">
            <input
              id="confirmPassword"
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              placeholder="+8 characters"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            <img
              className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                showPassword ? "hidden" : "block"
              }`}
              src={see}
              alt="Show password"
              onClick={togglePasswordVisibility}
            />
            <img
              className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                showPassword ? "block" : "hidden"
              }`}
              src={hide}
              alt="Hide password"
              onClick={togglePasswordVisibility}
            />
          </div>
          <PasswordStrengthBar password={formik.values.confirmPassword} />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.confirmPassword}
            </div>
          )}
        </div>

        <div className="my-[30px]">
          <div className="flex items-center gap-[13px]">
            <input
              type="checkbox"
              id="termsAccepted"
              name="termsAccepted"
              checked={formik.values.termsAccepted}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="mr-2 leading-tight shrink-0 checkbox "
            />
            <label
              htmlFor="termsAccepted"
              className="nunitoregular text-[14px] text-[#2B2F42]"
            >
              I agree to OptiRecruit's{" "}
              <a href="#" className="nunitoregular text-[#02A0FC]">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" className="nunitoregular text-[#02A0FC]">
                Privacy Policy
              </a>
              .
            </label>
          </div>
          {formik.touched.termsAccepted && formik.errors.termsAccepted && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.termsAccepted}
            </div>
          )}
        </div>

        <button
          type="submit"
          className="nunitomedium w-full h-[60px] sm:h-[79.55px] bg-[#FE0000] text-white p-3 rounded-[40px] text-[18px] sm:text-[22px] hover:bg-red-600"
        >
          Next
        </button>

        <p className="nunitosbold text-[22px] mt-[30px] text-center">
          Already have an account?{" "}
          <a href="/auth" className="text-[#02A0FC]">
            Sign in.
          </a>
        </p>
      </form>
    </div>
  );
};

export default CandidateRegistration;
