// jobPostResponse.ts
// Assuming this is your JobPostResponse file, we add countryName to PostModel

// export interface PostModel {
//   jobTitle: string;
//   companyName: string;
//   companyLogo: string;
//   postStatus: number;
//   scheduleDate: string;
//   companyWebsite: string;
//   quota: number;
//   modeOfOperation: number;
//   experience: number;
//   countryId: string; // Added this line
//   cityName: string;
// }

// export interface JobPostModel {
//   post: PostModel;
// }

// main component file

import "./job.css";
// import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
// import { JobPostModel } from "@/api/models/JobPostResponse";

interface CreateJobPostProps {
  height: string;
}

function CreatejobPost(props: CreateJobPostProps) {
  // const [tabledata, setTabledata] = useState<JobPostModel[]>([]);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");

  // useEffect(() => {
  // const temporaryData: JobPostModel[] = [
  //   {
  //     post: {
  //       jobTitle: "Frontend Developer",
  //       companyName: "Tech Company",
  //       companyLogo: "/media/logo-small.svg",
  //       postStatus: 1,
  //       scheduleDate: new Date().toISOString(),
  //       companyWebsite: "",
  //       quota: 0,
  //       modeOfOperation: 0,
  //       experience: 5,
  //       countryName: "", // Country name added here
  //       cityName: "",
  //     },
  //   },
  //   {
  //     post: {
  //       jobTitle: "Backend Developer",
  //       companyName: "Backend Solutions",
  //       companyLogo: "",
  //       postStatus: 0,
  //       scheduleDate: new Date(
  //         Date.now() + 3 * 24 * 60 * 60 * 1000
  //       ).toISOString(),
  //       companyWebsite: "",
  //       quota: 0,
  //       modeOfOperation: 1,
  //       experience: 3,
  //       countryName: "", // Country name added here
  //       cityName: "",
  //     },
  //   },
  // ];
  // setTabledata(temporaryData);
  // Uncomment below if you need to fetch data from API in the future
  // /*
  // const fetchCandidates = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await getAll();
  //     setTabledata(response.Data);
  //   } catch (err) {
  //     setError("Veri yüklenirken hata oluştu");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // fetchCandidates();
  // */
  // }, []);

  interface JobPost {
    jobPost: string;
    experience: string;
    deadline: string;
    status: string;
    applicationrecords: number;
    companyLogo: string;
    company: string;
  }

  const data: JobPost[] = [
    {
      companyLogo: "/public/media/svg/general/discord.svg",
      jobPost: "UI/UX Designer",
      experience: "5-10 Year",
      deadline: "3 Days",
      status: "ACTIVE",
      applicationrecords: 125,
      company: "Discord",
    },
    {
      companyLogo: "/public/media/svg/general/spotify.svg",
      jobPost: "UX Designer",
      experience: "5-10 Year",
      deadline: "1 Day",
      status: "ACTIVE",
      applicationrecords: 125,
      company: "Spotify",
    },
    {
      companyLogo: "/public/media/svg/general/slack.svg",
      jobPost: "Visual Designer",
      experience: "5-10 Year",
      deadline: "3 Days",
      status: "DEACTIVE",
      applicationrecords: 125,
      company: "Slack",
    },
  ];

  const statusBodyTemplate = (rowData: JobPost) => {
    const statusClass =
      rowData.status === "ACTIVE"
        ? "flex justify-center items-center w-[90px] h-[29px] bg-[#34B53A26] text-[14px] text-[#34B53A]  rounded-[20px] nunitobold"
        : "flex justify-center items-center w-[90px] h-[29px] bg-[#FE000026] text-[14px] text-[#FE0000] rounded-[20px] nunitobold";
    return <span className={statusClass}>{rowData.status}</span>;
  };

  const jobPostTemplate = (rowData: JobPost) => {
    return (
      <div className="flex items-center gap-[10px] px-[10px] lg:px-0 py-2 rounded">
        <div className="w-[70px] h-[70px] flex justify-center items-center bg-white rounded-full shrink-0">
          <img
            src={rowData.companyLogo}
            alt={rowData.jobPost}
            className="w-[40px] h-[40px]  "
          />
        </div>
        <div>
          <div className="nunitomedium text-[22px] text-[#2B2F42] leading-[30.01px] text-left">
            {rowData.jobPost}
          </div>
          <div className="nunitomedium text-[14px] text-[#2B2F4299]">
            {rowData.company}
          </div>
        </div>
      </div>
    );
  };

  const deadlineBodyTemplate = (rowData: JobPost) => {
    const isOneDay = rowData.deadline === "1 Day";
    return (
      <div className="flex flex-col items-start text-[18px] text-left px-[10px] lg:px-0 py-7 rounded">
        <div className="flex items-center leading-[20px]">
          <span>{rowData.deadline}</span>{" "}
          {isOneDay && (
            <img
              src="/public/media/svg/general/report.svg"
              alt="Report"
              className="w-[18px] h-[18px] ml-2"
            />
          )}
        </div>
        <div className="leading-[20px]">
          <span className="nunitomedium text-[14px] text-[#00000099]">
            Last
          </span>
        </div>
      </div>
    );
  };

  const experienceTemplate = (rowData: JobPost) => {
    return (
      <div className="flex justify-between items-center font-nunito text-[18px] leading-[30.01px] text-left px-[10px] lg:px-2 py-2 rounded ">
        <div className="flex flex-col">
          <div className="flex items-center leading-[20px]">
            <span>{rowData.applicationrecords}</span>
          </div>
          <div className="leading-[20px]">
            <span className="nunitomedium text-[14px] text-[#00000099]">
              Year
            </span>
          </div>
        </div>
      </div>
    );
  };

  const applicationTemplate = (rowData: JobPost) => {
    return (
      <div className="flex justify-between items-center font-nunito text-[18px] leading-[30.01px] text-left px-[10px] lg:px-2 py-2 rounded">
        <div className="flex flex-col">
          <div className="flex items-center leading-[20px]">
            <span>{rowData.applicationrecords}</span>
          </div>
          <div className="leading-[20px]">
            <span className="nunitomedium text-[14px] text-[#00000099]">
              Application
            </span>
          </div>
        </div>
        <i className="pi pi-chevron-right text-gray-400 text-xl"></i>
      </div>
    );
  };

  const rowClass = (data: JobPost) => {
    return {
      "custom-row": true,
      "bg-[#f4f4f4]": true,
    };
  };

  return (
    <div className="cmax-w-[1800px] py-[33px] px-[10px] xxl:pl-[90px] xxl:pr-[61px] min-h-screen h-full bg-[#F4F4F4]">
      <div>
        <h1 className="nunitomedium text-[30px] text-[#2B2F42] mb-0">Jobs</h1>
        <p className="nunitomedium text-[14px] text-[#2B2F4266] mb-0">
          Let's take a look jobs.
        </p>
      </div>
      <div className="w-full flex justify-end items-center">
        <div className="flex items-center gap-[15px]">
          <span className="nunitomedium text-[14px] text-[#2B2F42] ">
            Sort by{" "}
          </span>{" "}
          <span className="flex items-center gap-[6px] text-[14px] nunitobold800">
            Name{" "}
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4.5 0L8.39711 3H0.602886L4.5 0Z" fill="#2B2F42" />
              <path d="M4.5 8L0.602886 5L8.39711 5L4.5 8Z" fill="#2B2F42" />
            </svg>
          </span>
          <div className="flex items-center gap-[11px]  px-[16px] py-[11px] border-[1px] border-[#b0b0b0] rounded-[10px] cursor-pointer">
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.04167 10.6667L1 1H14.2917M20.3333 1L14.2917 10.6667V21.5417L7.04167 17.9167V15.5"
                stroke="#2B2F42"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="nunitomedium text-[14px] text-[#2B2F42]">
              Filters
            </span>
          </div>
        </div>
      </div>
      <div className="mt-[26px]  rounded-[32px] shadow-[0px_-1px_4px_#00000040] shadow-[1px_1px_4px_#00000040] p-[33px]  bg-[#f4f4f4]">
        <DataTable
          value={data}
          rowClassName={rowClass}
          className="bg-[#f4f4f4]"
        >
          <Column
            field="jobPost"
            header="Job Post"
            body={jobPostTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42] leading-[30.01px] text-left px-[10px] lg:px-0 pb-[20px] rounded bg-[#f4f4f4]"
            className="nunitomedium text-[18px] text-[#2B2F42] border-t-2 border-b-2 p-0"
          />
          <Column
            field="experience"
            header="Experience"
            body={experienceTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42]  leading-[30.01px] text-left px-[10px] lg:px-0 pb-[20px]  rounded bg-[#f4f4f4]"
            className="nunitomedium text-[18px] text-[#2B2F42]  border-t-2 border-b-2"
            bodyClassName="font-nunito text-[18px] leading-[30.01px] text-left  px-0 py-2 rounded"
          />
          <Column
            field="deadline"
            header="Deadline"
            body={deadlineBodyTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42] leading-[30.01px] text-left px-[10px] lg:px-0 pb-[20px]  rounded bg-[#f4f4f4]"
            className="nunitomedium text-[18px] text-[#2B2F42] px-0 border-t-2 border-b-2"
          />
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42] leading-[30.01px] text-left   px-[10px] lg:px-0 pb-[20px] rounded bg-[#f4f4f4]"
            className="text-gray-800  border-t-2 border-b-2"
          />
          <Column
            field="applicationrecords"
            header="Application Records"
            body={applicationTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42] leading-[30.01px] text-left  px-[10px] lg:px-0 pb-[20px]  rounded bg-[#f4f4f4]"
            className="nunitomedium text-[18px] text-[#2B2F42] px-0 border-t-2 border-b-2"
          />
        </DataTable>
      </div>
    </div>
  );
}

export default CreatejobPost;
