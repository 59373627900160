import { Toast } from 'primereact/toast';

interface ToastService {
  success(message: string): void;
  error(message: string): void;
  warn(message: string): void;
  info(message: string): void;
}

let toast: Toast;

const ToastService: ToastService = {
  success(message: string) {
    toast.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
  },
  error(message: string) {
    toast.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  },
  warn(message: string) {
    toast.show({ severity: 'warn', summary: 'Warning', detail: message, life: 3000 });
  },
  info(message: string) {
    toast.show({ severity: 'info', summary: 'Info', detail: message, life: 3000 });
  },
};

export const initToastService = (toastInstance: Toast) => {
  toast = toastInstance;
};

export default ToastService;