import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { RecommendedJobModel } from "../models/recommended-job-model";

interface RecommendedJobProps {
  data: RecommendedJobModel;
}

function RecommendedJob({ data }: RecommendedJobProps) {
  return (
    <Link to="/jobs">
      <div
        className={
          "w-full justify-between gap-[15px] w-full    rounded-[32px] flex flex-col lg:flex-row w-full bg-[#F4F4F4] min-h-[174px]  shadowds1"
        }
      >
        <div
          className={
            " pb-[23px] flex flex-row gap-[25px] w-fit h-fit items-start rounded-[32px] pr-[0px] pt-[16px] pl-[22px] pb-[10px]"
          }
        >
          <div className={"flex flex-col items-center gap-[10px]"}>
            <div
              className={
                "w-[74px] flex h-[74px] bg-white rounded-full fex justify-center items-center"
              }
            >
              <img
                className={"w-[70px] h-[70px] rounded-full"}
                src={"media/dashboard/discord.png"}
                alt=""
              />
            </div>
            <h6
              className={
                "nunitomedium text-[14px] text-opacity-40 text-[#2B2F42] mb-0"
              }
            >
              Discord
            </h6>
          </div>
          <div className={"flex flex-col"}>
            <h6 className={"text-[22px] nunitoregular text-[#2B2F42]"}>
              UI/UX Designer
            </h6>
            <h6
              className={
                "text-[14px] text-opacity-40 nunitomedium text-[#2B2F42] mb-0"
              }
            >
              Berlin, Germany <br /> (Remote)
            </h6>
            <div className={"mt-[25px] flex gap-[5px] flex-row items-center"}>
              <img
                className={"w-[42px] h-[42px] rounded-full"}
                src={"media/dashboard/profile1.png"}
                alt=""
              />
              <h6
                className={
                  "flex-1 text-[11px] text-opacity-40 nunitomedium text-[#2B2F42] mb-0"
                }
              >
                Your profile matches this job
              </h6>
            </div>
          </div>
        </div>
        <div
          className={
            "bg-[#F0F0F0] px-[15px] py-4  gap-[22px] rounded-b-[0px] lg:items-start items-center lg:py-[15px] h-full flex flex-col justify-start items-start   lg:rounded-r-[32px] w-full lg:w-[40%]"
          }
        >
          <div className={"w-full flex justify-between items-center flex-row"}>
            <h6
              className={
                "nunitomedium text-[10px] text-[#2B2F42] text-opacity-40"
              }
            >
              9 days ago
            </h6>
            <h6
              className={
                "nunitomedium text-[10px] text-[#2B2F42] text-opacity-40"
              }
            >
              +100
            </h6>
          </div>
          <div className={" flex flex-col gap-[4px]"}>
            <h6
              className={
                "nunitomedium text-[14px] text-[#2B2F42] text-opacity-40"
              }
            >
              11-50 Employees
            </h6>
            <h6
              className={
                "nunitomedium text-[14px] text-[#2B2F42] text-opacity-40"
              }
            >
              85% match your profile
            </h6>
            <div></div>
          </div>
          <div className={"flex flex-row items-center gap-2"}>
            <svg
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2273 0H1.77273C1.30257 0 0.851671 0.184374 0.51922 0.512563C0.186769 0.840752 0 1.28587 0 1.75V14L6.5 7.61425L13 14V1.75C13 1.28587 12.8132 0.840752 12.4808 0.512563C12.1483 0.184374 11.6974 0 11.2273 0Z"
                fill="#4339F2"
              />
            </svg>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 0C1.34315 0 0 1.34315 0 3V11C0 12.6569 1.34315 14 3 14H11C12.6569 14 14 12.6569 14 11V3C14 1.34315 12.6569 0 11 0H3ZM5.78908 10.9319C5.88165 10.9768 5.98262 11 6.08484 11C6.10476 11.0001 6.124 10.9984 6.14383 10.9965C6.25576 10.9865 6.3637 10.9486 6.45848 10.8863C6.55327 10.8239 6.6321 10.7387 6.6883 10.6381L10.9064 3.08327C10.999 2.91744 11.0241 2.72035 10.9763 2.53536C10.9284 2.35037 10.8114 2.19262 10.6511 2.09683C10.4908 2.00104 10.3002 1.97504 10.1214 2.02456C9.94256 2.07408 9.79005 2.19506 9.69744 2.36088L5.97974 9.02152L4.26091 6.88734C4.20446 6.80798 4.13292 6.74141 4.05067 6.69171C3.96843 6.64201 3.87723 6.61024 3.78268 6.59834C3.68812 6.58645 3.59222 6.59469 3.50086 6.62256C3.40949 6.65042 3.32461 6.69733 3.25142 6.76038C3.17823 6.82343 3.11829 6.90131 3.07527 6.98921C3.03226 7.07712 3.00708 7.17319 3.00129 7.27153C2.9955 7.36987 3.00922 7.4684 3.04161 7.56105C3.07399 7.65371 3.12436 7.73853 3.18961 7.8103L5.54919 10.7405C5.61461 10.8217 5.69651 10.8871 5.78908 10.9319Z"
                fill="#34B53A"
              />
            </svg>

            <p className={"nunitomedium text-[12px] text-[#4339F2] mb-0"}>
              You saved this job
            </p>
            <p
              className={"nunitomedium text-[12px] text-[#34B53A] mb-0 hidden"}
            >
              You already applied for this job
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default RecommendedJob;
