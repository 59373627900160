import React, { useEffect, useState } from 'react';
import { Role, Company } from '../enums/candidateEnums';
import { JobModel } from '../model';
import axios from 'axios';

const initialValues = [
    {
        title: Role.UX_Researcher,
        company: Company.discord,
        location: 'Istanbul (On-site)',
        appliedTime: 'Applied 5h ago',
        icon: 'https://cdn-icons-png.flaticon.com/512/2111/2111370.png',
    },
    {
        title: Role.Graphic_Designer,
        company: Company.discord,
        location: 'Istanbul (On-site)',
        appliedTime: 'Applied 5h ago',
        icon: 'https://cdn-icons-png.flaticon.com/512/2111/2111370.png',
    },
    {
        title: Role.UX_Writer,
        company: Company.discord,
        location: 'Istanbul (On-site)',
        appliedTime: 'Applied 5h ago',
        icon: 'https://cdn-icons-png.flaticon.com/512/2111/2111370.png',
    },
    {
        title: Role.UI_Designer,
        company: Company.discord,
        location: 'Istanbul (On-site)',
        appliedTime: 'Applied 5h ago',
        icon: 'https://cdn-icons-png.flaticon.com/512/2111/2111370.png',
    },
];

const GET_API_URL = "URL";

export const CandidateProfileJobs: React.FC = () => {

    const [jobs, setJobs] = useState<JobModel[]>(initialValues);

    useEffect(()=>{

       axios.get(GET_API_URL).then((response: any)=> {
        if (response.result != null) {
            setJobs(response.result);
        }     
    });

    },[]);

    return (
        <div className="max-w-[1425px] mx-auto p-6 bg-transparent border border-gray-400 rounded-[32px] shadow-lg mt-8">
            <h2 className="text-left text-[#2B2F42] font-nunito font-medium text-[20px] leading-[27.28px] mb-4">
                Jobs
            </h2>
            <hr className="border-black mb-10" />
            <div className="space-y-2 ml-20">
                {jobs.map((job, index) => (
                    <div key={index} className="flex items-center justify-between  border-b border-gray-400">
                        <div className="flex items-center">
                            <img src={job.icon} alt={job.title} className="w-10 h-10 mr-6" />
                            <div>
                                <h3 className="font-semibold">{job.title}</h3>
                                <p className="text-gray-600">{job.company}<br></br>{job.location}<br></br>{job.appliedTime}</p>
                            </div>
                        </div>
                        <div className="text-black">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 12h.01M12 12h.01M18 12h.01" />
                            </svg>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CandidateProfileJobs;
