import { useIntl } from "react-intl";
import avatar from "@/../public/media/avatars/300-1.jpg";
import clsx from "clsx";
import { Link } from "react-router-dom";
const userAvatarClass = "symbol-35px";

export function MenuInner() {
  const intl = useIntl();
  return (
    <div className="flex flex-col justify-center gap-[7px]">
      <div className="flex w-full justify-between items-center">
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <div className="relative w-[42px] h-[42px]  mr-[10px]">
            <img
              src={avatar}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
            {/* if online */}
            <div className="w-[10px] h-[10px] rounded-full bg-[#00D95F] absolute top-0 right-0"></div>
            {/* if offline */}
            <div className="w-[10px] h-[10px] rounded-full bg-[#00D95F] absolute top-0 right-0"></div>
          </div>
        </div>
        <Link to="/">
          <i className="bi bi-bell text-[26px] text-[#2B2F42] mr-0 xxl:mr-[33px]"></i>
        </Link>
      </div>
      <div className="fw-bolder d-flex align-items-center fs-5">
        admin admin
      </div>
      <div>
        <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
          admin@assatekno.com
        </a>
      </div>
      <div>
        <a href="/crafted/pages/profile" className="text-gray-600">
          My Profile
        </a>
      </div>
      <div>
        <a href="#" className="text-gray-600">
          Sign Out
        </a>
      </div>
    </div>
  );
}
