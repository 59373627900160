import React, { useState } from "react";
import "./job.css";
import positionsEmptyImg from "@/../public/media/icons/positions-card.svg";
import Chart from "react-apexcharts";

const BagIcon = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 3.33333H14.9167C14.7233 2.39284 14.2115 1.54779 13.4677 0.940598C12.7239 0.333408 11.7935 0.0012121 10.8333 0L9.16667 0C8.2065 0.0012121 7.2761 0.333408 6.53229 0.940598C5.78848 1.54779 5.27675 2.39284 5.08333 3.33333H4.16667C3.062 3.33466 2.00296 3.77407 1.22185 4.55518C0.440735 5.3363 0.00132321 6.39534 0 7.5L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H15.8333C16.938 19.9987 17.997 19.5593 18.7782 18.7782C19.5593 17.997 19.9987 16.938 20 15.8333V7.5C19.9987 6.39534 19.5593 5.3363 18.7782 4.55518C17.997 3.77407 16.938 3.33466 15.8333 3.33333ZM9.16667 1.66667H10.8333C11.3486 1.6688 11.8505 1.83007 12.2706 2.12841C12.6906 2.42674 13.0083 2.84758 13.18 3.33333H6.82C6.99174 2.84758 7.30936 2.42674 7.72942 2.12841C8.14948 1.83007 8.65145 1.6688 9.16667 1.66667ZM4.16667 5H15.8333C16.4964 5 17.1323 5.26339 17.6011 5.73223C18.0699 6.20107 18.3333 6.83696 18.3333 7.5V10H1.66667V7.5C1.66667 6.83696 1.93006 6.20107 2.3989 5.73223C2.86774 5.26339 3.50363 5 4.16667 5ZM15.8333 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V11.6667H9.16667V12.5C9.16667 12.721 9.25446 12.933 9.41074 13.0893C9.56703 13.2455 9.77899 13.3333 10 13.3333C10.221 13.3333 10.433 13.2455 10.5893 13.0893C10.7455 12.933 10.8333 12.721 10.8333 12.5V11.6667H18.3333V15.8333C18.3333 16.4964 18.0699 17.1323 17.6011 17.6011C17.1323 18.0699 16.4964 18.3333 15.8333 18.3333Z"
      fill="#2B2F42"
      fillOpacity={0.5}
    />
  </svg>
);

const ChevronRightIcon = () => {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.63332 8C1.42 8 1.20667 7.92159 1.04417 7.76558C0.718349 7.45276 0.718349 6.94712 1.04417 6.6343L3.79827 3.99011L1.14834 1.35552C0.829179 1.03709 0.838345 0.530657 1.16917 0.224234C1.50083 -0.082188 2.02831 -0.0733874 2.34747 0.243436L5.56572 3.44367C5.88154 3.75809 5.87738 4.25653 5.55572 4.56535L2.22248 7.76558C2.05998 7.92159 1.84665 8 1.63332 8Z"
        fill="#2B2F42"
      />
    </svg>
  );
};

function CreatejobCompanies() {
  const [activeTab, setActiveTab] = useState("active");

  return (
    <div className="cmax-w-[1800px] py-[33px] px-[10px] xxl:pl-[90px] xxl:pr-[61px] min-h-screen h-full bg-[#F4F4F4]">
      <div>
        <h1 className="nunitomedium text-[30px] text-[#2B2F42] mb-0">
          Companies
        </h1>
        <p className="nunitomedium text-[14px] text-[#2B2F4266] mb-0">
          Let's take a look positions.
        </p>
      </div>
      <div className="w-full border-b-[2px] border-b-[#B0B0B0] flex mt-[50px] cursor-pointer">
        <div
          className={`nunitobold800 w-[111px] text-center relative text-[14px] transition-all duration-400 ${
            activeTab === "active" ? "text-[#2B2F42]" : "text-[#2B2F4280]"
          }`}
          onClick={() => setActiveTab("active")}
        >
          Company
          {activeTab === "active" && (
            <div className="w-full h-[2px] bg-[#FF8E01] absolute transition-all duration-400"></div>
          )}
        </div>
      </div>
      <div className="flex flex-col xxl:flex-row xxl:items-center mt-[43px] gap-[8%]  mb-[12px]">
        <div className="nunitosbold text-[22px] text-[#2B2F42]">
          3 Companies{" "}
        </div>
        <div className="flex items-center gap-[10px] xxl:gap-[17%]">
          <div className="flex items-center gap-[11px]">
            <span className="nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              Sort by{" "}
            </span>{" "}
            <span className="flex items-center gap-[6px] text-[14px] nunitobold800 whitespace-nowrap">
              Creation Date{" "}
              <svg
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.5 0L8.39711 3H0.602886L4.5 0Z" fill="#2B2F42" />
                <path d="M4.5 8L0.602886 5L8.39711 5L4.5 8Z" fill="#2B2F42" />
              </svg>
            </span>
          </div>
          <div className="flex items-center gap-[11px]">
            <span className="nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              Status{" "}
            </span>{" "}
            <span className="flex items-center gap-[6px] text-[14px] nunitobold800 whitespace-nowrap">
              All Status{" "}
              <svg
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.5 0L8.39711 3H0.602886L4.5 0Z" fill="#2B2F42" />
                <path d="M4.5 8L0.602886 5L8.39711 5L4.5 8Z" fill="#2B2F42" />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-[18px]">
        <div
          className={`w-full sm:w-[270px] h-max-content shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] rounded-[8px] border-t-[4px] border-t-[#FF8E01]`}
        >
          <div className="px-[30px]">
            <div className="w-[120px] h-[120px] rounded-full overflow-hidden mx-auto mt-[20px]">
              <img
                className="w-full h-full object-cover"
                src={positionsEmptyImg}
                alt=""
              />
            </div>
            <div className="nunitobold800 text-[14px] text-[#2B2F4266] text-center mt-[18px]">
              100-1000 Employees
            </div>
            <div className="nunitobold800 text-[18px] text-[#2B2F42] text-center mt-[15px]">
              DISCORD
            </div>
            <div className="nunitobold800 text-[14px] text-[#2B2F4299] text-center">
              Company
            </div>

            <div className="flex justify-center items-center gap-[10px] mt-[18px]">
              <div className="nunitosbold text-[14px] text-[#2B2F4266]">
                London
              </div>
              <div className="w-[5px] h-[5px] bg-[#2B2F4266] rounded-full"></div>
              <div className="nunitosbold text-[14px] text-[#2B2F4266]">UK</div>
            </div>
          </div>
          <div className="px-[30px] py-[15px] mt-[24px] flex justify-between border-t-[1px] border-t-[#B0B0B080]">
            <div className="flex items-center gap-[10px]">
              <BagIcon />
              <div className="nunitosbold text-[10px] text-[#2B2F4280]">
                Jobs
              </div>
            </div>
            <div className="flex items-center gap-[8px] ">
              <div className="nunitosbold text-[10px] text-[#2B2F4280]">
                See Details
              </div>
              <ChevronRightIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatejobCompanies;
