import React, { useEffect, useState } from 'react';
import {SumamryModel} from '../model';
import { Education, Gender, LanguageLevel, Role } from '../enums/candidateEnums';
import axios from 'axios';

const initialValue =  {
    id: '1',
    name: 'Merve Gündoğan',
    reference: '13UXY94',
    gender: Gender.Female,
    education: Education.Bachelors_Degree,
    university: 'Istanbul University',
    currentCompany: 'Assa Tech',
    currentPosition: Role.UX_Researcher,
    candidateLocation: 'Beyoğlu/Istanbul',
    birthDate: new Date('1994-03-05'),
    emailAddress: 'mervegundogan@gmail.com',
    skills: ['UX Design', 'A/B Testing', 'User Testing', 'Data Analysis', 'Metrics', 'Team work', 'Project Management', 'Creativity', 'Relationship Management', 'Prototyping', 'Problem Solving', 'Wireframing'],
    languages: [
        { language: 'Dutch', writing: LanguageLevel.Intermediate, listening: LanguageLevel.UpperIntermediate, speaking: LanguageLevel.Intermediate, icon: "" },
        { language: 'English', writing: LanguageLevel.Advanced, listening: LanguageLevel.Advanced, speaking: LanguageLevel.UpperIntermediate, icon: "" },
        { language: 'Turkish', writing: LanguageLevel.Native, listening: LanguageLevel.Native, speaking: LanguageLevel.Native, icon :"" }
    ],
    recentHistory: [
        { date: new Date('2024-03-16'), updater: 'Emre' }
    ]
};


//icon ları dynamic ekle
// url ekle
//initial value değerini kaldır

const GET_PROFILE_URL = "/URL";


export const CandidateProfileSummary: React.FC = () => {
    const [summary, setSummary] = useState<SumamryModel>(initialValue)

    useEffect(()=>{

        axios.get(GET_PROFILE_URL).then((response: any)=> {
            if (response.result != null) {
                setSummary(response.result);
            }
        });
        
    },[]);


    return (
        <div className="p-8 bg-gray-50 min-h-screen font-nunito text-lg text-gray-800">
            {/* İçerik */}
            <div className="flex mt-12 gap-16 ml-16">
                {/* Sol Sütun */}
                <div className="w-2/3 space-y-6">
                    <div className="p-6 rounded-[20px] shadow-lg">
                        <h3 className="font-bold mb-4 border-b border-gray-300 pb-2">Candidate Details</h3>
                        <div className="grid gap-4">
                            <div><strong>Candidate Name:</strong> {summary.name}</div>
                            <div><strong>Candidate Reference:</strong> {summary.reference}</div>
                            <div><strong>Gender:</strong> {summary.gender}</div>
                            <div><strong>Education:</strong> {summary.education}</div>
                            <div><strong>University:</strong> {summary.university}</div>
                            <div><strong>Current Company:</strong> {summary.currentCompany}</div>
                            <div><strong>Current Position:</strong> {summary.currentPosition}</div>
                            <div><strong>Candidate Location:</strong> {summary.candidateLocation}</div>
                            <div><strong>Birthdate:</strong> {summary.birthDate.toLocaleDateString()}</div>
                            <div><strong>E-Mail Address:</strong> {summary.emailAddress}</div>
                        </div>
                    </div>

                    <div className="p-6 rounded-[20px] shadow-lg">
                        <h3 className="font-bold mb-4 border-b border-gray-300 pb-2">Language</h3>
                        <table className="w-full text-left">
                            <thead>
                                <tr>
                                    <th className="py-2"></th>
                                    <th className="py-2">Writing</th>
                                    <th className="py-2">Listening</th>
                                    <th className="py-2">Speaking</th>
                                </tr>
                            </thead>
                            <tbody>
                                {summary.languages.map((lang, index) => (
                                    <tr key={index}>
                                        <td className="py-2">
                                            <img src={`/media/svg/general/${lang.language.toLowerCase()}.svg`} alt={lang.language} className="inline-block mr-2" />
                                            {lang.language}
                                        </td>
                                        <td className="py-2">{lang.writing}</td>
                                        <td className="py-2">{lang.listening}</td>
                                        <td className="py-2">{lang.speaking}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Sağ Sütun */}
                <div className="w-1/3 space-y-6">
        <div className="p-6 rounded-[20px] shadow-lg">
            <h3 className="font-bold mb-4 border-b border-gray-300 pb-2">Skills</h3>
            <div className="flex flex-wrap gap-2">
                {summary.skills.map((skill, index) => (
                    <span key={index} className="px-3 py-1 border border-red-500 rounded-full font-bold text-black-700 text-sm">
                        {skill}
                    </span>
                ))}
            </div>
        </div>

        <div className="p-6 rounded-[20px] shadow-lg">
            <h3 className="font-bold mb-4 border-b border-gray-300 pb-2">Recent History</h3>
            <div className="space-y-4">
                {summary.recentHistory.map((history, index) => (
                    <div key={index} className="flex items-center mb-4 border-b border-gray-200 pb-2">
                        <img
                            src="https://randomuser.me/api/portraits/men/5.jpg"
                            alt="Profile"
                            className="w-10 h-10 rounded-full mr-4"
                        />
                        <div>
                            <p className="text-sm text-gray-700">
                                <span className="font-semibold text-[#02a0fc]">{history.updater}</span> {'updated the candidate'} <span className="text-[#02a0fc]">{summary.name}</span>
                            </p>
                            <p className="text-xs text-gray-500">{history.date.toLocaleDateString('en-GB')}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
            </div>
        </div>
    );
};

export default CandidateProfileSummary;
