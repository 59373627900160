// Main.tsx

import './app.css';
import './_metronic/assets/sass/style.react.scss';
import './_metronic/assets/fonticon/fonticon.css';
import './_metronic/assets/keenicons/duotone/style.css';
import './_metronic/assets/keenicons/outline/style.css';
import './_metronic/assets/keenicons/solid/style.css';
import 'primereact/resources/themes/saga-blue/theme.css'; // PrimeReact tema
import './_metronic/assets/sass/style.scss';

import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useEffect, useRef } from 'react';

import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider } from './app/modules/auth';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import { PrimeReactProvider } from 'primereact/api';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toast } from 'primereact/toast';
import { createRoot } from 'react-dom/client';
import { initToastService } from './services/toast.service';

const VERSION = import.meta.env.VITE_VERSION;

Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById('root');

const App = () => {
  const toast = useRef<Toast>(null);
  const isDev = import.meta.env.VITE_VERSION === 'dev';

  useEffect(() => {
    if (toast.current) {
      initToastService(toast.current);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <PrimeReactProvider>
          <AuthProvider>
            <Toast ref={toast} />
            <AppRoutes />
          </AuthProvider>
        </PrimeReactProvider>
      </MetronicI18nProvider>
      {isDev && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

if (container) {
  createRoot(container).render(<App />);
}
