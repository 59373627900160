import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { JobTitleModel } from "../models/job-title-model";
import {
  jobtitle,
  company,
  workplace,
  department,
  jobType,
  quota,
} from "../enum/job-title";

const CreateJobPost1 = () => {
  const navigate = useNavigate();

  const formik = useFormik<JobTitleModel>({
    initialValues: {
      jobTitle: undefined,
      department: undefined,
      company: undefined,
      companyWebSite: "",
      country: "Turkey",
      city: "Istanbul",
      workplace: undefined,
      salary: undefined,
      jobType: undefined,
      quota: undefined,
    },
    onSubmit: (values) => {
      navigate("/job-postings/step2");
    },
  });

  return (
    <div className="p-[10px] lg:p-6">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col lg:flex-row gap-[20px] lg:gap-[100px]">
          {/* Left column (1st Column) */}
          <div className="flex-1">
            <label className="block mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Job Title
            </label>
            <select
              className="block w-full p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent nunitoregular"
              name="jobTitle"
              value={formik.values.jobTitle}
              onChange={formik.handleChange}
            >
              <option>{jobtitle.UX_UI_Designer}</option>
            </select>

            <label className="block mt-6 mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Department
            </label>
            <select
              className="block w-full p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent nunitoregular"
              name="department"
              value={formik.values.department}
              onChange={formik.handleChange}
            >
              <option>{department.design}</option>
            </select>

            <label className="block mt-6 mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Company
            </label>
            <select
              className="block w-full p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent nunitoregular"
              name="company"
              value={formik.values.company}
              onChange={formik.handleChange}
            >
              <option>{company.slack}</option>
            </select>

            <label className="block mt-6 mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Company Website
            </label>
            <input
              name="companyWebSite"
              type="text"
              className="block w-full p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent nunitoregular"
              placeholder="slack.com"
              value={formik.values.companyWebSite}
              onChange={formik.handleChange}
            />
          </div>

          {/* Middle column (2nd Column) */}
          <div className="flex-1">
            <label className="block mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Country
            </label>
            <select
              className="block w-full p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent nunitoregular"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
            >
              <option>Turkey</option>
            </select>

            <label className="block mt-6 mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              City
            </label>
            <select
              className="block w-full p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent nunitoregular"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
            >
              <option>Istanbul</option>
            </select>

            <label className="block mt-6 mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Workplace
            </label>
            <select
              className="block w-full p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent nunitoregular"
              name="workplace"
              value={formik.values.workplace}
              onChange={formik.handleChange}
            >
              <option>{workplace.onsite}</option>
            </select>

            <label className="block mt-6 mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Salary
            </label>
            <div className="flex">
              <input
                name="salary"
                type="number"
                className="block w-full p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent nunitoregular"
                placeholder="50000"
                value={formik.values.salary}
                onChange={formik.handleChange}
              />
              <select
                className="block w-20 p-4 border-1 border-solid border-[#ADADAD] rounded-[3px] bg-transparent ml-2 nunitoregular"
                name="currency"
                onChange={formik.handleChange}
              >
                <option>TL</option>
              </select>
            </div>
          </div>

          {/* Right column (3rd Column) */}
          <div className="flex-1">
            <label className="block mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Job Type
            </label>
            <div className="flex flex-wrap gap-3">
              <label className="inline-flex items-center">
                <input type="checkbox" className="checkbox" />
                <span className="ml-2 nunitosemibold text-[14px] text-[#2B2F42]">
                  Full time
                </span>
              </label>
              <label className="inline-flex items-center">
                <input type="checkbox" className="checkbox" />
                <span className="ml-2 nunitosemibold text-[14px] text-[#2B2F42]">
                  Part time
                </span>
              </label>
              <label className="inline-flex items-center">
                <input type="checkbox" className="checkbox" />
                <span className="ml-2 nunitosemibold text-[14px] text-[#2B2F42]">
                  Internship
                </span>
              </label>
            </div>

            <label className="block mt-6 mb-2 nunitosbold text-[14px] text-[#2B2F42]">
              Quota
            </label>
            <div className="flex lg:justify-between flex-wrap gap-3 lg:gap-0">
              <label className="inline-flex items-center">
                <input
                  name="quota"
                  type="checkbox"
                  className="checkbox"
                  checked={formik.values.quota === quota.first}
                  onChange={formik.handleChange}
                />
                <span className="ml-2 nunitosemibold text-[14px] text-[#2B2F42]">
                  {quota.first} Candidates
                </span>
              </label>
              <label className="inline-flex items-center">
                <input
                  name="quota"
                  type="checkbox"
                  className="checkbox"
                  checked={formik.values.quota === quota.second}
                  onChange={formik.handleChange}
                />
                <span className="ml-2 nunitosemibold text-[14px] text-[#2B2F42]">
                  {quota.second} Candidates
                </span>
              </label>
              <label className="inline-flex items-center">
                <input
                  name="quota"
                  type="checkbox"
                  className="checkbox"
                  checked={formik.values.quota === quota.third}
                  onChange={formik.handleChange}
                />
                <span className="ml-2 nunitosemibold text-[14px] text-[#2B2F42]">
                  {quota.third} Candidates
                </span>
              </label>
            </div>
          </div>
        </div>

        {/* Next button */}
        <div className="flex justify-end mt-6">
          <button type="submit">
            <img
              src="/public/media/svg/general/ileriok.svg"
              alt="Next"
              className="w-30 h-30"
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateJobPost1;
