import React from "react";

const RemunerationOverview: React.FC = () => {
  return (
    <div className="flex-1 py-[19px] px-[40px] rounded-[32px] shadow-custom flex flex-col gap-[18px] h-max-content ">
      {/* Header */}
      <div className="flex flex-col sm:flex-row  sm:justify-between items-start sm:items-center">
        <h2 className="nunitomedium text-[30px] leading-[35px] text-[#2B2F42] mb-0">
          Remuneration Overview
        </h2>
        <div className="flex items-center text-[#2B2F42]  bg-[#F0F0F0]  px-[7px] py-[1px] rounded-[12px]">
          <img
            src="/media/svg/general/acute.svg"
            alt="Clock Icon"
            className="w-8 h-8s mr-2"
          />
          <span className="nunitomedium text-[14px] text-[#2B2F42]">
            Monthly
          </span>
        </div>
      </div>

      {/* List Items */}
      <div className="flex flex-col gap-[15px] sm:gap-[0px]">
        {/* Item 1 */}
        <div className="flex flex-col">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-[12px] sm:gap-[90px]">
            <div className="flex-1 flex items-center">
              <img
                src="/media/svg/general/Ellipse 67.svg"
                alt="Zeynep Kapucu"
                className="w-[42px] h-[42px] rounded-full mr-4"
              />
              <div>
                <p className="nunitomedium text-[22px] leading-[30px] text-[#2B2F42] mb-0 whitespace-nowrap">
                  Zeynep Kapucu
                </p>
                <p className="nunitomedium text-[14px] leading-[20px] text-[#2B2F42] opacity-40 mb-0">
                  Admin Advisor
                </p>
              </div>
            </div>
            <div className="flex-1 flex items-center gap-[40px] lg:gap-[60px]">
              <button className="bg-[#FF8E01] text-[10px] font-normal leading-[15px] text-[#F9F9F9] text-center w-[82.79px] h-[24px] rounded-[8px] whitespace-nowrap shrink-0">
                View Amount
              </button>
              <img
                src="/media/svg/general/more_horiz.svg"
                alt="Options"
                className="w-8 h-8"
              />
            </div>
          </div>
        </div>

        {/* Item 2 */}
        <div className="flex flex-col">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-[12px] sm:gap-[90px]">
            <div className="flex-1 flex items-center">
              <img
                src="/media/svg/general/Ellipse 44.svg"
                alt="Emre Yıldız"
                className="w-[42px] h-[42px] rounded-full mr-4"
              />
              <div>
                <p className="nunitomedium text-[22px]  leading-[30px] text-[#2B2F42] mb-1 whitespace-nowrap">
                  Emre Yıldız
                </p>
                <p className="font-nunito text-[14px] font-medium leading-[20px] text-[#2B2F42] opacity-40 mb-1">
                  Supervisor
                </p>
              </div>
            </div>
            <div className="flex-1 flex items-center gap-[40px] lg:gap-[60px]">
              <button className="bg-[#FF8E01] text-[10px] font-normal leading-[15px] text-[#F9F9F9] text-center w-[82.79px] h-[24px] rounded-[8px] whitespace-nowrap shrink-0">
                View Amount
              </button>
              <img
                src="/media/svg/general/more_horiz.svg"
                alt="Options"
                className="w-8 h-8"
              />
            </div>
          </div>
        </div>

        {/* Item 3 */}
        <div className="flex flex-col">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-[12px] sm:gap-[90px]">
            <div className="flex-1 flex items-center">
              <img
                src="/media/svg/general/Ellipse 45.svg"
                alt="Aslı Enver"
                className="w-[42px] h-[42px] rounded-full mr-4"
              />
              <div>
                <p className="nunitomedium text-[22px]  leading-[30px] text-[#2B2F42] mb-1 whitespace-nowrap">
                  Aslı Enver
                </p>
                <p className="font-nunito text-[14px] font-medium leading-[20px] text-[#2B2F42] opacity-40 mb-1">
                  Supervisor
                </p>
              </div>
            </div>
            <div className="flex-1 flex items-center gap-[40px] lg:gap-[60px]">
              <button className="bg-[#FF8E01] text-[10px] font-normal leading-[15px] text-[#F9F9F9] text-center w-[82.79px] h-[24px] rounded-[8px] whitespace-nowrap shrink-0">
                View Amount
              </button>
              <img
                src="/media/svg/general/more_horiz.svg"
                alt="Options"
                className="w-8 h-8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemunerationOverview;
