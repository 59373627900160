import React, { useState,useEffect } from 'react';
import {TaskStatusModel} from '../model';
import axios from 'axios';

const initialValues = [
    { status: 'Application', score: 90.35, date: '08.07.2024', time: '10:00', statusText: 'Application' },
    { status: 'InProgress', score: 90.35, date: '08.07.2024', time: '10:00',statusText: 'In Progress' }
];

const GET_MESSAGE_URL = "URL";

export const CandidateProfileHistory: React.FC = () => {

   const [history, setHistory] = useState<TaskStatusModel[]>(initialValues)

    useEffect(()=>{

        axios.get(GET_MESSAGE_URL).then((response: any)=> {
            if (response.result != null) {
                setHistory(response.result);
            }     
        });
        
    },[]);

    return (
        <div className="p-8 bg-gray-50 min-h-screen font-nunito text-lg text-gray-800">

            {/* History Bölümü */}
            <div className="mt-12 bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold mb-4">History</h3>
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            <th className="py-2">Status</th>
                            <th className="py-2 ">Score</th>
                            <th className="py-2">Date</th>
                            <th className="py-2">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {history.map((item, index) => (
                            <tr key={index} className="border-b border-gray-200">
                                <td className="py-2">
                                    <span
                                        className={`inline-block px-3 py-1 text-white font-semibold rounded-full ${item.status ==='Application' ? 'bg-red-500' : 'bg-orange-500' }`}
                                    >
                                        {item.statusText}
                                    </span>
                                </td>
                                <td className="py-2 ">
                                    <div className="flex flex-col">
                                        <div className='w-2/3'>
                                        <img
                                            src="/media/svg/general/scorebar.svg"
                                            alt="Score Bar"
                                            className="w-[95px] h-[80px]"
                                        />
                                        <span className="text-sm font-semibold text-gray-700 ">{`%${item.score}`}</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="py-2">{item.date}</td>
                                <td className="py-2">{item.time}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CandidateProfileHistory;
