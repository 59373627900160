import { Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { ForgotPasswordResult } from "./components/forgotPasswordResult";
import { AuthLayout } from "./AuthLayout";
import { NewPasswordResult } from "./components/newPasswordResult";
import { NewPassword } from "./components/newPassword";

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="forgot-password-result" element={<ForgotPasswordResult />} />
      <Route path="new-password" element={<NewPassword />} />
      <Route path="new-password-result" element={<NewPasswordResult />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
