import AdvisorDashboard from "../../modules/dashboard/advisor-dashboard";
import CandidateDashboard from "../../modules/dashboard/candidate-dashboard";
import CompanyDashboard from "../../modules/dashboard/company-dashboard";
import { Content } from "../../../_metronic/layout/components/content";
import { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { useAuth } from "@/app/modules/auth/core/Auth";
import { useIntl } from "react-intl";

function DashboardPage() {
  const { currentUser } = useAuth();

  const renderDashboard = () => {
    if (!currentUser) {
      return <p>Loading...</p>;
    }

    switch (Number(currentUser?.account_type)) {
      case -1:
        return (
          <p>
            You are logged in as admin. <br />
            You can continue from the administration menu.
          </p>
        );
      case 0:
        return <CandidateDashboard />;
      case 1:
        return <AdvisorDashboard />;
      case 2:
        return <CompanyDashboard />;
      default:
        return (
          <p>
            A user role that is not defined has been logged in. Contact the
            system administrator. Role: {currentUser.account_type}
          </p>
        );
    }
  };

  return <Content>{renderDashboard()}</Content>;
}

export default DashboardPage;

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
