import { toAbsoluteUrl } from "../../../../_metronic/helpers";

interface Props {
  title: string;
  percentagevalue: string;
  description: string;
  route: string;
}

function DashboardComponent1({
  title,
  percentagevalue,
  description,
  route,
}: Props) {
  return (
    <a href={route} className="flex-1 h-[230px] !block">
      <div
        className={"flex-1 h-[230px] rounded-[32px] relative overflow-hidden"}
      >
        <div
          className={
            "absolute flex flex-col gap-[30px] py-[23px] pl-[22px] z-20 h-full justify-between"
          }
        >
          <h6
            className={
              "nunitomedium text-[30px] select-none text-[#F9F9F9] mb-0"
            }
          >
            {title}
          </h6>
          <div className={"flex flex-col w-full gap-[6px]"}>
            <h6
              className={
                "nunitomedium leading-10 select-none w-fit mt-[22px]  text-center text-[30px] text-[#F9F9F9] my-0 ml-0"
              }
            >
              {percentagevalue}
            </h6>
            <h6
              className={
                "nunitomedium leading-4 select-none -ml-2 mt-4 w-[170px] text-[14px] text-[#F9F9F9] m-0 "
              }
            >
              {description}
            </h6>
          </div>
        </div>

        <img
          className={
            "absolute z-20 w-[28px] h-[33px] right-[51.34%] top-[35.22%] "
          }
          src={toAbsoluteUrl("media/dashboard/search-job-icon-1.svg")}
        />

        <img
          className={
            "w-[100%] h-[100%] object-cover absolute z-10 left-0 top-0"
          }
          src={toAbsoluteUrl("media/dashboard/search-job-bg.svg")}
        />

        <img
          className={
            "w-[100%] h-[100%] object-cover absolute z-15 left-0 top-[98px]"
          }
          src={toAbsoluteUrl("media/dashboard/box-shadow-card.svg")}
        />

        <img
          className={
            "w-[39px] h-[52px] object-cover absolute z-20 right-[30px] -top-[1px]"
          }
          src={toAbsoluteUrl("media/dashboard/flag-star.svg")}
        />

        <img
          className={
            "w-[148px] h-[148px] object-cover absolute z-20 right-0 bottom-0"
          }
          src={toAbsoluteUrl("media/dashboard/half-circles.svg")}
        />
      </div>
    </a>
  );
}

export default DashboardComponent1;
