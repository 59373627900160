import React from "react";

const SurveysFeedbackComponent: React.FC = () => {
  const feedbacks = [
    {
      name: "LinkedilN",
      location: "Türkiye",
      image: "media/dashboard/linkedin.svg",
      progress: 70,
      progressColor: "bg-[#14ED44]",
      progressOuterColor: "bg-[#14ED4433]",
    },
    {
      name: "Indeed",
      location: "Türkiye",
      image: "media/dashboard/indeed.svg",
      progress: 60,
      progressColor: "bg-[#36C5F1]",
      progressOuterColor: "bg-[#36C5F133]",
    },
    {
      name: "Jobwars",
      location: "Türkiye",
      image: "media/dashboard/jobwars.svg",
      progress: 50,
      progressColor: "bg-[#FEA800]",
      progressOuterColor: "bg-[#FEA80033]",
    },
  ];

  return (
    <div className="flex-1 py-[19px] px-[40px] rounded-[32px] shadow-custom flex flex-col gap-[18px] h-max-content">
      <h2 className="nunitomedium text-[30px]  text-[#2B2F42] mb-0">
        Surveys - Feedbacks
      </h2>
      {feedbacks.map((feedback, index) => (
        <div
          key={index}
          className="flex justify-between items-center mb-0 gap-[80px]"
        >
          {/* Profil resmi ve isim */}
          <div className="flex gap-[8px]">
            <img
              src={feedback.image}
              alt={feedback.name}
              className="w-[44px] h-[44px] shrink-0 rounded-full"
            />
            <div className="flex-1">
              <p className="nunitomedium text-[14px] text-[#2B2F42] mb-0">
                {feedback.name}
              </p>
              <p className="nunitomedium text-[#2B2F4266] text-[14px] mb-0">
                {feedback.location}
              </p>
            </div>
          </div>
          {/* Progress Bar */}
          <div className="flex w-[49%] shrink-0 flex-col items-end gap-[8px]">
            <div className="nunitomedium text-[14px] text-[#2B2F42] mr-[50px]">
              {feedback.progress}%
            </div>
            <div
              className={` w-full h-[8px] rounded-full rounded-[71px] ${feedback.progressOuterColor}`}
            >
              <div
                className={`${feedback.progressColor} h-[8px] rounded-[71px]`}
                style={{ width: `${feedback.progress}%` }}
              ></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SurveysFeedbackComponent;
