import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import plus from "@/_metronic/assets/registerlogin/plus.svg";
import trash from "@/_metronic/assets/registerlogin/trash.svg";

interface SummaryProps {
  onSubmit: (data: any) => void;
  onBack: () => void;
}

const validationSchema = Yup.object({
  company: Yup.string().required("Company is required"),
  location: Yup.string().required("Location is required"),
  phone: Yup.string()
    .matches(
      /^\+\d{1,3} \d{3} \d{3} \d{4}$/,
      "Phone must be in format +XX XXX XXX XXXX"
    )
    .required("Phone is required"),
  birthDate: Yup.date().required("Birth date is required"),
  coverLetter: Yup.string(),
});

const Summary: React.FC<SummaryProps> = ({ onSubmit, onBack }) => {
  const formik = useFormik({
    initialValues: {
      company: "Opti Recruits",
      location: "Istanbul",
      phone: "+90 501 016 3673",
      gender: "Male",
      birthDate: "1994-05-03",
      coverLetter:
        "Dear [Company Name] Representatives,\nI am applying for the [Position Title]. I am expressing my suitability and interest.\nBest regards, [Your Name]",
      languages: ["English"],
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleAddLanguage = () => {
    if (formik.values.languages.length < 7) {
      formik.setFieldValue("languages", [...formik.values.languages, ""]);
    }
  };

  const handleRemoveLanguage = (index: number) => {
    const updatedLanguages = formik.values.languages.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("languages", updatedLanguages);
  };

  const handleLanguageChange = (index: number, value: string) => {
    const updatedLanguages = [...formik.values.languages];
    updatedLanguages[index] = value;
    formik.setFieldValue("languages", updatedLanguages);
  };

  const handleClean = () => {
    formik.setValues({
      company: "",
      location: "",
      phone: "",
      gender: "Male",
      birthDate: "",
      coverLetter: "",
      languages: [""],
    });
  };

  return (
    <div className="w-full max-w-[750px] mx-auto rounded-lg">
      <form
        onSubmit={formik.handleSubmit}
        className="h-max  px-[10px] 2xl:px-[0]"
      >
        <div className="mb-4">
          <label
            htmlFor="company"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Current Company *
          </label>
          <input
            type="text"
            id="company"
            placeholder="Opti Recruits"
            {...formik.getFieldProps("company")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.company && formik.errors.company
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.company && formik.errors.company ? (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.company}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <label
            htmlFor="location"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Current Location *
          </label>
          <input
            type="text"
            id="location"
            placeholder="Istanbul"
            {...formik.getFieldProps("location")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.location && formik.errors.location
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.location && formik.errors.location ? (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.location}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <label
            htmlFor="phone"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Phone *
          </label>
          <InputMask
            mask="+90 999 999 9999"
            id="phone"
            placeholder="+90 501 016 3673"
            {...formik.getFieldProps("phone")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.phone && formik.errors.phone
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.phone}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <label
            htmlFor="gender"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Gender
          </label>
          <select
            id="gender"
            {...formik.getFieldProps("gender")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.gender && formik.errors.gender
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            } select-input`}
          >
            <option>Male</option>
            <option>Female</option>
            <option>Other</option>
          </select>
          {formik.touched.gender && formik.errors.gender ? (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.gender}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <label
            htmlFor="birthDate"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Birth Date *
          </label>
          <input
            type="date"
            id="birthDate"
            placeholder="01/01/1980"
            {...formik.getFieldProps("birthDate")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px]  ${
              formik.touched.birthDate && formik.errors.birthDate
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            } date-input`}
          />
          {formik.touched.birthDate && formik.errors.birthDate ? (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.birthDate}
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <label className="block text-[22px] nunitosbold text-[#2B2F42]">
            Language
          </label>
          <div className=" rounded-md">
            {formik.values.languages.map((language, index) => (
              <div
                key={index}
                className="flex items-center justify-between relative"
              >
                <input
                  type="text"
                  value={language}
                  onChange={(e) => handleLanguageChange(index, e.target.value)}
                  placeholder="English"
                  className={`w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                    formik.touched.languages && formik.errors.languages
                      ? "border-[#FE0000]"
                      : "border-[#ADADAD]"
                  }`}
                />
                {index === 0 ? (
                  <button
                    type="button"
                    onClick={handleAddLanguage}
                    className="absolute right-[27px]  top-1/2 -translate-y-1/2"
                  >
                    <img src={plus} alt="plus" />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleRemoveLanguage(index)}
                    className="absolute right-[27px]  top-1/2 -translate-y-1/2"
                  >
                    <img src={trash} alt="trash" />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="coverLetter"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Cover Letter
          </label>
          <textarea
            id="coverLetter"
            placeholder="Dear [Company Name] Representatives, I am applying for the [Position Title]. I am expressing my suitability and interest. Best regards, [Your Name]"
            {...formik.getFieldProps("coverLetter")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[149px] border-[1px] p-[16px] rounded-[3px] resize-none  ${
              formik.touched.coverLetter && formik.errors.coverLetter
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            } `}
            rows={4}
          />
          {formik.touched.coverLetter && formik.errors.coverLetter ? (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.coverLetter}
            </div>
          ) : null}
        </div>

        <div className="flex flex-col justify-between mt-6">
          <button
            type="button"
            onClick={handleClean}
            className="w-100 h-[60px] sm:h-[79.55px] bg-[#36C5F1] hover:bg-[#1FA6D8] rounded-[40px] nunitomedium text-[18px] sm:text-[22px] text-[#F9F9F9]"
          >
            Clean
          </button>

          <div className="flex gap-[20px] sm:gap-[35px] mt-[30px]">
            <button
              type="button"
              className="w-100 h-[60px] sm:h-[79.55px] border-[2px] border-[#D9D9D9]  rounded-[40px] nunitomedium text-[#B0B0B0] text-[18px] sm:text-[22px]"
              onClick={onBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="w-100 h-[60px] sm:h-[79.55px] border-[2px] border-[#FE0000] bg-[#FE0000] hover:bg-red-500 rounded-[40px] nunitomedium text-[#F9F9F9] text-[18px] sm:text-[22px]"
            >
              Next
            </button>
          </div>
        </div>
      </form>

      <p className="nunitosbold text-center mt-[30px] text-[18px] sm:text-[22px]">
        Back to{" "}
        <a href="/" className="text-[#02A0FC]">
          Home
        </a>
      </p>
    </div>
  );
};

export default Summary;
