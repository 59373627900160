import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

interface User {
  id: string;
  name: string;
  role: string;
  status: string;
  deadline: string;
}

const OngoingRecruitment: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [menuOpen, setMenuOpen] = useState<null | string>(null);
  const [loading, setLoading] = useState(true);

  // Demo verilerini oluşturma
  const demoData: User[] = [
    {
      id: "1",
      name: "Merve Gündoğdu",
      role: "UI / UX Designer",
      status: "Pending",
      deadline: "2024-09-20",
    },
  ];

  // useEffect ile demo verilerini state'e atama
  useEffect(() => {
    // Simülasyon: Gerçek API'den veri çekiyormuş gibi ayarlama
    setTimeout(() => {
      setUsers(demoData);
      setLoading(false);
    }, 1000); // 1 saniye bekletiyoruz
  }, []);

  // POST Request: Bildirim gönderme
  const handleSendReminder = (userId: string) => {
    fetch("https://api.example.com/send-reminder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    })
      .then((response) => {
        if (response.ok) {
          alert("Reminder sent successfully!");
        }
      })
      .catch((error) => {
        console.error("Error sending reminder:", error);
      });
  };

  // DELETE Request: İşten çıkarma
  const handleDeleteUser = (userId: string) => {
    fetch(`https://api.example.com/remove-user/${userId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          alert("User removed successfully!");
          setUsers(users.filter((user) => user.id !== userId)); // Kullanıcıyı listeden kaldır
        }
      })
      .catch((error) => {
        console.error("Error removing user:", error);
      });
  };

  // Menü açma/kapama
  const toggleMenu = (userId: string) => {
    setMenuOpen(menuOpen === userId ? null : userId);
  };

  // Bildirim ve silme seçeneklerinin bulunduğu template
  const actionTemplate = (rowData: User) => (
    <div>
      <Button icon="pi pi-ellipsis-v" className="p-button-text mr-[105px]" onClick={() => toggleMenu(rowData.id)} />
      {menuOpen === rowData.id && (
        <div className="absolute mt-2 w-48 bg-white rounded-md shadow-lg z-10">
          <Button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" label="Send Notification 📩" onClick={() => handleSendReminder(rowData.id)} />
          <Button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" label="Delete Recruitment 🗑️" onClick={() => handleDeleteUser(rowData.id)} />
        </div>
      )}
    </div>
  );

  return (
    <div>
      {/* Tablonun Başlığı */}
      <div className="p-6 shadow-lg rounded-lg bg-white">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTable value={users} responsiveLayout="scroll">
            <Column
              field="name"
              header="Name"
              body={(rowData) => (
                <div className="flex items-center">
                  <img src="https://randomuser.me/api/portraits/women/1.jpg" alt="profile" className="w-10 h-10 rounded-full mr-4" />
                  {rowData.name}
                </div>
              )}
            />
            <Column field="role" header="Role" />
            <Column field="status" header="Status" body={(rowData) => <span className={`font-semibold ${rowData.status === "Pending" ? "text-yellow-500" : "text-green-500"}`}>{rowData.status}</span>} />
            <Column field="deadline" header="Deadline" />
            <Column body={actionTemplate} />
          </DataTable>
        )}
      </div>
    </div>
  );
};

export default OngoingRecruitment;
