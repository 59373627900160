import { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  percentagevalue: string;
  description: string;
  route: string;
  caret: boolean;
  caretText: string;
}

function CompanyComponent1({
  title,
  percentagevalue,
  description,
  route,
  caret,
  caretText,
}: Props) {
  const [isCaretGreen, setIsCaretGreen] = useState(caret);
  const splitTitle = title.split(" ");

  return (
    <Link to={route} className="flex-1 h-[230px] !block">
      <div
        className={"flex-1 h-[230px] rounded-[32px] relative overflow-hidden"}
      >
        <div
          className={
            "absolute flex flex-col gap-[30px] py-[23px] pl-[22px] z-20"
          }
        >
          <h6
            className={
              "nunitomedium text-[30px] select-none text-[#F9F9F9] mb-0"
            }
          >
            {splitTitle.map((word, index) => (
              <span key={index}>
                {word}
                {index !== splitTitle.length - 1 && <br />}{" "}
              </span>
            ))}
          </h6>
          <div className={"flex flex-col w-full gap-[6px]"}>
            <h6
              className={
                "nunitomedium leading-10 select-none w-fit mt-[16px] text-center text-[30px] text-[#F9F9F9] mt-0 mb-0 ml-0"
              }
            >
              {percentagevalue}
            </h6>
            <h6
              className={
                "nunitomedium leading-4 select-none -ml-2 mt-4 w-[170px] text-[14px] text-[#F9F9F9] m-0 "
              }
            >
              {description}
            </h6>
          </div>
        </div>

        <img
          className={"absolute z-20 w-[40px] h-[40px] right-[57px] top-[29px] "}
          src={toAbsoluteUrl("media/dashboard/search-job-icon-1.svg")}
        />

        <img
          className={
            "w-[100%] h-[100%] object-cover absolute z-10 left-0 top-0"
          }
          src={toAbsoluteUrl("media/dashboard/search-job-bg.svg")}
        />

        <img
          className={
            "w-[100%] h-[100%] object-cover absolute z-15 left-0 top-[98px]"
          }
          src={toAbsoluteUrl("media/dashboard/box-shadow-card.svg")}
        />

        <img
          className={
            "w-[22px] h-[22px] object-cover absolute z-20 right-[108px] top-[148px]"
          }
          src={toAbsoluteUrl("media/dashboard/date-icon.svg")}
        />

        <div className="flex items-center gap-[7px] z-20 absolute right-[58px] top-[118px]">
          <img
            src={toAbsoluteUrl(
              isCaretGreen
                ? "media/dashboard/caret-icon-green.svg"
                : "media/dashboard/caret-icon-red.svg"
            )}
            alt={isCaretGreen ? "Green caret" : "Red caret"}
          />
          <span className="nunitomedium text-[14px] text-[#F9F9F9]">
            {caretText}
          </span>
        </div>
      </div>
    </Link>
  );
}

export default CompanyComponent1;
