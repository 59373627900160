import React, { useEffect, useState } from 'react';
import CandidateFilterCard from './candidateFilterCard';
import { CandidateModel } from '../model';
import axios, { HttpStatusCode } from 'axios';

const API_URL = "API_URL";

//skill kısmı enum olacak mı?
//api eklenecek

const CandidateFilters: React.FC = () => {

    const [candidates, setCandidates] = useState<CandidateModel[]>([]);

    const [skills, setSkills] = useState<string[]>(['Realition Management', 'Prototyping', 'Prototyping']);
    const [jobType, setJobType] = useState<string>('');
    const [experienceLevel, setExperienceLevel] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [currentPosition, setCurrentPosition] = useState<string>('');
    const [progress, setProgress] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [language, setLanguage] = useState<string>('English');
    const [languages, setLanguages] = useState<string[]>(['English']); // Diller için state
    const [showPopup, setShowPopup] = useState(false);
    const [general, setGeneral] = useState({
        selectall1: false,
        type: "general",
        extra: "a"
    });
    const [newSkill, setNewSkill] = useState<string>('');
    const [isInputVisible, setInputVisible] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>(''); // Hata mesajı için state

    useEffect(()=>{
        axios.get(API_URL).then(response => {
            if(response.status == HttpStatusCode.Ok)
                setCandidates(response.data);
        })
        .catch(error => {
            console.error("Error saving data", error);
        });
    },[]);


    const handleAddSkill = () => {
        if (newSkill.trim()) {
            if (skills.includes(newSkill.trim())) {
                setErrorMessage('This skill is already added.'); // Hata mesajı ayarlanıyor
            } else {
                setSkills([...skills, newSkill.trim()]);
                setErrorMessage(''); // Hata mesajı temizleniyor
                setNewSkill('');  // Input'u temizle
                setInputVisible(false);  // Input alanını gizle
            }
        }
    };

    const [newLanguage, setNewLanguage] = useState<string>(''); // Yeni dil eklemek için input state
    const [isLanguageInputVisible, setLanguageInputVisible] = useState<boolean>(false);
    const [languageErrorMessage, setLanguageErrorMessage] = useState<string>(''); // Dil hata mesajı için state


    const handleAddLanguage = () => {
        if (newLanguage.trim()) {
            if (languages.includes(newLanguage.trim())) {
                setLanguageErrorMessage('This language is already added.'); // Hata mesajı ayarlanıyor
            } else {
                setLanguages([...languages, newLanguage.trim()]);
                setLanguageErrorMessage(''); // Hata mesajı temizleniyor
                setNewLanguage('');  // Input'u temizle
                setLanguageInputVisible(false);  // Input alanını gizle
            }
        }
    };

    // Matching Recommendations state
    const [recommendations, setRecommendations] = useState<string[]>(['Artificial Intelligence', 'Resume', 'Position']);

    return (
        <div className={'w-full h-full flex flex-row bg-transparent'}>
            <div className={'flex flex-col relative pb-[50px] bg-[#F4F4F4] min-h-screen w-full pl-[20px] md:pl-[90px] pt-[33px] pr-[40px] gap-2'}>
                {general.type === "general" && (
                    <div className={'flex flex-col'}>
                        <div className={'w-full mt-8 flex-col sm:flex-row sm:gap-2 gap-6 sm:mt-[25px] flex justify-end items-center sm:items-start sm:justify-between'}>
                            <div className={'flex flex-col sm:items-start items-center w-fit gap-[5px]'}>
                                <h4 className={'nunitomedium text-[30px] text-[#2B2F42]'}>
                                    Candidates
                                </h4>
                                <h3 className={'nunitomedium opacity-60 text-[14px] text-[#2B2F42]'}>
                                    Let's compare the candidates.
                                </h3>
                            </div>
                            <div className={'flex flex-row gap-[14px] mr-2'}>
                                <div className={'bg-[#FF4343] cursor-pointer flex items-center w-[160px] h-[41px] gap-[8px] justify-center rounded-[10px]'}>
                                    <img src="/media/svg/general/Group 2443.svg" className="w-[25px] h-[23px]" />
                                    <p className={'nunitomedium text-[14px] text-[#F9F9F9] mt-4'}>
                                        Management
                                    </p>
                                </div>
                                <div className={'w-[101px] select-none cursor-pointer h-[41px] rounded-[10px] border-[#B0B0B0] border-[1px] flex justify-center items-center gap-1'}>
                                    <img src="/media/svg/general/Vector (4).svg" className="w-[19px] h-[20px] mt-1" />
                                    <h5 className={'nunitomedium text-[#2B2F42] text-[14px] mt-4'}>
                                        Filters
                                    </h5>
                                </div>
                                <div onClick={() => setShowPopup(true)} className={'bg-[#FF4343] cursor-pointer flex items-center w-[160px] h-[41px] gap-[8px] justify-center rounded-[10px]'}> {/* Pop-up açılma trigger'ı */}
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 21C16.2989 21 21 16.2989 21 10.5C21 4.70101 16.2989 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.2989 4.70101 21 10.5 21ZM11.2875 7.35C11.2875 6.91508 10.9349 6.5625 10.5 6.5625C10.0651 6.5625 9.7125 6.91508 9.7125 7.35V9.7125H7.35C6.91508 9.7125 6.5625 10.0651 6.5625 10.5C6.5625 10.9349 6.91508 11.2875 7.35 11.2875H9.7125V13.65C9.7125 14.0849 10.0651 14.4375 10.5 14.4375C10.9349 14.4375 11.2875 14.0849 11.2875 13.65V11.2875H13.65C14.0849 11.2875 14.4375 10.9349 14.4375 10.5C14.4375 10.0651 14.0849 9.7125 13.65 9.7125H11.2875V7.35Z" fill="#F9F9F9" />
                                    </svg>
                                    <h4 className={'nunitomedium text-[14px] text-[#F9F9F9] mt-3'}>
                                        Add Candidate
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="p-8 bg-gray-50 min-h-screen flex">
                            {/* Filtreler Bölümü */}
                            <div className="w-2/5 p-4 rounded-lg shadow-md mr-4 bg-transparent">
                                <div className="flex justify-between items-center mb-8">
                                    <h2 className="text-lg font-semibold">FILTERS</h2>
                                    <button className="text-gray-500 text-2xl">Clear all filters</button>
                                </div>
                                <hr className="border-t border-black mb-8" />

                                <div className="mb-6">
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="font-semibold mb-2">Skills</h3>
                                        <button className="text-gray-500 text-xl" onClick={() => setSkills([])}>Clear</button>
                                    </div>
                                    <div className="p-4 rounded-lg shadow-md border border-gray-300">
                                        <div className="flex flex-wrap mb-4">
                                            {skills.map(skill => (
                                                <div
                                                    key={skill}
                                                    className="mb-2 flex items-center border-2 border-red-500 rounded-full px-3 py-1 mr-2"
                                                >
                                                    <span className="text-sm text-gray-700 flex-grow">{skill}</span>
                                                    <button
                                                        onClick={() => setSkills(skills.filter(s => s !== skill))}
                                                        className="text-red-700 ml-2"
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                            {isInputVisible ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={newSkill}
                                                        onChange={(e) => setNewSkill(e.target.value)}
                                                        onBlur={() => setInputVisible(false)} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleAddSkill();
                                                            }
                                                        }}
                                                        className="border-2 border-gray-300 rounded-full px-3 py-1 mr-2"
                                                        placeholder="Enter skill"
                                                        autoFocus
                                                    />
                                                    {errorMessage && (
                                                        <div className="text-red-600 text-sm mt-2">{errorMessage}</div>
                                                    )}
                                                </>
                                            ) : (
                                                <button
                                                    className="rounded-full px-3 py-1 flex items-center text-sm text-gray-700 mr-2 border-2 border-gray-300"
                                                    onClick={() => setInputVisible(true)}
                                                >
                                                    Add Skills
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Matching Recommendations Bölümü */}
                                <div className="mb-6">
    <div className="flex justify-between items-center mb-4">
        <h3 className="font-semibold mb-2">Matching Recommendations</h3>
        <button className="text-gray-500 text-xl">Clear</button>
    </div>
    <div className="flex flex-col mb-4">
        {recommendations.map((rec, index) => (
            <div key={index} className="flex items-center mb-2">
                <input type="checkbox" checked readOnly className="form-checkbox h-5 w-5 text-red-500" />
                <span className="ml-2 text-sm text-gray-700">{rec}</span>
            </div>
        ))}
    </div>
</div>


<div className="mb-6">
    <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold">Job Type</h3>
        <button 
            className="text-gray-500 text-xl"
            onClick={() => setJobType('')} // Job Type seçimini temizler
        >
            Clear
        </button>
    </div>
    <select className="w-full p-2 border border-gray-300 rounded-md bg-transparent shadow-md" value={jobType} onChange={e => setJobType(e.target.value)}>
        <option value="">select Job Type</option>
        <option value="Tam Zamanlı">Tam Zamanlı</option>
        <option value="Yarı Zamanlı">Yarı Zamanlı</option>
        <option value="Freelance">Freelance</option>
    </select>
</div>

<div className="mb-6">
    <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold">Experience Level</h3>
        <button 
            className="text-gray-500 text-xl"
            onClick={() => setExperienceLevel('')} // Experience Level seçimini temizler
        >
            Clear
        </button>
    </div>
    <select className="w-full p-2 border border-gray-300 rounded-md bg-transparent shadow-md" value={experienceLevel} onChange={e => setExperienceLevel(e.target.value)}>
        <option value="">select Experience Level</option>
        <option value="Junior">Junior</option>
        <option value="Mid-Level">Mid-Level</option>
        <option value="Senior">Senior</option>
    </select>
</div>

<div className="mb-6">
    <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold">Company</h3>
        <button 
            className="text-gray-500 text-xl"
            onClick={() => setCompany('')} // Company seçimini temizler
        >
            Clear
        </button>
    </div>
    <select className="w-full p-2 border border-gray-300 rounded-md bg-transparent shadow-md" value={company} onChange={e => setCompany(e.target.value)}>
        <option value="">select Company</option>
        <option value="Company A">Company A</option>
        <option value="Company B">Company B</option>
    </select>
</div>

<div className="mb-6">
    <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold">Current Position</h3>
        <button 
            className="text-gray-500 text-xl"
            onClick={() => setCurrentPosition('')} // Current Position seçimini temizler
        >
            Clear
        </button>
    </div>
    <select className="w-full p-2 border border-gray-300 rounded-md bg-transparent shadow-md" value={currentPosition} onChange={e => setCurrentPosition(e.target.value)}>
        <option value="">select Current Position</option>
        <option value="Position A">Position A</option>
        <option value="Position B">Position B</option>
    </select>
</div>

<div className="mb-6">
    <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold">Progress</h3>
        <button 
            className="text-gray-500 text-xl"
            onClick={() => setProgress('')} // Progress seçimini temizler
        >
            Clear
        </button>
    </div>
    <select className="w-full p-2 border border-gray-300 rounded-md bg-transparent shadow-md" value={progress} onChange={e => setProgress(e.target.value)}>
        <option value="">select Progress</option>
        <option value="In Progress">In Progress</option>
        <option value="Completed">Completed</option>
    </select>
</div>

<div className="mb-6">
    <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold">Countries</h3>
        <button 
            className="text-gray-500 text-xl"
            onClick={() => setCountry('')} // Country seçimini temizler
        >
            Clear
        </button>
    </div>
    <select className="w-full p-2 border border-gray-300 rounded-md bg-transparent shadow-md" value={country} onChange={e => setCountry(e.target.value)}>
        <option value="">select Country</option>
        <option value="Turkey">Turkey</option>
        <option value="USA">USA</option>
    </select>
</div>

<div className="mb-6">
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="font-semibold mb-2">Languages</h3>
                                        <button className="text-gray-500 text-xl">Clear</button>
                                    </div>
                                    <div className="p-4 rounded-lg shadow-md border border-gray-300">
                                        <div className="flex flex-wrap items-center">
                                            {languages.map(lang => (
                                                <div
                                                    key={lang}
                                                    className="mb-2 flex items-center border-2 border-red-500 rounded-full px-3 py-1 mr-2"
                                                >
                                                    <span className="text-sm text-gray-700 flex-grow">{lang}</span>
                                                    <button
                                                        onClick={() => setLanguages(languages.filter(l => l !== lang))}
                                                        className="text-red-700 ml-2"
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                            {isLanguageInputVisible ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={newLanguage}
                                                        onChange={(e) => setNewLanguage(e.target.value)}
                                                        onBlur={() => setLanguageInputVisible(false)} // Input alanı dışında tıklayınca kapanır
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleAddLanguage();
                                                            }
                                                        }}
                                                        className="border-2 border-gray-300 rounded-full px-3 py-1 mr-2"
                                                        placeholder="Enter language"
                                                        autoFocus
                                                    />
                                                    {languageErrorMessage && (
                                                        <div className="text-red-600 text-sm mt-2">{languageErrorMessage}</div>
                                                    )}
                                                </>
                                            ) : (
                                                <button
                                                    className="rounded-full px-3 py-1 flex items-center text-sm text-gray-700 mr-2 border-2 border-gray-300"
                                                    onClick={() => setLanguageInputVisible(true)}
                                                >
                                                    Add Language
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="w-3/5 p-4 rounded-lg shadow-2xl border-gray-500">
            <h2 className="text-lg font-semibold mb-4">Sonuçlar</h2>
            <div className="flex justify-between items-center mb-4">
                <span>Sırala:</span>
                <select className="p-2 border border-gray-300 rounded-md">
                    <option>Relevans</option>
                    <option>Deneyim - Yüksekten Düşüğe</option>
                </select>
            </div>

            {
                candidates?.map(candidate => 
                    <CandidateFilterCard
                    name={candidate.name}
                    title={candidate.title}
                    location= {candidate.location}
                    skills={candidate.skills}
                    matchPercentage={candidate.matchPercentage}
                    avatar={candidate.avatar}
                    description={candidate.description}
                />
                )
            }
           

            {/* Daha fazla aday kartı eklenebilir */}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CandidateFilters;
