import React, { useState } from "react";
import { format, addDays, subDays, isToday } from "date-fns";
import arrowLeft from "@/../public/media/dashboard/arrow-left.svg";
import arrowRight from "@/../public/media/dashboard/arrow-right.svg";

const UpcomingScheduleComponent: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const schedules = [
    {
      name: "Olivia Arribas",
      role: "Product Designer",
      time: "10 am - 11 am",
      timeColor: "text-red-600",
      icon: "media/svg/general/avatar.svg",
      details:
        "Please prepare and bring these documents with you before the interview: Current CV and Portfolio.",
      borderColor: "border-red-500",
      statusColor: "bg-red-500",
      title: "INTERVIEW", // Eklenen başlık
    },
    {
      name: "Jack Liam",
      role: "Product Designer",
      time: "13 pm - 15 pm",
      timeColor: "text-orange-600",
      icon: "media/svg/general/avatar.svg",
      details:
        "Please prepare and bring these documents with you before the interview: Current CV and Portfolio.",
      borderColor: "border-orange-500",
      statusColor: "bg-orange-500",
      title: "INTERVIEW", // Eklenen başlık
    },
    {
      name: "Ava Victoria",
      role: "Product Designer",
      time: "16 pm - 17 pm",
      timeColor: "text-yellow-600",
      icon: "media/svg/general/avatar.svg",
      details:
        "Please prepare and bring these documents with you before the interview: Current CV and Portfolio.",
      borderColor: "border-yellow-500",
      statusColor: "bg-yellow-500",
      title: "INTERVIEW", // Eklenen başlık
    },
  ];

  const handleNextDay = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  const handlePreviousDay = () => {
    setCurrentDate((prevDate) => subDays(prevDate, 1));
  };

  return (
    <div className="shadow-custom-black mt-[16px] rounded-[32px] px-[20px] py-[17px] w-full xl-custom:w-[400px] shrink-0 ">
      <h2 className="nunitomedium text-[30px] text-[#2B2F42] mb-[20px]">
        Upcoming Schedule
      </h2>
      <div className="flex justify-between items-center mb-[25px]">
        <button onClick={handlePreviousDay} className="text-gray-500">
          <img src={arrowLeft} alt="Previous Day" className="w-6 h-6" />
        </button>

        <h3 className="nunitomedium text-[22px] text-[#2B2F42] mb-0">
          {isToday(currentDate) ? "Today, " : ""}{" "}
          {format(currentDate, "dd MMMM yyyy")}
        </h3>

        <button onClick={handleNextDay} className="text-gray-500">
          <img src={arrowRight} alt="Next Day" className="w-6 h-6" />
        </button>
      </div>

      {schedules.map((schedule, index) => (
        <div key={index} className="flex items-start mb-8">
          <div className={` rounded-b rounded-t pl-2 pr-4 relative`}>
            <div
              className={`absolute h-full w-[8px] rounded-[72px] ${schedule.statusColor}`}
            ></div>
            <div className="pl-[12px]">
              <div className="flex items-center">
                <img
                  src={schedule.icon}
                  alt={schedule.name}
                  className="w-[42px] h-[42px] rounded-full mr-4 ml-2"
                />
                <div
                  className={`flex-shrink-0 w-[42px] h-[42px] rounded-full mb-0 ${schedule.statusColor} flex items-center justify-center text-white font-bold`}
                >
                  <span>+</span>
                </div>
              </div>

              <div className="ml-4 mt-[11px]">
                <p className="nunitomedium text-[22px] text-[#2B2F42] mb-0">
                  {schedule.name}
                </p>
                <p className="text-[14px] text-[#2B2F4266]">{schedule.role}</p>
                <p
                  className={`nunitomedium text-[22px] mb-0 ${schedule.timeColor}`}
                >
                  {schedule.time}
                </p>
                <p className="nunitomedium text-[22px] text-[#2B2F4299] mb-0">
                  {schedule.title} {/* Başlık burada gösteriliyor */}
                </p>
                <p className="nunitomedium text-[#2B2F4280] text-[14px] mb-0">
                  {schedule.details}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpcomingScheduleComponent;
