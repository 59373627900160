import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const CombinedChart = () => {
  const chartOptions: ApexOptions = {
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
    },
    colors: ["#00b341", "#00b341"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [0, 3],
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        gradientToColors: ["transparent"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100],
        type: "vertical",
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#e0e0e0",
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
    },
    legend: {
      show: false,
    },
  };

  const seriesData = [
    {
      name: "Area Data",
      type: "area",
      data: [30, 50, 45, 70, 65, 85, 95, 120, 130, 140, 110, 100],
    },
    {
      name: "Line Data",
      type: "line",
      data: [20, 30, 25, 45, 55, 75, 80, 110, 115, 120, 105, 95],
    },
  ];

  return (
    <div className="w-full xxl:w-auto">
      <Chart
        options={chartOptions}
        series={seriesData}
        type="area"
        height={200}
      />
    </div>
  );
};

function PerformanceAnalysis() {
  return (
    <>
      <div
        className={
          "flex-1 cursor-pointer relative flex-col justify-between flex h-[230px] rounded-[32px] shadowds1 bg-[#F4F4F4] h-max-content"
        }
      >
        <div className={"flex flex-col lg:flex-row w-full"}>
          <div
            className={
              "w-full pb-[40px] pr-[20px] rounded-[32px] flex flex-col bg-[#F4F4F4] min-h-[266px] pt-[27px] pl-[21px] shadowds1 relative"
            }
          >
            <div className={"flex flex-col lg:flex-row items-center"}>
              <img
                src={"/media/svg/general/Vector.svg"}
                className={"ml-[13px] mr-[13px] w-[60px] h-[60px]"}
              />
              <p
                className={
                  "nunitoregular text-left text-[22px] text-[#2B2F42] mb-0"
                }
              >
                Average Listing <br /> Closure Time
              </p>
            </div>
            <div>
              <p
                className={
                  "nunitomedium ml-5 text-left text-[14px] text-[#662B2F42] mb-0"
                }
              >
                Monthly
              </p>
            </div>
            <div className={"w-full flex flex-col  lg:flex-row items-center"}>
              <div
                className={
                  "w-full flex  gap-2 items-start flex-col xxl:flex-row"
                }
              >
                <CombinedChart />
                <div className="flex-1 w-full flex flex-col justify-center">
                  <div className="w-full flex justify-between py-[10px] xxl:py-[20px]">
                    <div className="flex items-center gap-[5px] ">
                      <img
                        src={"/media/svg/general/spotify.svg"}
                        className={" w-[25px] h-[25px]"}
                      />
                      <div className="flex flex-col gap-[5px]">
                        <span className="nunitobold text-[#2B2F4266] text-[10px]">
                          Spotify
                        </span>
                        <span className="nunitoregular text-[#2B2F4266] text-[10px]">
                          12 adv
                        </span>
                      </div>
                    </div>
                    <span className="flex items-center nunitoregular text-[#2B2F4266] text-[10px]">
                      2 weeks
                    </span>
                  </div>
                  <div className="w-full h-[1px] bg-[#A1A3AA] bg-opacity-40"></div>
                  <div className="w-full flex justify-between py-[10px] xxl:py-[20px] ">
                    <div className="flex items-center gap-[5px] ">
                      <img
                        src={"/media/svg/general/spotify.svg"}
                        className={" w-[25px] h-[25px]"}
                      />
                      <div className="flex flex-col gap-[5px]">
                        <span className="nunitobold text-[#2B2F4266] text-[10px]">
                          Spotify
                        </span>
                        <span className="nunitoregular text-[#2B2F4266] text-[10px]">
                          12 adv
                        </span>
                      </div>
                    </div>
                    <span className="flex items-center nunitoregular text-[#2B2F4266] text-[10px]">
                      2 weeks
                    </span>
                  </div>
                  <div className="w-full h-[1px] bg-[#A1A3AA] bg-opacity-40"></div>
                  <div className="w-full flex justify-between py-[10px] xxl:py-[20px] ">
                    <div className="flex items-center gap-[5px] ">
                      <img
                        src={"/media/svg/general/spotify.svg"}
                        className={" w-[25px] h-[25px]"}
                      />
                      <div className="flex flex-col gap-[5px]">
                        <span className="nunitobold text-[#2B2F4266] text-[10px]">
                          Spotify
                        </span>
                        <span className="nunitoregular text-[#2B2F4266] text-[10px]">
                          12 adv
                        </span>
                      </div>
                    </div>
                    <span className="flex items-center nunitoregular text-[#2B2F4266] text-[10px]">
                      2 weeks
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PerformanceAnalysis;
