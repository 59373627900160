import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { KTIcon, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";
import "../sideBar.css";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = pathname === to; // Tam eşleşme kontrolü

  const { config } = useLayout();
  const { app } = config;

  return (
    <div className="menu-item">
      <Link
        className={clsx(
          "menu-link without-sub rounded-0 border-0 px-[40px] h-[60px] gap-[10px] xxl:gap-[56px] relative activeItemAbs",
          {
            active: isActive,
          }
        )}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}

        {fontIcon && (
          <i
            className={clsx(
              `text-[24px] bi ${isActive ? "text-[#FE0000]" : ""}`,
              fontIcon
            )}
          ></i>
        )}
        <span className="nunitomedium text-[14px] text-[#2B2F42]">{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
