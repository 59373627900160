import React, { useState } from 'react';
import axios from 'axios';
import {SocialMediaLinks} from "../model";

const SAVE_URL = "URL"; // Buraya API URL'inizi ekleyin

//url ekle

export const CandidateProfileSocial: React.FC = () => {
  const [socialLinks, setSocialLinks] = useState<SocialMediaLinks>({
    facebook: '',
    twitter: '',
    google: '',
    instagram: '',
    linkedin: '',
    github: '',
    discord: '',
    website: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSocialLinks({
      ...socialLinks,
      [name]: value,
    });
  };

  const handleSave = () => {
    axios.post(SAVE_URL, socialLinks)
      .then(response => {
        console.log('Data saved successfully:', response.data);
      })
      .catch(error => {
        console.error('There was an error saving the data:', error);
      });
  };

  const handleCancel = () => {
    setSocialLinks({
      facebook: '',
      twitter: '',
      google: '',
      instagram: '',
      linkedin: '',
      github: '',
      discord: '',
      website: '',
    });
  };

  return (
    <div className="max-w-[1425px] mx-auto p-6 bg-transparent border border-gray-400 rounded-[32px] shadow-lg mt-8">
      <h2 className="text-left text-[#2B2F42] font-nunito font-medium text-[20px] leading-[27.28px] mb-4">
        Social
      </h2>
      <hr className="border-black mb-6" />
      <div className="space-y-4 ml-20">
        {/* Sosyal medya input alanları */}
        <div className="flex items-center">
          <img src="https://cdn-icons-png.flaticon.com/512/174/174848.png" alt="Facebook" className="w-6 h-6 mr-4" />
          <input
            type="text"
            placeholder="Facebook"
            name="facebook"
            value={socialLinks.facebook}
            onChange={handleInputChange}
            className="ml-4 w-[600px] p-2 border border-black rounded-md focus:outline-none focus:border-red-500"
          />
        </div>
        {/* Diğer sosyal medya input alanları benzer şekilde eklenir */}
        <div className="flex items-center">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733579.png" alt="Twitter" className="w-6 h-6 mr-4" />
          <input
            type="text"
            placeholder="Twitter"
            name="twitter"
            value={socialLinks.twitter}
            onChange={handleInputChange}
            className="ml-4 w-[600px] p-2 border border-black rounded-md focus:outline-none focus:border-red-500"
          />
        </div>
        <div className="flex items-center">
          <img src="https://cdn-icons-png.flaticon.com/512/2991/2991148.png" alt="Google" className="w-6 h-6 mr-4" />
          <input
            type="text"
            placeholder="Google"
            name="google"
            value={socialLinks.google}
            onChange={handleInputChange}
            className="ml-4 w-[600px] p-2 border border-black rounded-md focus:outline-none focus:border-red-500"
          />
        </div>
        <div className="flex items-center">
          <img src="https://cdn-icons-png.flaticon.com/512/1409/1409946.png" alt="Instagram" className="w-6 h-6 mr-4" />
          <input
            type="text"
            placeholder="Instagram"
            name="instagram"
            value={socialLinks.instagram}
            onChange={handleInputChange}
            className="ml-4 w-[600px] p-2 border border-black rounded-md focus:outline-none focus:border-red-500"
          />
        </div>
        <div className="flex items-center">
          <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" className="w-6 h-6 mr-4" />
          <input
            type="text"
            placeholder="LinkedIn"
            name="linkedin"
            value={socialLinks.linkedin}
            onChange={handleInputChange}
            className="ml-4 w-[600px] p-2 border border-black rounded-md focus:outline-none focus:border-red-500"
          />
        </div>
        <div className="flex items-center">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733553.png" alt="Github" className="w-6 h-6 mr-4" />
          <input
            type="text"
            placeholder="Github"
            name="github"
            value={socialLinks.github}
            onChange={handleInputChange}
            className="ml-4 w-[600px] p-2 border border-black rounded-md focus:outline-none focus:border-red-500"
          />
        </div>
        <div className="flex items-center">
          <img src="https://cdn-icons-png.flaticon.com/512/2111/2111370.png" alt="Discord" className="w-6 h-6 mr-4" />
          <input
            type="text"
            placeholder="Discord"
            name="discord"
            value={socialLinks.discord}
            onChange={handleInputChange}
            className="ml-4 w-[600px] p-2 border border-black rounded-md focus:outline-none focus:border-red-500"
          />
        </div>
        <div className="flex items-center">
          <img src="https://cdn-icons-png.flaticon.com/512/1409/1409987.png" alt="Your Website" className="w-6 h-6 mr-4" />
          <input
            type="text"
            placeholder="Your Website"
            name="website"
            value={socialLinks.website}
            onChange={handleInputChange}
            className="ml-4 w-[600px] p-2 border border-black rounded-md focus:outline-none focus:border-red-500"
          />
        </div>
      </div>
      <div className="flex justify-center space-x-4 mt-6 mr-[160px]">
        <button className="text-gray-700" onClick={handleCancel}>Cancel</button>
        <button className="bg-red-500 text-white px-16 py-2 rounded-full" onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};

export default CandidateProfileSocial;
