import React, { useEffect, useState } from 'react';
import axios, { HttpStatusCode } from 'axios';
import { candidateManagementModel, candidateModel } from '../model';
import { Process, Role, Education } from '../enums/candidateEnums';
import AddCandidate from './addCandidate';
import { useNavigate } from 'react-router-dom';

const initialValues = [
    {id:"", displayName: "Merve", fullName: "Merve Gündoğan", process: Process.Interview, role: Role.UX_Researcher,  education:Education.Bachelors_Degree, imageUrl: "https://randomuser.me/api/portraits/women/1.jpg" },
    { id:"", displayName: "Emre", fullName: "Emre Yıldız", process: Process.Interview, role: Role.UI_Designer,  education: Education.Masters_Degree, imageUrl: "https://randomuser.me/api/portraits/men/1.jpg" },
    { id:"", displayName: "Halil", fullName: "Halil Sönmez", process: Process.Test, role: Role.UX_Writer,  education: Education.Doctorate_Degree , imageUrl: "https://randomuser.me/api/portraits/men/2.jpg" },
    { id:"", displayName: "Yaren", fullName: "Yaren Bayraktar", process: Process.Offer, role: Role.Visual_Designer,  education: Education.Graduate_Degree, imageUrl: "https://randomuser.me/api/portraits/women/2.jpg" },
    { id:"", displayName: "Bengisu", fullName: "Bengisu Güneş", process: Process.Interview, role: Role.Product_Owner,  education: Education.Professional_Cert, imageUrl: "https://randomuser.me/api/portraits/women/3.jpg" },
    { id:"", displayName: "Mustafa", fullName: "Mustafa Ay", process: Process.Interview, role: Role.Full_stack_Developer,  education: Education.Professional_Deg, imageUrl: "https://randomuser.me/api/portraits/men/3.jpg" },
    { id:"", displayName: "İbrahim", fullName: "İbrahim Yılmaz", process: Process.Test, role: Role.iOS_Developer,  education: Education.Bachelors_Degree, imageUrl: "https://randomuser.me/api/portraits/men/4.jpg" },
    { id:"", displayName: "Mehmet", fullName: "Mehmet Türk", process: Process.Offer, role: Role.Graphic_Designer,  education: Education.Masters_Degree, imageUrl: "https://randomuser.me/api/portraits/men/5.jpg" },
];

const API_URL = "";

//initial values kaldırılacak
//url eklenecek

const CandidateManagement = () => {

    const navigate = useNavigate();
    
    
    const [candidates, setCandidates] = useState<candidateManagementModel[] | []>(initialValues);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(()=> {
        axios.get(API_URL).then(response => {
            setCandidates(response.data);
        })
        .catch(error => {
            console.error("Error saving data", error);
        });
    },[]);

    return (
        <div style={{ padding: '20px', fontFamily: 'Nunito, sans-serif' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <div>
                    <h2 style={{ margin: 0, fontSize: '30px', fontWeight: '500', lineHeight: '35px', color: '#2B2F42' }}>Candidate Management</h2>
                    <p style={{ margin: 0, fontSize: '14px', fontWeight: '500', lineHeight: '20px', color: '#2B2F42', opacity: 0.6 }}>Manage your candidates.</p>
                </div>
                <div className="flex justify-end items-center gap-[20px]">
    <div className="w-[101px] select-none cursor-pointer h-[41px] rounded-[10px] border-[#B0B0B0] border-[1px] flex justify-center items-center gap-1" onClick={() => navigate('/candidateFilters')}>
        <img src="/media/svg/general/Vector (4).svg" className="w-[19px] h-[20px] mt-1" />
        <h5 className="nunitomedium text-[#2B2F42] text-[14px] mt-4">
            Filters
        </h5>
    </div>
    <div className="bg-[#FF4343] cursor-pointer flex items-center w-[160px] h-[41px] gap-[8px] justify-center rounded-[10px]" onClick={() => setShowPopup(true)}>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.5 21C16.2989 21 21 16.2989 21 10.5C21 4.70101 16.2989 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.2989 4.70101 21 10.5 21ZM11.2875 7.35C11.2875 6.91508 10.9349 6.5625 10.5 6.5625C10.0651 6.5625 9.7125 6.91508 9.7125 7.35V9.7125H7.35C6.91508 9.7125 6.5625 10.0651 6.5625 10.5C6.5625 10.9349 6.91508 11.2875 7.35 11.2875H9.7125V13.65C9.7125 14.0849 10.0651 14.4375 10.5 14.4375C10.9349 14.4375 11.2875 14.0849 11.2875 13.65V11.2875H13.65C14.0849 11.2875 14.4375 10.9349 14.4375 10.5C14.4375 10.0651 14.0849 9.7125 13.65 9.7125H11.2875V7.35Z" fill="#F9F9F9" />
        </svg>
        <h4 className="nunitomedium text-[14px] text-[#F9F9F9] mt-3">
            Add Candidate
        </h4>
    </div>
</div>

            </div>
            <div style={{ marginBottom: '20px', color: '#02A0FC', fontSize: '10px', fontWeight: '500', lineHeight: '13.64px' }}>
                <span>Candidates &gt; Management</span>
            </div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        {['Display Name', 'Full Name', 'Process', 'Role', 'Portfolio', 'Resume', 'Education'].map(header => (
                            <th key={header} style={{ padding: '15px', textAlign: 'left', borderBottom: '3px solid #ddd', fontSize: '20px', fontWeight: '600', lineHeight: '20px', color: '#000000' }}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {candidates.map((candidate, index) => (
                        <tr key={index}>
                            <td style={{ padding: '15px', textAlign: 'left', borderBottom: '3px solid #ddd', display: 'flex', alignItems: 'center' }}>
                                <img src={candidate.imageUrl} alt="profile" style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} />
                                <span style={{ fontSize: '16px', fontWeight: '500', lineHeight: '20px', color: '#FF4343' }}>{candidate.displayName}</span>
                            </td>
                            <td style={{ padding: '15px', textAlign: 'left', borderBottom: '3px solid #ddd', fontSize: '16px', fontWeight: '500', lineHeight: '20px', color: '#000000' }}>{candidate.fullName}</td>
                            <td style={{ padding: '15px', textAlign: 'left', borderBottom: '3px solid #ddd', fontSize: '16px', fontWeight: '500', lineHeight: '20px', color: '#000000' }}>{candidate.process}</td>
                            <td style={{ padding: '15px', textAlign: 'left', borderBottom: '3px solid #ddd', fontSize: '16px', fontWeight: '500', lineHeight: '20px', color: '#000000' }}>{candidate.role}</td>
                            <td style={{ padding: '15px', textAlign: 'left', borderBottom: '3px solid #ddd' }}>
                                <button style={{
                                    backgroundColor: '#FF8E01', color: 'black', border: 'none', padding: '5px 10px',
                                    borderRadius: '20px', cursor: 'pointer', fontSize: '14px', fontWeight: '700', lineHeight: '20px', opacity: 0.25, width: '100px', height: '29px'
                                }}>{"Take a look"}</button>
                            </td>
                            <td style={{ padding: '15px', textAlign: 'left', borderBottom: '3px solid #ddd' }}>
                                <a href="#" style={{ color: '#02A0FC', textDecoration: 'none', fontSize: '16px', fontWeight: '500', lineHeight: '20px' }}>{"Download"}<span style={{ fontSize: '12px', marginLeft: '5px' }}>⬇️</span></a>
                            </td>
                            <td style={{ padding: '15px', textAlign: 'left', borderBottom: '3px solid #ddd', fontSize: '16px', fontWeight: '500', lineHeight: '20px', color: '#2B2F42' }}>{candidate.education}</td>
                            <td style={{ padding: '15px', textAlign: 'right' }}>
                                <svg width="17px" height="4px" viewBox="0 0 17 4" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(-90deg)' }}>
                                    <circle cx="2" cy="2" r="2" fill="#2B2F42"/>
                                    <circle cx="8" cy="2" r="2" fill="#2B2F42"/>
                                    <circle cx="15" cy="2" r="2" fill="#2B2F42"/>
                                </svg>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {showPopup && (
            <div 
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                onClick={() => setShowPopup(false)}  // Pop-up dışına tıklanınca kapatma
            >
                <div 
                    className="relative bg-white rounded-[32px] p-8"
                    style={{
                        width: '1441px',
                        height: '758px',
                        top: '50px',
                        left: '121px'
                    }}
                    onClick={(e) => e.stopPropagation()} // Pop-up'ın içine tıklayınca kapanmaması için
                >
                    <AddCandidate cancel={()=> setShowPopup(false)} />
                </div>
            </div>
        )}
        </div>
    );
}

export default CandidateManagement;
