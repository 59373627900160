import React,{useEffect, useState} from 'react';
import axios from 'axios';

const GET_API_URL = "URL";

//url ekle useState i şununla değiştir {score:0, text:"""}

export const CandidateProfileRecom: React.FC = () => {

    const [value,setValue] = useState({score: 90.35, text: "This candidate has a compatibility score of 90.35%, making them a highly suitable profile for the position. They have demonstrated strong performance in technical skills and work experience, meeting most of the position’s requirements. Their communication and problem-solving abilities are also at a high level, making them an ideal candidate for the UX Researcher role."});
    
    useEffect(()=>{

        axios.get(GET_API_URL).then((response: any)=> {
         if (response.result != null) {
             setValue(response.result);
         }     
     });
 
     },[]);

    return (
        <div className="max-w-[1425px] mx-auto p-6 bg-transparent border border-gray-400 rounded-[32px] shadow-lg mt-8">
            <h2 className="text-left text-[#2B2F42] font-nunito font-medium text-[20px] leading-[27.28px] mb-4">
                Recommendation
            </h2>
            <hr className="border-black mb-6" />
            <div className="flex">
                <div className="flex justify-center mr-8 ml-20 mt-10">
                    <div className="flex flex-col items-center">
                        <div className="relative w-24 h-24">
                            <svg className="w-full h-full">
                                <circle
                                    cx="50%"
                                    cy="50%"
                                    r="45%"
                                    fill="none"
                                    stroke="#e5e7eb"
                                    strokeWidth="8"
                                />
                                <circle
                                    cx="50%"
                                    cy="50%"
                                    r="45%"
                                    fill="none"
                                    stroke="#4caf50"
                                    strokeWidth="8"
                                    strokeDasharray="283"
                                    strokeDashoffset="28"
                                    transform="rotate(-90deg)"
                                    style={{ transformOrigin: "50% 50%" }}
                                />
                            </svg>
                            <div className="absolute inset-0 flex items-center justify-center text-xl font-semibold">
                                %{value.score}
                            </div>
                        </div>
                        <span className="text-gray-700 text-sm">Your Score</span>
                    </div>
                </div>
                <div className="text-black text-left font-nunito text-[22px] font-normal leading-[30.01px] ml-10 mb-20 mt-6">
                    {value.text}
                </div>
            </div>
        </div>
    );
};

export default CandidateProfileRecom;
