import {
  AppointmentModel,
  DashboardCardData,
  InterviewDashBoardModel,
  InterviewModel,
  InterviewRecordsModel,
  ParticipantModel,
} from "../model";
import React, { useEffect, useState } from "react";

import InterviewAllResults from "./component/interviewAllResults";
import InterviewCalendar from "./component/interviewCalendar";
import InterviewCancelled from "./component/interviewCancelled";
import InterviewCancelled1 from "./component/interviewCancelled";
import InterviewFeatured from "./component/interviewFeatured";
import InterviewRecords from "./component/interviewRecords";
import { Role } from "../enums/candidateEnums";
import { Status } from "../enums/interviewEnums";
import axios from "axios";
import Chart from "react-apexcharts";
import "./interview.css";

const InterviewCircularProgress = () => {
  const options = {
    chart: {
      type: "radialBar" as const,
      offsetY: 0,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "50%",
        },
        track: {
          background: "#BEE6FD",
          strokeWidth: "100%",
        },
        dataLabels: {
          show: false,
        },
        rounded: true,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "linear",
        shadeIntensity: 1,
        gradientToColors: ["#0500FF80"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    colors: ["#12005A"],
  };

  const series = [60];

  return (
    <div className="chart absolute top-[-14px]">
      <Chart options={options} series={series} type="radialBar" height={120} />
    </div>
  );
};

const InProgressAreaChart = () => {
  const options = {
    chart: {
      type: "area" as const,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth" as const,
      width: 2,
      colors: ["#FF9800"],
    },
    fill: {
      type: "solid",
      opacity: 0.5,
      colors: ["#FF9800"],
    },
    markers: {
      size: [0, 0, 0, 0, 0, 0, 5],
      colors: ["#FF9800"],
      strokeColors: "#FF9800",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Data",
      data: [20, 30, 50, 15, 12, 30, 70],
    },
  ];

  return (
    <div className="chart absolute left-[-50px] top-[-40px]">
      <Chart
        options={options}
        series={series}
        type="area"
        width={200}
        height={150}
      />
    </div>
  );
};

const RecordsCircularProgress = () => {
  const options = {
    chart: {
      type: "radialBar" as const,
      offsetY: 0,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "63%",
        },
        track: {
          background: "#BEE6FD",
          strokeWidth: "100%",
          margin: 0,
        },
        dataLabels: {
          show: false,
        },
        rounded: true,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#5F5CFA80"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    colors: ["#36C5F1"],
  };

  const series = [80];

  return (
    <div className="chart absolute top-[-8px]">
      <Chart options={options} series={series} type="radialBar" height={110} />
    </div>
  );
};

const CancelledBarChart = () => {
  const options = {
    chart: {
      type: "bar" as const,
      height: 200,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        borderRadius: 4,
        endingShape: "rounded",
      },
    },
    colors: ["#023AFF33"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Low", "Medium", "High"],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Series 1",
      data: [20, 30, 60, 80, 90],
    },
  ];

  return (
    <div className="chart absolute left-[-50px] top-[-40px]">
      <Chart
        options={options}
        series={series}
        type="bar"
        width={120}
        height={160}
      />
    </div>
  );
};

const Interview: React.FC = () => {
  const [interviewList, setInterviewList] = useState<InterviewModel[]>([]);

  const [recordList, setRecordList] = useState<InterviewRecordsModel[]>([
    {
      name: "Jack Sparrow",
      status: Status.Completed,
      date: new Date(),
      avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    },
  ]);

  const [participants, setParticipants] = useState<ParticipantModel[]>([]);
  const [appointments, setAppointments] = useState<AppointmentModel[]>([]);

  const API_URL = import.meta.env.VITE_APP_API_URL;

  const API_GET_INTERVIEW = `${API_URL}/services/app/Invites/Get`;
  const API_GET_INTERVIEW_LIST = `${API_URL}/services/app/Invites/GetAll`;
  const API_GET_INTERVIEW_RECORD = `${API_URL}/services/app/Invites/GetRecords`;
  const API_GET_PARTICIPANTS = `${API_URL}/services/app/Invites/GetParticipants`;
  const API_GET_APPOINTMENTS = `${API_URL}/services/app/Invites/GetAppointments`;

  let dashboardData: DashboardCardData[] = [
    {
      title: "Interviews",
      status: "Delayed",
      color: "purple-200",
      icon: "/media/svg/general/interviews.svg",
      label: "Completed",
      subLabel: 60,
      backgroundClass: "bg-[#4339f2]",
    },
    {
      title: "In Progress",
      status: "Delayed",
      color: "orange-100",
      icon: "/media/svg/general/in-progress.svg",
      label: "Today",
      subLabel: 12,
      backgroundClass: "bg-[#FF8E01]",
    },
    {
      title: "Records",
      status: "On track",
      color: "blue-100",
      icon: "/media/svg/general/records.svg",
      label: "Completed",
      subLabel: 81,
      backgroundClass: "bg-[#36C5F1]",
    },
    {
      title: "Cancelled",
      status: "Moderate",
      color: "red-100",
      icon: "/media/svg/general/cancelled.svg",
      label: "Today",
      subLabel: 2,
      backgroundClass: "bg-red-500",
    },
  ];

  const fetchInterviewDashboard = async () => {
    try {
      const response = await axios.get<InterviewDashBoardModel>(
        API_GET_INTERVIEW
      );
      const data = response.data;

      dashboardData = dashboardData.map((card) => {
        if (card.title === "Interviews") {
          return { ...card, subLabel: data.interviews };
        } else if (card.title === "In Progress") {
          return { ...card, subLabel: data.inProgress };
        } else if (card.title === "Records") {
          return { ...card, subLabel: data.records };
        } else if (card.title === "Cancelled") {
          return { ...card, subLabel: data.cancelled };
        }
        return card;
      });
    } catch (error) {
      console.error("Error fetching interview dashboard:", error);
    }
  };

  const fetchInterviewList = async () => {
    try {
      const response = await axios.get(API_GET_INTERVIEW_LIST);
      console.log(response.data);
      setInterviewList(response.data?.result?.items);
    } catch (error) {
      console.error("Error fetching interview list:", error);
    }
  };

  const fetchRecordList = async () => {
    try {
      const response = await axios.get<InterviewRecordsModel[]>(
        API_GET_INTERVIEW_RECORD
      );
      setRecordList(response.data);
    } catch (error) {
      console.error("Error fetching record list:", error);
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await axios.get<ParticipantModel[]>(
        API_GET_PARTICIPANTS
      );
      setParticipants(response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await axios.get<AppointmentModel[]>(
        API_GET_APPOINTMENTS
      );
      setAppointments(response.data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    // fetchInterviewDashboard();
    // fetchInterviewList();
    // fetchRecordList();
    // fetchParticipants();
    // fetchAppointments();
  }, []);

  return (
    <div className="cmax-w-[1800px] py-[33px] px-[10px] xxl:pl-[90px] xxl:pr-[61px] min-h-screen h-full bg-[#F4F4F4]">
      {/* Interview Management Header */}
      <div className="flex justify-between items-center mb-[48px]">
        <div>
          <h1 className="nunitomedium text-[30px] text-[#2B2F42] mb-0">
            Interview Management
          </h1>
          <p className="nunitomedium text-[14px] text-[#2B2F4266] mb-0">
            Manage your interviews.
          </p>
        </div>
        <button className="nunitomedium w-[198px] h-[48px] bg-[#FE0000] text-[14px] font-semibold leading-[20px] text-left text-[#F9F9F9] rounded-t-[32px] rounded-b-[32px] py-2 px-4 shadow-md flex items-center justify-center gap-[18px]">
          Send Invitation
          <img
            src="/media/svg/general/plus2.svg"
            alt="Plus Icon"
            className="w-[32px] h-[32px]"
          />
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 xl-custom:grid-cols-4 gap-6 mb-6">
        {dashboardData.map((card, index) => (
          <div
            key={index}
            className="rounded-[32px] shadow-[0px_1px_4px_rgba(0,0,0,0.25)] relative bg-white"
          >
            <div
              className={`${card.backgroundClass} flex justify-between items-center px-[33px] py-[10px] rounded-tl-[32px] rounded-tr-[32px]`}
            >
              <p className="text-nunitomedium text-[22px] text-[#F9F9F9] mb-0">
                {card.title}
              </p>
              <img
                src={card.icon}
                alt={`${card.title} Icon`}
                className="w-8 h-8"
              />
            </div>
            <div className="p-6 flex justify-center items-center gap-[23%]">
              <div className="w-[88px] h-[88px] relative">
                {card.title === "Interviews" && <InterviewCircularProgress />}
                {card.title === "In Progress" && <InProgressAreaChart />}
                {card.title === "Records" && <RecordsCircularProgress />}
                {card.title === "Cancelled" && <CancelledBarChart />}
              </div>
              <div className="flex flex-col justify-center items-center">
                <p className="nunitomedium text-[#2B2F42] text-[14px]">
                  {card.label}
                </p>
                <h3 className="nunitobold text-[30px] text-[#2B2F42]">
                  {card.title === "Interviews" && "%"}
                  {card.subLabel}
                </h3>
                <span
                  className={`${
                    card.title === "Interviews"
                      ? "bg-[#4339F266]"
                      : card.title === "In Progress"
                      ? "bg-[#FF8E0166]"
                      : card.title === "Records"
                      ? "bg-[#36C5F166]"
                      : card.title === "Cancelled"
                      ? "bg-[#FF434366]"
                      : ""
                  } ${
                    card.title === "Interviews"
                      ? "text-[#4339F2]"
                      : card.title === "In Progress"
                      ? "text-[#FF8E01]"
                      : card.title === "Records"
                      ? "text-[#36C5F1]"
                      : card.title === "Cancelled"
                      ? "text-[#FF4343]"
                      : ""
                  } text-[11px] rounded-full mt-2 w-[64px] h-[17px] flex justify-center items-center nunitomedium`}
                >
                  {card.status}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Featured & Calendar Sections */}
      <div className="grid grid-cols-1 xl-custom:grid-cols-2 gap-6 mb-6">
        <div>
          <h2 className="nunitomedium text-[30px] text-[#2B2F42] mb-[5px]">
            Featured
          </h2>
          <InterviewFeatured
            data={interviewList.filter((i) => i.type === "featured")}
          />
        </div>
        <div>
          <h2 className="nunitomedium text-[30px] text-[#2B2F42] mb-[5px]">
            Calendar
          </h2>
          <InterviewCalendar
            appointments={appointments}
            participants={participants}
          />
        </div>
      </div>

      <div className="gap-6 mb-6">
        <div>
          <h2 className="nunitomedium text-[30px] text-[#2B2F42]">
            All Results
          </h2>
          <InterviewAllResults data={interviewList} />
        </div>
      </div>

      <div className="gap-6 mb-6">
        <div>
          <h2 className="nunitomedium text-[30px] text-[#2B2F42]">Records</h2>
          <InterviewRecords data={recordList} />
        </div>
      </div>

      <div className="gap-6 mb-6">
        <div>
          <h2 className="nunitomedium text-[30px] text-[#2B2F42]">Cancelled</h2>
          <InterviewCancelled1
            data={interviewList.filter((i) => i.type === "cancelled")}
          />
        </div>
      </div>
    </div>
  );
};

export default Interview;
