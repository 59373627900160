import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import build from "@/_metronic/assets/registerlogin/build.svg";

interface CompanyRegistration1Props {
  onBack: () => void;
  onNext: (data: any) => Promise<void>;
  formData: any;
}

const validationSchema = Yup.object({
  crNumber: Yup.string()
    .min(8, "CR Number must be at least 8 characters")
    .required("Commercial Registration (CR) Number is required"),
  industry: Yup.string().required("Industry is required"),
  subIndustry: Yup.string().required("Sub-Industry is required"),
  companyType: Yup.string().required("Company Type is required"),
  employees: Yup.string().required("Number of Employees is required"),
});

const CompanyRegistration1: React.FC<CompanyRegistration1Props> = ({
  onBack,
  onNext,
  formData,
}) => {
  const formik = useFormik({
    initialValues: {
      crNumber: formData.crNumber || "1234567890",
      industry: formData.industry || "Information Technology",
      subIndustry: formData.subIndustry || "Information Technology",
      companyType: formData.companyType || "Joint-Stock Company (JSC)",
      employees: formData.employees || "11-50",
    },
    validationSchema,
    onSubmit: async (values) => {
      const finalFormData = { ...formData, ...values };

      try {
        const response = await axios.post(
          "https://api.example.com/register/company",
          finalFormData
        );
        console.log(response.data);
        alert("Registration successful!");
        onNext(finalFormData);
      } catch (error) {
        console.error("There was an error during registration!", error);
        alert("There was an error during registration.");
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="w-full xl:w-[100%] 2xl:w-[643px] px-[10px] 2xl:px-0 max-w-[643px] rounded-lg">
      <div className="flex justify-start mb-10">
        <img
          src="/media/svg/general/Group 2449.svg"
          alt="Opti Recruits"
          className="w-[230px] h-[60px]"
        />
      </div>

      <h1 className="nunitobold mb-1 text-[26px] lg:text-[30px] text-start text-[#2B2F42]">
        Enter Your Details!
      </h1>
      <p className="nunitoregular italic text-[13px] lg:text-[14px] text-gray-500 mb-[24px] lg:mb-[70px] text-start text-[#2B2F42]">
        Please fill out your profile to access the application.
      </p>
      <div className="flex justify-center w-[100%] mb-6">
        <div className={`w-[25.51%] `}></div>
        <div className="w-[2px] bg-[#ADADAD] h-[65px] rounded-[2px]"></div>
        <div className="flex flex-col justify-center items-center cursor-pointer w-[48.98%]">
          <img
            src={build}
            alt="Education"
            className={`w-[26px] h-[32px] mb-[17px] ${"[filter:invert(36%)_sepia(75%)_saturate(7475%)_hue-rotate(333deg)_brightness(97%)_contrast(104%)]"}`}
          />
          <span className="nunitobold text-[14px] text-[#FF4343]">
            Company Details
          </span>
        </div>
        <div className="w-[2px] bg-[#ADADAD] h-[65px] rounded-[2px]"></div>
        <div className={`w-[25.51%]`}></div>
      </div>

      <form onSubmit={formik.handleSubmit} className="mt-[45.4px]">
        <div className="mb-4">
          <label
            htmlFor="crNumber"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Commercial Registration (CR) Number *
          </label>
          <input
            type="text"
            id="crNumber"
            {...formik.getFieldProps("crNumber")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.crNumber && formik.errors.crNumber
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.crNumber &&
            typeof formik.errors.crNumber === "string" && (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.crNumber}
              </div>
            )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="industry"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Industry *
          </label>
          <select
            id="industry"
            {...formik.getFieldProps("industry")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.industry && formik.errors.industry
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            } select-input`}
          >
            <option value="Information Technology">
              Information Technology
            </option>
            <option value="Finance">Finance</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Retail">Retail</option>
          </select>
          {formik.touched.industry &&
            typeof formik.errors.industry === "string" && (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.industry}
              </div>
            )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="subIndustry"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Sub-Industry *
          </label>
          <select
            id="subIndustry"
            {...formik.getFieldProps("subIndustry")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.subIndustry && formik.errors.subIndustry
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            } select-input`}
          >
            <option value="Information Technology">
              Information Technology
            </option>
            <option value="Software Development">Software Development</option>
            <option value="IT Consulting">IT Consulting</option>
            <option value="Cybersecurity">Cybersecurity</option>
            <option value="Cloud Computing">Cloud Computing</option>
          </select>
          {formik.touched.subIndustry &&
            typeof formik.errors.subIndustry === "string" && (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.subIndustry}
              </div>
            )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="companyType"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Company Type *
          </label>
          <select
            id="companyType"
            {...formik.getFieldProps("companyType")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.companyType && formik.errors.companyType
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            } select-input`}
          >
            <option value="Joint-Stock Company (JSC)">
              Joint-Stock Company (JSC)
            </option>
            <option value="Limited Liability Company (LLC)">
              Limited Liability Company (LLC)
            </option>
            <option value="Partnership">Partnership</option>
            <option value="Sole Proprietorship">Sole Proprietorship</option>
          </select>
          {formik.touched.companyType &&
            typeof formik.errors.companyType === "string" && (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.companyType}
              </div>
            )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="employees"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Number of Employees
          </label>
          <select
            id="employees"
            {...formik.getFieldProps("employees")}
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.employees && formik.errors.employees
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            } select-input`}
          >
            <option value="1-10">1-10</option>
            <option value="11-50">11-50</option>
            <option value="51-200">51-200</option>
            <option value="201-500">201-500</option>
            <option value="500+">500+</option>
          </select>
          {formik.touched.employees &&
            typeof formik.errors.employees === "string" && (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.employees}
              </div>
            )}
        </div>

        <div className="flex gap-[20px] sm:gap-[35px] mt-[30px]">
          <button
            type="button"
            onClick={onBack}
            className="w-100 h-[60px] sm:h-[79.55px] border-[2px] border-[#D9D9D9]  rounded-[40px] nunitomedium text-[#B0B0B0] text-[18px] sm:text-[22px]"
          >
            Back
          </button>
          <button
            type="submit"
            className="w-100 h-[60px] sm:h-[79.55px] border-[2px] border-[#FE0000] bg-[#FE0000] hover:bg-red-500 rounded-[40px] nunitomedium text-[#F9F9F9] text-[18px] sm:text-[22px]"
          >
            Submit
          </button>
        </div>
      </form>

      <p className="nunitosbold text-center mt-[30px] text-[18px] sm:text-[22px]">
        Back to{" "}
        <a href="/" className="text-[#02A0FC]">
          Home
        </a>
      </p>
    </div>
  );
};

export default CompanyRegistration1;
