import { AdvisorFormData, AdvisorResponse } from '@/api/models/Registration/Advisor';
import { CandidateFormData, CandidateResponse } from '@/api/models/Registration/Candidate';
import { CompanyFormData, CompanyResponse } from '@/api/models/Registration/Company';

import { AuthResponse } from '@/api/models/AuthModel';
import { CurrentUserResponse } from '@/api/models/Login/CurrentUser';
import { api } from './api';
import httpClient from '../httpClient';

export const getCurrentUser = async () => {
  const response = await httpClient.get<CurrentUserResponse>(api.currentUser);
  return response.data.Data;
};

export const login = async (email: string, password: string) => {
  const response = await httpClient.post<AuthResponse>(api.login, {
    userNameOrEmailAddress: email,
    password,
  });
  return response.data;
};

export const advisorRegistration = async (data: AdvisorFormData) => {
  const response = await httpClient.post<AdvisorResponse>(api.advisorRegistration, data);
  return response.data;
};

export const candidateRegistration = async (data: CandidateFormData) => {
  const response = await httpClient.post<CandidateResponse>(api.candidateRegistration, data);
  return response.data;
};

export const companyRegistration = async (data: CompanyFormData) => {
  const response = await httpClient.post<CompanyResponse>(api.companyRegistration, data);
  return response.data;
};