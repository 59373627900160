import { useEffect, useState } from "react";

import { CurrentUser } from "@/api/models/Login/CurrentUser";
import { HeaderUserMenu } from "../../../partials";
import { KTIcon } from "../../../helpers";
import clsx from "clsx";
import { useAuth } from "@/app/modules/auth/core/Auth";
import { useLayout } from "../../core";
import { Link } from "react-router-dom";
import avatar from "@/../public/media/avatars/300-1.jpg";

const itemClass = "ms-1 ms-md-4";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px";
const userAvatarClass = "symbol-35px";
const btnIconClass = "fs-2";

const Navbar = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();

  return (
    <div className="app-navbar flex-shrink-0 ">
      <div className={clsx("app-navbar-item hidden xxl:flex", itemClass)}>
        <Link to="/">
          <i className="bi bi-bell text-[26px] text-[#2B2F42] mr-0 xxl:mr-[33px]"></i>
        </Link>

        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <div className="relative w-[42px] h-[42px]  mr-[10px]">
            <img
              src={avatar}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
            {/* if online */}
            <div className="w-[10px] h-[10px] rounded-full bg-[#00D95F] absolute top-0 right-0"></div>
            {/* if offline */}
            <div className="w-[10px] h-[10px] rounded-full bg-[#00D95F] absolute top-0 right-0"></div>
          </div>
        </div>
        <div>
          {currentUser?.name} {currentUser?.surname}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
