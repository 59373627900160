import React, { useState } from "react";
import { advisorRegistration, candidateRegistration, companyRegistration, getCurrentUser } from "@/api/services/userService"

import { AdvisorFormData } from "@/api/models/Registration/Advisor"
import AdvisorRegistration from "./advisorRegistration";
import { CandidateFormData } from "@/api/models/Registration/Candidate"
import CandidateRegistration from "./candidateRegistration";
import CandidateRegistration1 from "./candidateRegistration1";
import { CompanyFormData } from "@/api/models/Registration/Company"
import CompanyRegistration from "./companyRegistration";
import CompanyRegistration1 from "./companyRegistration1";
import ReCAPTCHA from "react-google-recaptcha";
import Tabbar from "./Tabbar";
import ToastService from "@/services/toast.service";
import { useAuth } from "../core/Auth";

export function Registration() {
  const [choosen, setChoosen] = useState<string>("candidate");
  const [step, setStep] = useState<number>(1);
  const [showTabbar, setShowTabbar] = useState<boolean>(true);
  const { saveAuth, setCurrentUser } = useAuth();

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const recaptchaSiteKey = "YOUR_RECAPTCHA_SITE_KEY";

  const onRecaptchaChange = (token: string) => {
    setRecaptchaToken(token);
  };

  const [advisorFormData, setAdvisorFormData] = useState<AdvisorFormData>({
    fullName: "",
    organizationName: "",
    companyEmail: "",
    password: "",
    phone: "",
  });

  const [candidateFormData, setCandidateFormData] = useState<CandidateFormData>({
    resume: null,
    fullname: "",
    username: "",
    email: "",
    password: "",
    summary: {},
    education: [],
    links: {},
  });

  const [companyFormData, setCompanyFormData] = useState<CompanyFormData>({
    organizationName: "",
    companyEmail: "",
    password: "",
    phone: "",
    country: "",
    city: "",
    postCode: "",
    address: "",
    crNumber: "",
    industry: "",
    subIndustry: "",
    companyType: "",
    employees: "",
  });

  const handleAdvisorRegistrationComplete = async (formData: AdvisorFormData) => {
    setAdvisorFormData({ ...advisorFormData, ...formData });

    try {
      const { Status, Data, Message } = await advisorRegistration({ ...advisorFormData, ...formData });

      if (Status == 'Ok') {
        saveAuth(Data.Token);
        const user = await getCurrentUser();
        setCurrentUser(user);
      } else {
        ToastService.success(Message);
      }
    } catch (error) {
      console.error("Error during advisor registration", error);
      ToastService.success('Error during advisor registration');
    }
  };

  const handleCandidateRegistrationNext = (data: CandidateFormData) => {
    setCandidateFormData({ ...candidateFormData, ...data });
    setStep(2);
    setShowTabbar(false);
  };

  const handleCandidateRegistrationComplete = async (data: any) => {
    const allData = {
      ...candidateFormData,
      ...data,
    };

    setCandidateFormData(allData);

    try {
      const response = await candidateRegistration({ ...candidateFormData, ...data });
      alert("Candidate registration successful!");
    } catch (error) {
      console.error("Error during candidate registration", error);
      alert("Registration failed.");
    }
  };

  const handleCompanyRegistrationNext = (data: CompanyFormData) => {
    setCompanyFormData({ ...companyFormData, ...data });
    setStep(2);
    setShowTabbar(false);
  };

  const handleCompanyRegistration1Next = async (data: CompanyFormData) => {
    try {
      const response = await companyRegistration({ ...companyFormData, ...data });
      alert("Company registration successful!");
    } catch (error) {
      console.error("Error during company registration", error);
      alert("Registration failed.");
    }
  };

  return (
    <div>
      {showTabbar && (
        <Tabbar
          type={(accountType: string) => {
            setChoosen(accountType);
            setStep(1);
            setShowTabbar(true);
          }}
        />
      )}

      {choosen === "advisor" && step === 1 && <AdvisorRegistration onNext={handleAdvisorRegistrationComplete} />}

      {choosen === "candidate" && step === 1 && <CandidateRegistration onNext={(data) => handleCandidateRegistrationNext(data)} />}
      {choosen === "candidate" && step === 2 && <CandidateRegistration1 onNext={handleCandidateRegistrationComplete} onBack={() => setStep(1)} />}

      {choosen === "company" && step === 1 && <CompanyRegistration onNext={(data) => handleCompanyRegistrationNext(data)} />}
      {choosen === "company" && step === 2 && <CompanyRegistration1 formData={companyFormData} onNext={handleCompanyRegistration1Next} onBack={() => setStep(1)} />}
    </div>
  );
}
