import axios, { HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { NoteModel } from '../model';

const GET_NOTES_URL = "/URL";
const CREATE_NOTES_URL = "/URL"

const initialValue = {
        id: "1",
        candidateId: '12345',
        strengths: ['Ability to solve problems quickly.', 'Generating innovative ideas.'],
        weaknesses: ['Needs to improve time management skills.'],
        overallImpression: ['Shows great potential for growth.'],
        date: new Date(),
    
};

//URL leri ekle 
//initial values ları kaldır

export const CandidateProfileNotes: React.FC = () => {
    
    const [notes, setNotes] = useState<NoteModel[]>([initialValue]);
    const [strengths, setStrengths] = useState<string>(''); // Güçlü yönler için state
    const [weaknesses, setWeaknesses] = useState<string>(''); // Zayıf yönler için state
    const [overallImpression, setOverallImpression] = useState<string>(''); // Genel izlenim için state



    useEffect(()=> {

        axios.get(GET_NOTES_URL).then((response:any) => {
            if(response.result != null) {
                setNotes(response.result);
            }
        })   
    },[]);

    // Save butonuna basıldığında çağrılacak fonksiyon
    const handleSave = () => {
        const newNote: NoteModel = {
            id: 'some-unique-id', // ID'yi uygun bir yöntemle üretin
            candidateId: 'candidate-123', // Aday ID'sini uygun yerden alın
            strengths: strengths.split('\n'), // Satır satır diziye çevir
            weaknesses: weaknesses.split('\n'), // Satır satır diziye çevir
            overallImpression: overallImpression.split('\n'), // Satır satır diziye çevir
            date: new Date() // Bugünün tarihini kaydet
        };

        axios.put(CREATE_NOTES_URL, newNote).then((response) => {
            if (response.status === 200) {
                setNotes(prevNotes => [...prevNotes, newNote]); // Yeni notu listeye ekle
                setStrengths(''); // Alanları temizle
                setWeaknesses('');
                setOverallImpression('');
            }
        }).catch(error => {
            console.error('Error saving note:', error);
        });
    };

    // Cancel butonuna basıldığında alanları temizleme
    const handleCancel = () => {
        setStrengths(''); // Alanları temizle
        setWeaknesses('');
        setOverallImpression('');
    };

    return (
        <div className="p-8 bg-gray-50 min-h-screen font-nunito text-lg text-gray-800">

            {/* Notlar Bölümü */}
            <div className="flex mt-12 gap-16 ml-16">
            <div className="w-1/3 space-y-6">
                {/* Sol Sütun */}
                <h3 className="font-bold mb-4 border-b border-gray-300 pb-2">Notes</h3>
                <textarea
                    className="w-full h-32 p-4 border border-gray-300 rounded-lg"
                    placeholder="Strengths"
                    value={strengths}
                    onChange={(e) => setStrengths(e.target.value)} // Güçlü yönler
                />
                <textarea
                    className="w-full h-32 p-4 border border-gray-300 rounded-lg mt-4"
                    placeholder="Weaknesses"
                    value={weaknesses}
                    onChange={(e) => setWeaknesses(e.target.value)} // Zayıf yönler
                />
                <textarea
                    className="w-full h-32 p-4 border border-gray-300 rounded-lg mt-4"
                    placeholder="Overall Impression"
                    value={overallImpression}
                    onChange={(e) => setOverallImpression(e.target.value)} // Genel izlenim
                />
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2"
                        onClick={handleSave} // Save butonuna basıldığında veriler kaydedilir
                    >
                        Save
                    </button>
                    <button
                        className="text-gray-500"
                        onClick={handleCancel} // Cancel butonuna basıldığında alanlar temizlenir
                    >
                        Cancel
                    </button>
                </div>
                </div>

                <div className="w-1/3 space-y-6">
                {notes.map((note) => (
                    <div key={note.id} className="p-4 rounded-[20px] shadow-lg">
                        <p className="text-sm text-gray-500 mb-2">{note.date.toLocaleDateString()}</p>
                        <p className="text-sm text-gray-700">
                            <strong>Strengths:</strong>
                            <br />
                            {note.strengths.map((strength, index) => (
                                <span key={index}>• {strength}<br /></span>
                            ))}
                        </p>
                        <p className="text-sm text-gray-700 mt-2">
                            <strong>Weaknesses:</strong>
                            <br />
                            {note.weaknesses.map((weakness, index) => (
                                <span key={index}>• {weakness}<br /></span>
                            ))}
                        </p>
                        <p className="text-sm text-gray-700 mt-2">
                            <strong>Overall Impression:</strong>
                            <br />
                            {note.overallImpression.map((impression, index) => (
                                <span key={index}>• {impression}<br /></span>
                            ))}
                        </p>
                    </div>
                ))}
            </div>
            </div>
        </div>
    );
};

export default CandidateProfileNotes;
