import React, { useState, useEffect, useRef } from "react";
import { Calendar } from "primereact/calendar";
import axios from "axios";
import { AppointmentModel, ParticipantModel } from "../../model";
import avatar from "@/../public/media/avatars/300-1.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";

interface InterviewCalendarProps {
  participants: ParticipantModel[];
  appointments: AppointmentModel[];
}

const InterviewCalendar: React.FC<InterviewCalendarProps> = ({
  participants,
  appointments,
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState("09:00 - 18:00");
  const inviteModalRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(
    null
  );

  const CustomDropdownIndicator = () => null;

  const participantOptions = participants.map((participant) => ({
    value: participant.id,
    label: participant.name,
  }));

  const API_PUT_INVITE = "https://api.example.com/interviews";

  const handleSubmitInvite = async () => {
    if (date && time && selectedCandidate) {
      const payload = {
        candidateId: selectedCandidate,
        date: date.toISOString(),
        timeSlot: time,
      };

      try {
        const response = await axios.put(
          `${API_PUT_INVITE}/${selectedCandidate}`,
          payload
        );
        console.log("Data successfully sent:", response.data);
        setIsOpen(false);
      } catch (error) {
        console.error("Error sending data:", error);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inviteModalRef.current &&
        !inviteModalRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div className="px-[21px] py-[35px] rounded-[32px] shadow-[inset_0px_1px_4px_rgba(0,0,0,0.25)] flex flex-col sm:flex-row h-max-content sm:h-[417px]">
        <div className="flex-[60%] shrink-0 border-r-[2px] border-r-[#FEA800]">
          <h3 className="nunitomedium text-[16px] text-[#2B2F42] text-center mb-[12px]">
            {new Date().toLocaleDateString().replace(/\./g, "/")}
          </h3>
          <div
            className="flex flex-col gap-2 pr-2 mb-[35px] w-full overflow-auto pb-[50px] sm:pb-0"
            style={{ height: "calc(100% - 35px)" }}
          >
            <div className="flex items-center justify-between bg-transparent p-2 rounded-lg">
              <div className="text-gray-700 text-sm">
                <div className="flex flex-col text-right">
                  <span className="nunitoregular text-[12px] text-[#003651]">
                    09.30
                  </span>
                  <img
                    className="w-[1px] h-[9px] mx-auto"
                    src="@/../public/media/icons/sixdot.svg"
                    alt=""
                  />
                  <span className="nunitoregular text-[12px] text-[#003651]">
                    10.00
                  </span>
                </div>
              </div>
              <div className="flex-1 relative">
                <div className="flex items-center pl-[11px] py-[2px] rounded-[4px] shadow-[0px_1px_4px_rgba(0,0,0,0.25)] flex-1 ml-4 relative">
                  <div className="w-[4px] h-[38px] bg-[#FE7A00] absolute left-0 rounded-tl-[4px] rounded-bl-[4px]"></div>
                  <div className="relative">
                    <img
                      src={avatar}
                      alt="Candidate"
                      className="w-[27px] h-[27px] rounded-full mr-[13px]"
                    />
                    <img
                      src={"@/../public/media/icons/orange-camera.svg"}
                      alt="Candidate"
                      className="w-[10px] h-[10px] rounded-full absolute bottom-[-3px] right-[13px] object-contain"
                    />
                  </div>
                  <div className="text-gray-700 text-sm flex-1">
                    <span className="block nunitoregular text-[12px] text-[#2B2F42] leading-[17px]">
                      Jack Sparrow
                    </span>
                    <span className="block nunitoregular text-[12px] text-[#B5B5B5] leading-[17px]">
                      UX Researcher
                    </span>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" className="p-0 no-caret">
                      <img
                        src="/media/svg/general/more-options.svg"
                        alt="More Options"
                        className="w-6 h-6"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="bg-[#f4f4f4] p-0 shadow-[0px_4px_10px_rgba(0,0,0,0.2)]
"
                    >
                      <Dropdown.Item
                        className="w-[140px] h-[25px] flex items-center justify-between border"
                        href="#/action-1"
                      >
                        <span className="nunitomedium text-[12px] text-[#2B2F42]">
                          Edit
                        </span>
                        <img
                          className="object-cover"
                          src="@/../public/media/icons/edit-icon.svg"
                          alt="Edit Icon"
                        />
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="w-[140px] h-[25px] flex items-center justify-between border"
                        href="#/action-2"
                      >
                        <span className="nunitomedium text-[12px] text-[#2B2F42]">
                          Delete
                        </span>
                        <img
                          className="object-cover"
                          src="@/../public/media/icons/delete-icon.svg"
                          alt="Edit Icon"
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="w-full h-[1px] bg-[#B0B0B066] absolute bottom-[-12px]"></div>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <div
            ref={inviteModalRef}
            className="z-10 absolute shadow-[0px_1px_4px_rgba(0,0,0,0.2)] p-6 rounded-[30px] mt-2 sm:ml-0 w-full sm:w-[47%] right-0 sm:right-40 bg-[#f4f4f4]"
          >
            <div className="relative">
              <img
                className="absolute top-1/2 -translate-y-1/2"
                src="@/../public/media/icons/user-plus.svg"
                alt=""
              />
              <Select
                value={participantOptions.find(
                  (option) => option.value === selectedCandidate
                )}
                onChange={(option) =>
                  setSelectedCandidate(option ? option.value : null)
                }
                options={participantOptions}
                placeholder="Katılımcı ekle"
                className="w-full mb-4 border-0"
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: null,
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    boxShadow: "none",
                    border: "none",
                    backgroundColor: "transparent",
                    paddingLeft: 10,
                  }),
                }}
              />
            </div>

            <div className="w-full bg-transparent flex flex-col xxl:flex-row gap-[37px] mt-[30px]">
              <div className="flex-1">
                <div className="realtive nunitosbold text-[16px] text-[#2B2F42] flex items-center gap-[3px]">
                  <img
                    src="@/../public/media/icons/date-icon.svg"
                    alt="Date Icon"
                  />{" "}
                  Tarih*
                </div>
                <input
                  className="w-full border-[1px] border-[#ADADAD] rounded-[5px] h-[47px] bg-transparent px-[11px] py-[14px] mt-[3px]"
                  type="date"
                  value={date ? date.toISOString().split("T")[0] : ""}
                  onChange={(e) =>
                    setDate(e.target.value ? new Date(e.target.value) : null)
                  }
                />
              </div>
              <div className="flex-1">
                <div className="realtive nunitosbold text-[16px] text-[#2B2F42] flex items-center gap-[3px]">
                  <img
                    src="@/../public/media/icons/clock-icon.svg"
                    alt="Date Icon"
                  />{" "}
                  Saat*
                </div>
                <input
                  className="w-full border-[1px] border-[#ADADAD] rounded-[5px] h-[47px] bg-transparent px-[11px] py-[14px] mt-[3px]"
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </div>
              <div className="flex-1 flex items-end justify-end">
                <div className="realtive nunitosbold text-[16px] text-[#2B2F42] flex items-center gap-[3px] opacity-0">
                  <img
                    src="@/../public/media/icons/clock-icon.svg"
                    alt="Date Icon"
                  />{" "}
                  Saat*
                </div>
                <button
                  className="w-[138px] h-[47px] bg-[#FE0000] rounded-[10px] nunitomedium text-white text-[14px] flex justify-center items-center"
                  onClick={handleSubmitInvite}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        <div className=" w-[260px] mx-auto sm:w-[40%] sm:flex-[40%] shrink-0 imCalendarContainer nunitomedium">
          <Calendar
            value={date}
            onChange={(e) => {
              setDate(e.value || null);
              setIsOpen(true);
            }}
            inline
            showWeek
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default InterviewCalendar;
