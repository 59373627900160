import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";

interface RichTextEditorProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const RichTextEditor = ({ value, setValue }: RichTextEditorProps) => {
  return (
    <div className="bg-[#FDFDFD]">
      <ReactQuill
        value={value}
        onChange={setValue}
        className="w-full border border-gray-300 rounded-lg h-[478px] mb-[80px] lg:mb-20 bg-[#FDFDFD] nunitoregular"
        placeholder="We are seeking a skilled and passionate professional for the position of UX/UI Designer..."
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["link"],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "list",
          "bullet",
          "align",
          "link",
        ]}
      />
    </div>
  );
};

const CreateJobPost2 = () => {
  const navigate = useNavigate();
  const [editorValue, setEditorValue] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      template: "",
      jobDescription: editorValue,
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      navigate("/createjobpostlayout/step3");
    },
  });

  const handleEditorChange: React.Dispatch<React.SetStateAction<string>> = (
    value
  ) => {
    const content = typeof value === "function" ? value(editorValue) : value;
    setEditorValue(content);
    formik.setFieldValue("jobDescription", content);
  };
  return (
    <form onSubmit={formik.handleSubmit} className="p-[10px] lg:p-6  ">
      <div className="flex flex-col lg:flex-row gap-[20px] lg:gap-[130px]">
        {/* Left side - Job Description */}
        <div className="flex-1">
          <h2 className="nunitomedium text-[22px] text-[#2B2F42] mb-4">
            Job Description
          </h2>
          {/* Template selection */}
          <div className="flex space-x-4 mb-4">
            <label className="inline-flex items-center">
              <div className="flex flex-col ml-2 gap-2">
                <div className="flex items-center gap-1">
                  <input
                    type="radio"
                    name="template"
                    value="template1"
                    className="form-radio text-[#FF4343] border-[#FF4343] focus:ring-[#FF4343] focus:border-[#FF4343]"
                    onChange={formik.handleChange}
                  />
                  <span className="nunitoregular text-[14px] text-[#000000]">
                    Template 1
                  </span>
                </div>
                <span className="nunitoregular text-[10px] text-[#FE0000]">
                  (Recommended by AI)
                </span>
              </div>
            </label>

            <label className="inline-flex items-center">
              <div className="flex flex-col ml-2 gap-2">
                <div className="flex items-center gap-1">
                  <input
                    type="radio"
                    name="template"
                    value="template2"
                    className="form-radio text-red-500"
                    onChange={formik.handleChange}
                  />
                  <span className="nunitoregular text-[14px] text-[#000000]">
                    Template 2
                  </span>
                </div>
                <span className="nunitoregular text-[10px] text-[#FE0000]">
                  (Recommended by AI)
                </span>
              </div>
            </label>

            <label className="inline-flex items-center">
              <div className="flex flex-col ml-2 gap-2">
                <div className="flex items-center gap-1">
                  <input
                    type="radio"
                    name="template"
                    value="template3"
                    className="form-radio text-red-500"
                    onChange={formik.handleChange}
                  />
                  <span className="nunitoregular text-[14px] text-[#000000]">
                    Template 3
                  </span>
                </div>
                <span className="nunitoregular text-[10px] text-[#FE0000]">
                  (Recommended by AI)
                </span>
              </div>
            </label>
          </div>

          <RichTextEditor value={editorValue} setValue={handleEditorChange} />

          <div className="flex gap-[20px]">
            <button
              type="submit"
              className="w-[130px] h-[50px] bg-[#FE0000] text-[14px] text-white nunitoregular rounded-[10px]"
            >
              Save
            </button>
            <button className="w-[130px] h-[50px] text-[14px] text-[#2B2F42] nunitoregular">
              Cancel
            </button>
          </div>
        </div>

        {/* Right side - Skills */}
        <div className="flex-1">
          <h2 className="nunitomedium text-[22px] text-[#2B2F42] mb-4">
            Skills
          </h2>

          <div className="flex flex-wrap gap-[20px]">
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Metrics
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Team Work
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Project Management
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Creativity
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              UX Design
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              A/B Testing
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              User Testing
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Prototyping
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Realtion Management
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Data Analysis
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Problem Solving
            </span>
            <span className="px-[18px] py-[10px] h-[41px] bg-[#FF4343] nunitomedium text-[16px] text-[#F9F9F9] rounded-[20px]">
              Wireframing
            </span>
          </div>
        </div>
      </div>

      {/* Floating Next Button */}
      <div className="flex justify-end mt-6">
        <button onClick={() => formik.handleSubmit()}>
          <img
            src="/public/media/svg/general/ileriok.svg"
            alt="Next"
            className="w-30 h-30"
          />
        </button>
      </div>
    </form>
  );
};

export default CreateJobPost2;
