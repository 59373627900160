import './app.css';

import React, { useEffect, useState } from 'react';

import AddCandidate from './addCandidate';
import CandidateCard from './candicateCard';
import { CandidateModel } from '@/api/models/CandidateResponse';
import { getAll } from '@/api/services/candidateService';

const Candidate: React.FC = () => {
    const [candidates, setCandidate] = useState<CandidateModel[]>([]);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                setLoading(true);
                const response = await getAll();
                setCandidate(response.Data);
            } catch (err) {
                setError('Veri yüklenirken hata oluştu');
            } finally {
                setLoading(false);
            }
        };

        fetchCandidates();
    }, []);

    const [general, setGeneral] = useState({
        selectall1: false,
        type: "general",
        extra: "a"
    });

    return (
        <div className={'w-full h-full flex flex-row'}>
            <div className={'flex flex-col relative pb-[50px] bg-[#F4F4F4] min-h-screen w-full pl-[20px] md:pl-[90px] pt-[33px] pr-[40px] gap-2'}>
                {general.type === "general" && (
                    <div className={'flex flex-col'}>
                        <div className={'w-full mt-8 flex-col sm:flex-row sm:gap-2 gap-6 sm:mt-[25px] flex justify-end items-center sm:items-start sm:justify-between'}>
                            <div className={'flex flex-col sm:items-start items-center w-fit gap-[5px]'}>
                                <h4 className={'nunitomedium text-[30px] text-[#2B2F42]'}>
                                    Candidates
                                </h4>
                                <h3 className={'nunitomedium opacity-60 text-[14px] text-[#2B2F42]'}>
                                    Let's compare the candidates.
                                </h3>
                            </div>
                            <div className={'flex flex-row gap-[14px] mr-2'}>
                                <div className={'bg-[#FF4343] cursor-pointer flex items-center w-[160px] h-[41px] gap-[8px] justify-center rounded-[10px]'}>
                                    <img src="/media/svg/general/Group 2443.svg" className="w-[25px] h-[23px]" />
                                    <p className={'nunitomedium text-[14px] text-[#F9F9F9] mt-4'}>
                                        Management
                                    </p>
                                </div>
                                <div className={'w-[101px] select-none cursor-pointer h-[41px] rounded-[10px] border-[#B0B0B0] border-[1px] flex justify-center items-center gap-1'}>
                                    <img src="/media/svg/general/Vector (4).svg" className="w-[19px] h-[20px] mt-1" />
                                    <h5 className={'nunitomedium text-[#2B2F42] text-[14px] mt-4'}>
                                        Filters
                                    </h5>
                                </div>
                                <div onClick={() => setShowPopup(true)} className={'bg-[#FF4343] cursor-pointer flex items-center w-[160px] h-[41px] gap-[8px] justify-center rounded-[10px]'}>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 21C16.2989 21 21 16.2989 21 10.5C21 4.70101 16.2989 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.2989 4.70101 21 10.5 21ZM11.2875 7.35C11.2875 6.91508 10.9349 6.5625 10.5 6.5625C10.0651 6.5625 9.7125 6.91508 9.7125 7.35V9.7125H7.35C6.91508 9.7125 6.5625 10.0651 6.5625 10.5C6.5625 10.9349 6.91508 11.2875 7.35 11.2875H9.7125V13.65C9.7125 14.0849 10.0651 14.4375 10.5 14.4375C10.9349 14.4375 11.2875 14.0849 11.2875 13.65V11.2875H13.65C14.0849 11.2875 14.4375 10.9349 14.4375 10.5C14.4375 10.0651 14.0849 9.7125 13.65 9.7125H11.2875V7.35Z" fill="#F9F9F9" />
                                    </svg>
                                    <h4 className={'nunitomedium text-[14px] text-[#F9F9F9] mt-3'}>
                                        Add Candidate
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-[42px]'}>

                            <div>
                                <div className={'flex flex-row items-center gap-[31px]'}>
                                    <h4 className={'nunitomedium text-[24px]'}>Application</h4>
                                    <div className={'px-[10px] text-[12px] text-[#ffffff] relative nunitomedium flex items-center justify-center h-[26px] bg-[#D9D9D9] rounded-[3px]'}>
                                        <h6 className={"text-[#ffffff] mt-3"}>100</h6>
                                        <p className={'absolute -top-1 right-1'}> +</p>
                                    </div>
                                </div>
                                <div className={'flex flex-row mt-[33px]'}>
                                    <div className={'w-[116px] select-none cursor-pointer relative h-[25px] flex items-center justify-center bg-[#D9D9D9] rounded-[4px] mr-[70px]'}>
                                        <h6 className={'nunitomedium mr-2 text-[#5A5A5A] text-[12px] mt-2'}>
                                            Processes
                                        </h6>
                                    </div>
                                    <div className={'flex flex-row'}>
                                        <h6 className={'nunitomedium text-[#7D7D7D] text-[12px] mt-2'}>
                                            Select all
                                        </h6>
                                        <div className="checkbox-wrapper-15 flex items-center">
                                            <input
                                                onClick={() => {
                                                    setGeneral({ ...general, selectall1: !general.selectall1 })
                                                }}
                                                className={'inp-cbx'} id="cbx-30" type="checkbox"
                                                style={{ display: "none" }}
                                            />
                                            <label className="cbx !border-gray-300" htmlFor="cbx-30">
                                                <span>
                                                    <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                        <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {candidates.filter(i => i.status === "Application").map((candidate, index) => (
                                    <CandidateCard key={index} candidate={candidate} index={index} />
                                ))}
                            </div>

                            <div>
                                <div className={'flex flex-row items-center gap-[31px]'}>
                                    <h4 className={'nunitomedium text-[24px]'}>In Progress</h4>
                                    <div className={'px-[10px] text-[12px] text-[#ffffff] relative nunitomedium flex items-center justify-center h-[26px] bg-[#D9D9D9] rounded-[3px]'}>
                                        <h6 className={"text-[#ffffff] mt-3"}>100</h6>
                                        <p className={'absolute -top-1 right-1'}> +</p>
                                    </div>
                                </div>
                                <div className={'flex flex-row mt-[33px]'}>
                                    <div className={'w-[116px] select-none cursor-pointer relative h-[25px] flex items-center justify-center bg-[#D9D9D9] rounded-[4px] mr-[70px]'}>
                                        <h6 className={'nunitomedium mr-2 text-[#5A5A5A] text-[12px] mt-2'}>
                                            Processes
                                        </h6>
                                    </div>
                                    <div className={'flex flex-row'}>
                                        <h6 className={'nunitomedium text-[#7D7D7D] text-[12px] mt-2'}>
                                            Select all
                                        </h6>
                                        <div className="checkbox-wrapper-15 flex items-center">
                                            <input
                                                onClick={() => {
                                                    setGeneral({ ...general, selectall1: !general.selectall1 })
                                                }}
                                                className={'inp-cbx'} id="cbx-31" type="checkbox"
                                                style={{ display: "none" }}
                                            />
                                            <label className="cbx !border-gray-300" htmlFor="cbx-31">
                                                <span>
                                                    <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                        <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {candidates.filter(i => i.status === "In Progress").map((candidate, index) => (
                                    <CandidateCard key={index} candidate={candidate} index={index} />
                                ))}
                            </div>

                            <div>
                                <div className={'flex flex-row items-center gap-[31px]'}>
                                    <h4 className={'nunitomedium text-[24px]'}>Interview</h4>
                                    <div className={'px-[10px] text-[12px] text-[#ffffff] relative nunitomedium flex items-center justify-center h-[26px] bg-[#D9D9D9] rounded-[3px]'}>
                                        <h6 className={"text-[#ffffff] mt-3"}>100</h6>
                                        <p className={'absolute -top-1 right-1'}> +</p>
                                    </div>
                                </div>
                                <div className={'flex flex-row mt-[33px]'}>
                                    <div className={'w-[116px] select-none cursor-pointer relative h-[25px] flex items-center justify-center bg-[#D9D9D9] rounded-[4px] mr-[70px]'}>
                                        <h6 className={'nunitomedium mr-2 text-[#5A5A5A] text-[12px] mt-2'}>
                                            Processes
                                        </h6>
                                    </div>
                                    <div className={'flex flex-row'}>
                                        <h6 className={'nunitomedium text-[#7D7D7D] text-[12px] mt-2'}>
                                            Select all
                                        </h6>
                                        <div className="checkbox-wrapper-15 flex items-center">
                                            <input
                                                onClick={() => {
                                                    setGeneral({ ...general, selectall1: !general.selectall1 })
                                                }}
                                                className={'inp-cbx'} id="cbx-32" type="checkbox"
                                                style={{ display: "none" }}
                                            />
                                            <label className="cbx !border-gray-300" htmlFor="cbx-32">
                                                <span>
                                                    <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                        <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {candidates.filter(i => i.status === "Interview").map((candidate, index) => (
                                    <CandidateCard key={index} candidate={candidate} index={index} />
                                ))}
                            </div>

                            <div>
                                <div className={'flex flex-row items-center gap-[31px]'}>
                                    <h4 className={'nunitomedium text-[24px]'}>BlackList</h4>
                                    <div className={'px-[10px] text-[12px] text-[#ffffff] relative nunitomedium flex items-center justify-center h-[26px] bg-[#D9D9D9] rounded-[3px]'}>
                                        <h6 className={"text-[#ffffff] mt-3"}>100</h6>
                                        <p className={'absolute -top-1 right-1'}> +</p>
                                    </div>
                                </div>
                                <div className={'flex flex-row mt-[33px]'}>
                                    <div className={'w-[116px] select-none cursor-pointer relative h-[25px] flex items-center justify-center bg-[#D9D9D9] rounded-[4px] mr-[70px]'}>
                                        <h6 className={'nunitomedium mr-2 text-[#5A5A5A] text-[12px] mt-2'}>
                                            Processes
                                        </h6>
                                    </div>
                                    <div className={'flex flex-row'}>
                                        <h6 className={'nunitomedium text-[#7D7D7D] text-[12px] mt-2'}>
                                            Select all
                                        </h6>
                                        <div className="checkbox-wrapper-15 flex items-center">
                                            <input
                                                onClick={() => {
                                                    setGeneral({ ...general, selectall1: !general.selectall1 })
                                                }}
                                                className={'inp-cbx'} id="cbx-33" type="checkbox"
                                                style={{ display: "none" }}
                                            />
                                            <label className="cbx !border-gray-300" htmlFor="cbx-33">
                                                <span>
                                                    <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                        <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {candidates.filter(i => i.status === "BlackList").map((candidate, index) => (
                                    <CandidateCard key={index} candidate={candidate} index={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>


            {showPopup && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    onClick={() => setShowPopup(false)}
                >
                    <div
                        className="relative bg-white rounded-[32px] p-8"
                        style={{
                            width: '1441px',
                            height: '758px',
                            top: '50px',
                            left: '121px'
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <AddCandidate cancel={() => setShowPopup(false)} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Candidate;
