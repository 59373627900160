import * as Yup from "yup";
import logosvg from "@/_metronic/assets/registerlogin/logo.svg";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const initialValues = {
  email: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        setHasErrors(false);
        setLoading(false);
        setSubmitting(false);
        navigate("../../forgot-password-result");
      }, 1000);
    },
  });

  return (
    <div className="w-100 xl:w-[100%] 2xl:w-[643px] px-[10px] 2xl:px-0 max-w-[643px] rounded-lg">
      <img
        className="select-none cursor-pointer mx-auto"
        src={logosvg}
        alt=""
      />

      <form className="form w-full" noValidate onSubmit={formik.handleSubmit}>
        <div className={"flex flex-col w-full flex justify-start items-center"}>
          <h5
            className={
              "text-[#FF4343] text-center select-none mt-[41px] nunitoregular text-[40px] mb-0"
            }
          >
            Forgot Password?
          </h5>
          <h5
            className={
              "my-[70px] w-full text-start select-none cursor-pointer text-[#2B2F42] text-[16px] nunitoregular italic"
            }
          >
            Type your registered e-mail address or user name to set a new
            password. Your password change link will be sent to your e-mail
            address.
          </h5>
          <div className={"flex flex-col max-w-[1800px] w-full"}>
            <div className={"mt-[54px] gap-[33px] flex flex-col w-full "}>
              <div className={"flex w-full flex-col"}>
                {/* begin::Form group */}
                <div className="fv-row mb-[30px]">
                  <label className="block text-[22px] nunitosbold text-[#2B2F42]">
                    Email or Username
                  </label>
                  <input
                    type="email"
                    placeholder="Please enter your email or username"
                    autoComplete="off"
                    {...formik.getFieldProps("email")}
                    className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                      formik.touched.email && formik.errors.email
                        ? "border-[#FE0000]"
                        : "border-[#ADADAD]"
                    }`}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-[#FE0000] text-[14px] mt-[7px]">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="nunitomedium w-full h-[60px] sm:h-[79.55px] bg-[#FE0000] text-white p-3 rounded-[40px] text-[18px] sm:text-[22px] hover:bg-red-600"
                  >
                    <span className="indicator-label nunitomedium select-none text-white text-[22px]">
                      Reset Password
                    </span>
                    {loading && (
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Form group */}
              </div>
            </div>

            <p className="nunitosbold text-center mt-[30px] text-[18px] sm:text-[22px]">
              Back to{" "}
              <a href="/auth" className="text-[#02A0FC]">
                Login
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}
