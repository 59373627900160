import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

interface User {
  id: string;
  name: string;
  role: string;
  price: string;
  staringdate: string;
}

const StartingRecruitment: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  // Demo verilerini oluşturma
  const demoData: User[] = [
    {
      id: "1",
      name: "Merve Gündoğdu",
      role: "UI / UX Designer",
      price: "$2000",
      staringdate: "2024-09-20",
    },
  ];

  // useEffect ile demo verilerini state'e atama
  useEffect(() => {
    // Simülasyon: Gerçek API'den veri çekiyormuş gibi ayarlama
    setTimeout(() => {
      setUsers(demoData);
      setLoading(false);
    }, 1000); // 1 saniye bekletiyoruz
  }, []);

  // POST Request: Starting Process
  const handleStartProcess = (userId: string) => {
    fetch("https://api.example.com/start-process", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    })
      .then((response) => {
        if (response.ok) {
          alert("Process started and notification sent!");
        }
      })
      .catch((error) => {
        console.error("Error starting process:", error);
      });
  };

  // Starting Process butonunun template'i
  const actionTemplate = (rowData: User) => (
    <button className="px-6 py-2 rounded-full bg-[#fe0000] text-white" onClick={() => handleStartProcess(rowData.id)}>
      Starting Process
    </button>
  );

  return (
    <div>
      {/* Tablonun Başlığı */}
      <div className="p-6 shadow-lg rounded-lg bg-white">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTable value={users} responsiveLayout="scroll">
            <Column
              field="name"
              header="Name"
              body={(rowData) => (
                <div className="flex items-center">
                  <img src="https://randomuser.me/api/portraits/women/1.jpg" alt="profile" className="w-10 h-10 rounded-full mr-4" />
                  {rowData.name}
                </div>
              )}
            />
            <Column field="role" header="Role" />
            <Column field="price" header="Price" />
            <Column field="staringdate" header="Starting Date" />
            <Column body={actionTemplate} />
          </DataTable>
        )}
      </div>
    </div>
  );
};

export default StartingRecruitment;
