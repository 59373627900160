import React from "react";
import { useNavigate } from "react-router-dom";

const CreateJobPost4 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    // Bir sonraki sayfaya ilerle
    navigate("/createjobpostlayout/complete");
  };

  return (
    <div className="p-[10px] lg:p-6 pb-[120px] flex flex-col lg:flex-row justify-between h-max-content">
      <div>
        {/* Başlık */}
        <h2 className="nunitomedium text-[22px] text-[#2B2F42] leading-[30.01px] text-left mb-2">
          Publish Post
        </h2>
        <p className="nunitoregular text-[14px] text-[#2B2F42] leading-[20px] text-left  mb-4">
          Copy and paste this code into your website.{" "}
          <a href="#" className="text-[14px] text-[#02A0FC]">
            Learn more...
          </a>
        </p>

        {/* Link input */}
        <div className="w-full lg:w-[728px] relative mb-6 shadow-custom bg-trasnparent  rounded-[10px]">
          <img
            src="/public/media/svg/general/link.svg"
            alt="Link"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
          />
          <input
            type="text"
            className="block w-full lg:w-[728px] h-[48px] pl-12 p-3  ml-10 bg-transparent"
            value="https://www.jobwars.de/jobs"
          />
        </div>

        <p className="nunitoregular text-[14px] text-[#2B2F42] font-medium leading-[20px] text-left  mb-4">
          Share this on your favorite social networks
        </p>

        {/* Sosyal medya kartları */}
        <div className="flex justify-start flex-wrap gap-[20px] mb-6">
          <a href="#">
            <div className="w-[204px] h-[243px] rounded-lg flex items-center justify-center">
              <img
                src="/public/media/svg/general/linkedincard.svg"
                alt="LinkedIn"
                className="w-[200px] h-[250px]"
              />
            </div>
          </a>
          <a href="#">
            <div className="w-[204px] h-[243px] rounded-lg flex items-center justify-center">
              <img
                src="/public/media/svg/general/xingcard.svg"
                alt="Xing"
                className="w-[200px] h-[250px]"
              />
            </div>
          </a>
          <a href="#">
            <div className="w-[204px] h-[243px] rounded-lg flex items-center justify-center">
              <img
                src="/public/media/svg/general/googlecard.svg"
                alt="Google"
                className="w-[200px] h-[250px]"
              />
            </div>
          </a>
        </div>

        {/* Onay kutusu */}
        <div className="flex items-center mb-6">
          <input type="checkbox" className="mr-5 checkbox shrink-0" />
          <p className="nunitomedium text-[14px] text-[#2B2F42] mb-0">
            I've read and agree with{" "}
            <a href="#" className="text-[14px] text-[#02A0FC]">
              Opti Recruits
            </a>{" "}
            terms of service and our privacy policy
          </p>
        </div>

        {/* Tarih ve butonlar */}
        <div className="flex flex-col">
          <p className="nunitomedium text-[14px] text-[#000000] leading-[20px] text-left">
            Choose date share later
          </p>
          <div className="flex items-center flex-col sm:flex-row gap-[10px] sm:gap-[30px]">
            <select className="block w-full sm:w-[150px] h-[50px] p-3 border border-gray-300 rounded-lg bg-transparent">
              <option>Choose Date</option>
            </select>
            <button className="bg-[#FE0000] rounded-[10px] text-white w-full sm:w-[130px] h-[50px] rounded-lg">
              Publish
            </button>
            <button className="text-[#2B2F42] w-[full] sm:w-[100px] h-[50px] rounded-lg">
              Cancel
            </button>
          </div>
        </div>
      </div>
      {/* Preview kartı */}
      <div className="mt-[20px] lg:mt-[0px] w-full lg:w-[376px] h-max-content bg-[#F4F4F4] rounded-[20px] shadow-custom border border-gray-300 p-6 ">
        <h2 className="nunitomedium text-[22px] text-[#2B2F42] leading-[25px] text-center mb-[24px]">
          Preview
        </h2>
        <div className="flex items-center justify-center w-[120px] h-[120px] bg-white mx-auto mb-[20px] rounded-full">
          <img
            src="/public/media/svg/general/prevcard.svg"
            alt="Company Logo"
            className="w-[62px] h-[62px]"
          />
        </div>
        <div className="text-center">
          <p className="nunitoregular text-[18px] text-[#00000066]">Slack</p>
          <div className="flex flex-col items-start w-[164px] mx-auto mb-[11px]">
            <p className="nunitomedium text-[22px] text-[#2B2F42] leading-[30px] mb-0">
              UI/UX Designer
            </p>
            <p className="nunitoregular text-[14px] text-[#00000066] mb-0">
              London
            </p>
          </div>
          <p className="nunitoregular text-[12px] text-[#00000066] mb-[67px]">
            0-4 Years • 15-90 Employees
          </p>
          <div className="flex flex-wrap justify-start gap-[16px] mb-[20px] w-[320px] mx-auto">
            <span className="nunitoregular bg-[#F0F0F0] text-[#2B2F42] px-[11px] py-[5px] rounded-[6px]">
              User Research
            </span>
            <span className="nunitoregular bg-[#F0F0F0] text-[#2B2F42] px-[11px] py-[5px] rounded-[6px]">
              Wireframe
            </span>
            <span className="nunitoregular bg-[#F0F0F0] text-[#2B2F42] px-[11px] py-[5px] rounded-[6px]">
              Mockups
            </span>
            <span className="nunitoregular bg-[#F0F0F0] text-[#2B2F42] px-[11px] py-[5px] rounded-[6px]">
              Figma
            </span>
            <span className="nunitoregular bg-[#F0F0F0] text-[#2B2F42] px-[11px] py-[5px] rounded-[6px]">
              Adobe XD
            </span>
          </div>
          <div className="flex flex-wrap justify-start gap-[16px] pb-[38px] w-[320px] mx-auto">
            <span className="nunitoregular bg-[#FED7FA] text-[#2B2F42] px-[11px] py-[5px] rounded-[6px]">
              Full Time
            </span>
            <span className="nunitoregular bg-[#FFE5D3] text-[#2B2F42] px-[11px] py-[5px] rounded-[6px]">
              Remote
            </span>
          </div>
        </div>
      </div>

      {/* Sabitlenen Sonraki Buton */}
      {/* <div className="fixed bottom-10 right-10">
        <button onClick={handleNext}>
          <img
            src="/public/media/svg/general/ileriok.svg"
            alt="Next"
            className="w-30 h-30"
          />
        </button>
      </div> */}
    </div>
  );
};

export default CreateJobPost4;
