export enum Process{
    Interview = "Interview",
    Test = "Test",
    Offer = "Offer"
}

export enum Role {
    UX_Researcher ="UX Researcher",
    UI_Designer ="UI Designer",
    UX_Writer ="UX Writer",
    Visual_Designer = "Visual Designer",
    Product_Owner = "Product_Owner",
    Full_stack_Developer = "Full-stack Developer",
    iOS_Developer = "iOS Developer",
    Graphic_Designer = "Graphic Designer"
}

export enum Education {

    Bachelors_Degree = "Bachelor's Degree",
    Masters_Degree = "Master's Degree",
    Doctorate_Degree =  "Doctorate Degree",
    Graduate_Degree = "Graduate Degree",
    Professional_Cert = "Professional Cert",
    Professional_Deg = "Professional Deg"
}

export enum Company {
 discord = "Discord",
}

export enum Gender {
    Female = "Female",
    Male = "Male"
}

export enum LanguageLevel {
    Native = "Native",
    Intermediate = "Intermediate", 
    Advanced = "Advanced", 
    UpperIntermediate = "Upper-Intermediate"
}

export enum Status {
    Completed = "Completed",
    Scheduled = "Scheduled",
    Cancelled = "Cancelled",
}