import React, { useEffect, useState } from "react";
import { format, addDays, subDays } from "date-fns";
import axios from "axios";
import { InterviewModel } from "../model";
import { Role, Status } from "../enums/candidateEnums";

const activitieInitialValues: InterviewModel[] = [
  {
    name: "Esra K. Elden",
    role: "Head of HR",
    time: "10 am - 11 am",
    type: "Interview",
    details: "Please prepare and bring these documents with you before the interview: Current CV and Portfolio.",
    icon: "https://randomuser.me/api/portraits/women/1.jpg",
    designation: Role.Product_Owner,
    status: Status.Completed,
    date: new Date(),
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
    invite: "Yes", // Add missing properties
    userName: "esra.k.elden",
    fullName: "Esra K. Elden",
    profilePictureId: 12345,
    jobTitle: "Head of HR",
  },
  {
    name: "Esra K. Elden",
    role: "Head of HR",
    time: "10 am - 11 am",
    type: "Interview",
    details: "Please prepare and bring these documents with you before the interview: Current CV and Portfolio.",
    icon: "https://randomuser.me/api/portraits/women/1.jpg",
    designation: Role.Product_Owner,
    status: Status.Completed,
    date: new Date(),
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
    invite: "Yes",
    userName: "esra.k.elden",
    fullName: "Esra K. Elden",
    profilePictureId: 12345,
    jobTitle: "Head of HR",
  },
  {
    name: "Esra K. Elden",
    role: "Head of HR",
    time: "10 am - 11 am",
    type: "Interview",
    details: "Please prepare and bring these documents with you before the interview: Current CV and Portfolio.",
    icon: "https://randomuser.me/api/portraits/women/1.jpg",
    designation: Role.Product_Owner,
    status: Status.Completed,
    date: new Date(),
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
    invite: "Yes",
    userName: "esra.k.elden",
    fullName: "Esra K. Elden",
    profilePictureId: 12345,
    jobTitle: "Head of HR",
  },
];

const GET_API_URL = "URL";

export const CandidateProfileActivities: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [activities, setActivities] = useState<InterviewModel[]>(activitieInitialValues);

  const handleNextDay = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  const handlePrevDay = () => {
    setCurrentDate((prevDate) => subDays(prevDate, 1));
  };

  const fetchData = (date: Date) => {
    axios
      .get(GET_API_URL, {
        params: { date: date },
      })
      .then((response: any) => {
        if (response.result != null) {
          response.result;
        }
      });
  };

  useEffect(() => {
    fetchData(currentDate);
  }, [currentDate]);

  return (
    <div className="max-w-[1425px] mx-auto p-6 bg-transparent border border-gray-400 rounded-[32px] shadow-lg mt-8">
      <h2 className="text-left text-[#2B2F42] font-nunito font-medium text-[20px] leading-[27.28px] mb-4">Activities</h2>
      <hr className="border-black mb-6" />

      <div className="text-center mb-8">
        <h3 className="text-2xl font-semibold text-[#2B2F42]">Activities</h3>

        <h3 className="text-lg font-semibold text-[#2B2F42] flex justify-center items-center">
          <button onClick={handlePrevDay} className="text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 mr-4">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          {format(currentDate, "dd MMMM yyyy")}
          <button onClick={handleNextDay} className="text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 ml-4">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </h3>
      </div>

      <div className="space-y-4 ml-16 mb-20">
        {activities.map((activity, index) => (
          <div key={index} className="flex items-start ">
            <div className={`border-l-8  border-red-500 rounded-2 pl-2 pr-4`}>
              <div className="flex items-center">
                <img src={activity.icon} alt={activity.name} className="w-10 h-10 rounded-full mr-2 ml-4 mb-4" />
                <div className={`flex-shrink-0 w-10 h-10 rounded-full mb-4 bg-red-500 flex items-center justify-center text-white font-bold`}>
                  <span>+</span>
                </div>
              </div>
              <div className="ml-4">
                <h3 className="font-semibold text-black">{activity.name}</h3>
                <p className="text-gray-600 ">{activity.role}</p>
              </div>
            </div>

            <div className="ml-8">
              <p className={`font-bold text-red-600 mt-0 mb-1`}>{activity.time}</p>
              <p className="text-black font-semibold mt-0 mb-1">{activity.type}</p>
              <p className="text-gray-600 mt-0 ">{activity.details}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CandidateProfileActivities;
