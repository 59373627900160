import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const WaveformChart = () => {
  const options: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 4,
      colors: ["#00FF00"],
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Waveform",
      data: [10, 20, 15, 30, 10, 25, 15, 20],
    },
  ];

  return (
    <div className="absolute  lg:-left-[10px] top-[30px]">
      <Chart
        options={options}
        series={series}
        type="line"
        height={90}
        width={150}
      />
    </div>
  );
};

interface Props {
  percentage: number;
  viewed: number;
}

function ProfileViewers({ percentage, viewed }: Props) {
  return (
    <>
      <div className={"flex-1 flex flex-col w-full h-full"}>
        <div className={" w-full  h-full flex flex-col lg:flex-row  "}>
          <div
            className={
              "2xl:w-full w-full lg:w-[521px] gap-[68px]   pb-[40px] pr-[20px] rounded-[32px] flex flex-col bg-[#F4F4F4] min-h-[266px] pt-[27px] pl-[21px] shadowds1"
            }
          >
            <div
              className={
                "flex flex-col lg:flex-row gap-[10px] lg:gap-0 justify-between items-center"
              }
            >
              <h5 className={"nunitomedium text-[30px] text-[#2B2F42] mb-0"}>
                Profile Viewers
              </h5>
              <div
                className={
                  "shadowds1 cursor-pointer flex  sm:mt-0 sm:flex-row gap-[5px] px-[12px] py-[6px] rounded-[12px]"
                }
              >
                <svg
                  width="26"
                  height="18"
                  viewBox="0 0 26 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.52 17.75C14.0744 17.75 12.0029 16.9013 10.3056 15.204C8.60827 13.5067 7.75961 11.4352 7.75961 8.98959C7.75961 6.56224 8.60827 4.4988 10.3056 2.79928C12.0029 1.09976 14.0744 0.25 16.52 0.25C18.9474 0.25 21.0108 1.09976 22.7104 2.79928C24.4099 4.4988 25.2596 6.56224 25.2596 8.98959C25.2596 11.4352 24.4099 13.5067 22.7104 15.204C21.0108 16.9013 18.9474 17.75 16.52 17.75ZM16.5096 16.5C18.593 16.5 20.3638 15.7708 21.8221 14.3125C23.2805 12.8542 24.0096 11.0833 24.0096 9C24.0096 6.91667 23.2805 5.14583 21.8221 3.6875C20.3638 2.22917 18.593 1.5 16.5096 1.5C14.4263 1.5 12.6555 2.22917 11.1971 3.6875C9.7388 5.14583 9.00964 6.91667 9.00964 9C9.00964 11.0833 9.7388 12.8542 11.1971 14.3125C12.6555 15.7708 14.4263 16.5 16.5096 16.5ZM19.5938 13.0072L20.4856 12.1154L17.1346 8.76441V4.38462H15.8846V9.26684L19.5938 13.0072ZM1.99039 4.625V3.375H6.50961V4.625H1.99039ZM0.740387 9.625V8.375H6.50961V9.625H0.740387ZM1.99039 14.625V13.375H6.50961V14.625H1.99039Z"
                    fill="#2B2F42"
                  />
                </svg>
                <h5 className={"nunitomedium text-[14px] text-[#2B2F42] mb-0"}>
                  Today
                </h5>
              </div>
            </div>
            <div
              className={
                "flex flex-col gap-12 lg:gap-0 lg:flex-row  justify-between items-center"
              }
            >
              <div className={"flex flex-col gap-2 items-center relative"}>
                <h6 className={"nunitomedium text-[40px] text-[#14ED44]"}>
                  {percentage}%
                </h6>
                <WaveformChart />
              </div>
              <h6
                className={
                  "text-[22px] nunitoregular text-[#2B2F42] w-[276px] mb-0 mt-[80px] lg:mt-0"
                }
              >
                Your profile got {viewed} viewed today. View your analytics.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileViewers;
