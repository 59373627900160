import React, { useState, useEffect } from 'react';
import {DocumentModel} from '../model';
import axios from 'axios';

const attachmentsInitialValue = [
    { title: 'Reference Letter', date: '08.07.2024', thumbnail: '/media/thumbnails/reference-letter-1.png', isOpen: false },
    { title: 'Reference Letter', date: '08.07.2024', thumbnail: '/media/thumbnails/reference-letter-2.png', isOpen: true }
];

const GET_MESSAGE_URL = "URL";

export const CandidateProfileAttachments: React.FC = () => {

    const [attachments, setAttachment] = useState<DocumentModel[]>(attachmentsInitialValue);

    useEffect(()=>{

        axios.get(GET_MESSAGE_URL).then((response: any)=> {
            if (response.result != null) {
                setAttachment(response.result);
            }     
        });
        
    },[]);


    return (
        <div className="p-8 bg-gray-50 min-h-screen font-nunito text-lg text-gray-800">
            <div className="mt-12 bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold mb-4">Attachments</h3>
                <div className="flex gap-8">
                    {attachments.map((attachment, index) => (
                        <div key={index} className="p-4 bg-gray-100 rounded-[20px] shadow-lg">
                            <img
                                src={attachment.thumbnail}
                                alt={attachment.title}
                                className="w-48 h-48 object-cover rounded-md mb-4"
                            />
                            <p className="text-sm text-gray-700 font-semibold">{attachment.title}</p>
                            <p className="text-sm text-gray-500">{attachment.date}</p>
                            <button className="mt-2 bg-red-500 text-white px-4 py-1 rounded-lg">Open</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CandidateProfileAttachments;
