import React, { useState } from "react";
import { format, addDays, subDays, isToday } from "date-fns";
import arrowLeft from "@/../public/media/dashboard/arrow-left.svg";
import arrowRight from "@/../public/media/dashboard/arrow-right.svg";
const UpcomingScheduleComponentCandidate: React.FC = () => {
  const schedules: any[] = [];

  const [currentDate, setCurrentDate] = useState(new Date());

  const handleNextDay = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  const handlePreviousDay = () => {
    setCurrentDate((prevDate) => subDays(prevDate, 1));
  };
  return (
    <div className="shadow-custom-black mt-[16px] rounded-[32px] px-[20px] py-[17px] w-full h-[91%] xl-custom:w-[400px] shrink-0 ">
      <h2 className="nunitomedium text-[30px] text-[#2B2F42] mb-[20px]">
        Upcoming Schedule
      </h2>
      <div className="flex justify-between items-center mb-[25px]">
        <button onClick={handlePreviousDay} className="text-gray-500">
          <img src={arrowLeft} alt="Previous Day" className="w-6 h-6" />
        </button>

        <h3 className="nunitomedium text-[22px] text-[#2B2F42] mb-0">
          {isToday(currentDate) ? "Today, " : ""}{" "}
          {format(currentDate, "dd MMMM yyyy")}
        </h3>

        <button onClick={handleNextDay} className="text-gray-500">
          <img src={arrowRight} alt="Next Day" className="w-6 h-6" />
        </button>
      </div>

      {schedules.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-500 mb-4">
            There is no upcoming schedule for today.
          </p>
          <img
            src="media/svg/general/OBJECTS.svg"
            alt="No Schedule"
            className="w-[250px] mx-auto mb-6"
          />
          <button className="bg-red-500 text-white rounded-full px-6 py-2 flex items-center justify-center mx-auto">
            <span className="text-xl font-bold mr-2">+</span>
            <span>Create</span>
          </button>
        </div>
      ) : (
        schedules.map((schedule, index) => (
          <div key={index} className="flex items-start mb-8">
            <div
              className={`border-l-4 ${schedule.borderColor} rounded-b rounded-t pl-2 pr-4`}
            >
              <div className="flex items-center">
                <img
                  src={schedule.icon}
                  alt={schedule.name}
                  className="w-12 h-12 rounded-full mr-4 ml-2"
                />
                <div
                  className={`flex-shrink-0 w-10 h-10 rounded-full mb-4 ${schedule.statusColor} flex items-center justify-center text-white font-bold`}
                >
                  <span>+</span>
                </div>
              </div>
              <div className="ml-4">
                <p className="font-semibold text-lg text-[#2B2F42]">
                  {schedule.name}
                </p>
                <p className="text-gray-500 text-sm">{schedule.role}</p>
                <p className={`font-semibold ${schedule.timeColor} mt-2`}>
                  {schedule.time}
                </p>
                <p className="text-gray-500 text-sm">{schedule.details}</p>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default UpcomingScheduleComponentCandidate;
