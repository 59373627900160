import { InterviewModel } from "../../model";
import React from "react";
import avatar from "@/../public/media/avatars/300-1.jpg";

interface InterviewProps {
  data: InterviewModel[];
}

const InterviewAllResults: React.FC<InterviewProps> = ({ data }) => {
  const getStatusClass = (status: string) => {
    switch (status) {
      case "Scheduled":
        return "text-orange-500";
      case "Completed":
        return "text-green-500";
      case "Cancelled":
        return "text-red-500";
      default:
        return "";
    }
  };

  return (
    <div className="px-[22px] py-[27px] pt-0 rounded-[32px] shadow-[0px_1px_4px_rgba(0,0,0,0.25)] h-[417px] overflow-auto">
      <table className="w-full text-left">
        <thead className="border-b-[1px] border-b-[#000000] sticky top-0 z-10 bg-[#F4F4F4]">
          <tr>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] w-[20%] h-[75px] pt-[27px] pr-[100px] sm:pr-[0px] whitespace-nowrap ">
              Name
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] w-[28%] h-[75px] pt-[27px] whitespace-nowrap">
              Designation
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] h-[75px] pt-[27px] whitespace-nowrap">
              Status
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] h-[75px] pt-[27px] whitespace-nowrap">
              Date
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] h-[75px] pt-[27px] whitespace-nowrap">
              Time
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="hover:bg-gray-100">
            <td className="border px-0 py-4  flex items-center flex-nowrap nunitomedium text-[14px] text-[#2B2F42] w-[200px] sm:w-[max-content]">
              <img
                src={avatar}
                alt="Avatar"
                className="w-[52px] h-[52px] rounded-full mr-[8%] shrink-0"
              />
              <span className="nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
                John Doe
              </span>
            </td>
            <td className="border px-0 py-4 nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              Software Engineer
            </td>
            {/* completed => text-[#34B53A] scheduled => #FF8E01 cancelled => #FE0000 */}
            <td className="border px-0 py-4 nunitobold text-[14px] text-[#34B53A] whitespace-nowrap">
              Completed
            </td>
            <td className="border px-0 py-4 nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              {new Date().toLocaleDateString()}
            </td>
            <td className="border px-0 py-4 nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              {new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InterviewAllResults;
