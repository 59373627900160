import React, { useEffect, useState } from "react";
import EmployeesCard from "./employeesCard"; // Çalışan kartı bileşeni

interface Employee {
  name: string;
  role: string;
  imageUrl: string;
  gradientColor: string;
}

const HiredEmployees: React.FC<Employee> = ({ name, role, imageUrl, gradientColor }) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);

  // API'den çalışan verilerini çekme
  useEffect(() => {
    fetch("https://api.example.com/hired-employees") // API URL'sini buraya ekleyin
      .then((response) => response.json())
      .then((data) => {
        setEmployees(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="p-6 bg-[#F4F4F4] min-h-screen bg-white">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">{loading ? <p>Loading...</p> : employees.map((employee, index) => <EmployeesCard key={index} name={employee.name} role={employee.role} imageUrl={employee.imageUrl} gradientColor={employee.gradientColor} />)}</div>
    </div>
  );
};

export default HiredEmployees;
