import React from "react";
import { InterviewModel } from "../../model";
import avatar from "@/../public/media/avatars/300-1.jpg";

interface InterviewProps {
  data: InterviewModel[];
}

const InterviewFeatured: React.FC<InterviewProps> = ({ data }) => {
  return (
    <div className="px-[22px] py-[27px] rounded-[32px] shadow-[0px_1px_4px_rgba(0,0,0,0.25)] h-[417px]">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="border-b-[1px] border-b-[#B0B0B066]">
            <tr>
              <th className="nunitosbold text-[22px] text-[#2B2F42] w-[200px] sm:w-[260px] ">
                Name
              </th>
              <th className="nunitosbold text-[22px] text-[#2B2F42]">
                Designation
              </th>
              <th className="text-left text-gray-700 text-xl font-semibold px-6 py-4"></th>
            </tr>
          </thead>
        </table>
        <div className="max-h-[325px] overflow-y-auto scrollbar-custom">
          <table>
            <tbody>
              {/* {data.map((i, index) => (
              <tr key={index} className="border-b">
                <td className="flex items-center px-6 py-4">
                  <img
                    src={`${i.avatar} + ${index + 1}.jpg`}
                    alt="Candidate"
                    className="w-10 h-10 rounded-full mr-4"
                  />
                  <span>{i.name}</span>
                </td>
                <td className="px-6 py-4">{i.designation}</td>
                <td className="px-6 py-4">
                  <span className="bg-[#4339f2] text-white py-1 px-4 rounded-full">
                    {i.status}
                  </span>
                </td>
              </tr>
            ))} */}
              <tr>
                <td className="flex items-center   py-[12px] w-[170px] sm:w-[260px]">
                  <img
                    src={avatar}
                    alt="Candidate"
                    className="w-[52px] h-[52px] rounded-full mr-[5%] sm:mr-[15%]"
                  />
                  <span className="nunitomedium text-[16px] text-[#2B2F42]  ">
                    John Doe
                  </span>
                </td>
                <td className="nunitomedium text-[16px] text-[#2B2F42] py-[12px] pr-20 whitespace-nowrap">
                  UX/UI Designer
                </td>
                <td className="px-6 py-[12px]">
                  <div className="w-[144px] h-[31px] bg-[#4339F2] rounded-[20px] flex justify-center items-center nunitomedium text-[16px] text-[#F9F9F9] cursor-pointer">
                    Start Interview
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InterviewFeatured;
