import React, { useState } from 'react';
import { CandidateProfileSummary } from './candidateProfileSummary';
import { CandidateProfileAttachments } from './candidateProfileAttachments';
import { CandidateProfileHistory } from './candidateProfileHistory';
import { CandidateProfileNotes } from './candidateProfileNotes';
import { CandidateProfileSocial } from './candidateProfileSocial';
import { CandidateProfileInbox } from './candidateProfileInbox';
import { CandidateProfileJobs } from './candidateProfileJobs';
import { CandidateProfileRecom } from './candidateProfileRecom';
import { CandidateProfileActivities } from './candidateProfileActivities';




const CandidateProfile: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('Summary');

    const tabs = [
        { name: 'Summary', icon: 'summary' },
        { name: 'Inbox', icon: 'inbox' },
        { name: 'Social', icon: 'social' },
        { name: 'Jobs', icon: 'jobs' },
        { name: 'Recommendation', icon: 'recommendation' },
        { name: 'Activities', icon: 'activities' },
        { name: 'Notes', icon: 'notes' },
        { name: 'Attachments', icon: 'attachments' },
        { name: 'History', icon: 'history' }
    ];

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Summary':
                return <CandidateProfileSummary/>;
            case 'Inbox':
                return <CandidateProfileInbox/>;
            case 'Social':
                return <CandidateProfileSocial/>;
            case 'Jobs':
                return <CandidateProfileJobs/>;
            case 'Recommendation':
                return <CandidateProfileRecom/>;
            case 'Activities':
                return <CandidateProfileActivities/>;
            case 'Notes':
                return <CandidateProfileNotes/>;
            case 'Attachments':
                return <CandidateProfileAttachments/>;
            case 'History':
                return <CandidateProfileHistory/>;
            default:
                return <CandidateProfileSummary/>;
        }
    };

    return (
        <div className=" min-h-screen font-nunito text-lg text-gray-800">
            {/* Profil ve Skor Bölümü */}
            <div className="flex justify-between items-center p-6 rounded-lg shadow-md ">
    <div className="flex items-center ml-20">
        <div>
            <img
                src="https://randomuser.me/api/portraits/women/1.jpg"
                alt="Profile"
                className="w-20 h-20 rounded-full mr-4"
            />
            
        </div>
        <div>
            
            <div className=" ml-2">
                <h2 className="text-2xl font-bold ">Merve Gündoğan</h2>
                <p className="text-gray-500">UX Researcher</p>
                <div>
                    <span className="text-sm text-white bg-[#ff8e01] px-3 py-1 rounded-full">
                        In Progress
                    </span>
                </div>
            </div>
        </div>
        <div className="flex items-center justify-center mr-6">
            <div className="flex flex-col items-center ml-20">
                <div className="relative w-24 h-24">
                    <svg className="w-full h-full">
                        <circle
                            cx="50%"
                            cy="50%"
                            r="45%"
                            fill="none"
                            stroke="#e5e7eb"
                            strokeWidth="8"
                        />
                        <circle
                            cx="50%"
                            cy="50%"
                            r="45%"
                            fill="none"
                            stroke="#4caf50"
                            strokeWidth="8"
                            strokeDasharray="283"
                            strokeDashoffset="28"
                            transform="rotate(-90deg)"
                            style={{ transformOrigin: "50% 50%" }}
                        />
                    </svg>
                    <div className="absolute inset-0 flex items-center justify-center text-xl font-semibold">
                        %90.35
                    </div>
                </div>
                <span className="text-gray-500 text-sm">Your Score</span>
            </div>
        </div>
    </div>
    <div className="flex items-center">
        <button className="text-[#fe0000] px-4 py-2 rounded-[20px] border-2 border-[#fe0000] flex items-center">
            <img 
                src="/media/svg/general/mingcute_document-2-line.svg"
                className="w-4 h-4 mr-2"
                alt="Download CV"
            />
            <span className="mr-2">Download CV</span>
        </button>
        <img 
            src="/media/svg/general/tabler_download.svg"
            className="w-6 h-6 ml-4"
            alt="Download CV"
        />
    </div>
</div>




            <div className="flex flex-col">
    {/* Üst çizgi */}
    <div className="border-b border-[#090909] " />

    {/* Sekmeler */}
    <div className="flex justify-left  p-4 rounded-lg ">
        {tabs.map((tab, index) => (
            <button
                key={index}
                className={`flex items-center font-medium mr-14 ${
                    activeTab === tab.name ? 'text-red-500' : 'text-black'
                }`}
                onClick={() => setActiveTab(tab.name)}
            >
                <img
                    src={`/media/svg/general/${tab.icon}.svg`}
                    alt={`${tab.name} icon`}
                    className={`w-6 h-6 mr-2 ${activeTab === tab.name ? 'fill-red-500' : 'fill-gray-500'}`}
                />
                {tab.name}
            </button>
        ))}
    </div>

    {/* Alt çizgi */}
    <div className="border-b border-[#090909] " />
</div>

    
            {renderTabContent()}
            
        </div>
    );
};

export default CandidateProfile;
