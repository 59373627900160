import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AdminManagement from "../modules/adminManagement/admin1";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import Candidate from "../modules/candidate/candidate";
import CandidateDashboard from "../modules/dashboard/candidate-dashboard";
import CandidateFilters from "../modules/candidate/candidateFilters";
import CandidateManagement from "../modules/candidate/candidateManagement";
import CandidateProfile from "../modules/candidate/candidateProfile";
import CompanyDashboard from "../modules/dashboard/company-dashboard";
import CreateJobPostLayout from "../modules/jobposting/createjobpostlayout";
import JobPost from "../modules/jobposting/jobPost";
import CreatejobPost from "../modules/job-management/Jobs-post";
import CreatejobPositions from "../modules/job-management/Jobs-positions";
import CreatejobCompanies from "../modules/job-management/Jobs-companies";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import Interview from "../modules/interview/interview";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { MenuTestPage } from "../pages/MenuTestPage";
import OnboardingManagement from "../modules/Onboarding/onboardingManagement";
import TopBarProgress from "react-topbar-progress-indicator";
import { WithChildren } from "../../_metronic/helpers";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />

        {/* Dashboards */}
        <Route path="candidates" element={<Candidate />} />
        <Route path="candidateManagement" element={<CandidateManagement />} />
        <Route path="candidateProfile" element={<CandidateProfile />} />
        <Route path="candidateFilters" element={<CandidateFilters />} />

        <Route path="jobs" element={<CreatejobPost height="750px" />} />
        <Route path="jobs-positions" element={<CreatejobPositions />} />
        <Route path="jobs-companies" element={<CreatejobCompanies />} />

        <Route path="job-postings" element={<CreateJobPostLayout />} />
        <Route path="job-postings-pre" element={<JobPost />} />

        <Route path="interview-management" element={<Interview></Interview>} />
        <Route path="onboarding" element={<OnboardingManagement />} />

        <Route path="administration" element={<AdminManagement />} />

        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
