import React, { useState } from "react";
import Summary from "./summary";
import Education from "./education";
import YourLinks from "./yourLinks";
import axios from "axios";

interface CandidateRegistration1Props {
  onNext: (data: any) => Promise<void>;
  onBack: () => void;
}

const CandidateRegistration1: React.FC<CandidateRegistration1Props> = ({
  onNext,
  onBack,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [summaryData, setSummaryData] = useState({});
  const [educationData, setEducationData] = useState({});
  const [linksData, setLinksData] = useState({});

  const handleSummarySubmit = (data: any) => {
    setSummaryData(data);
    setActiveTab(2);
  };

  const handleEducationSubmit = (data: any) => {
    setEducationData(data);
    setActiveTab(3);
  };

  const handleLinksSubmit = async (data: any) => {
    setLinksData(data);
    const allData = {
      summary: summaryData,
      education: educationData,
      links: data,
    };

    await onNext(allData);
  };

  return (
    <div className="w-full xl:w-[100%] 2xl:w-[643px]  px-[10px] 2xl:px-0 max-w-[643px] rounded-lg">
      <div className="flex justify-start mb-10">
        <img
          src="/media/svg/general/Group 2449.svg"
          alt="Opti Recruits"
          className="w-[230px] h-[60px]"
        />
      </div>

      <h1 className="nunitobold mb-1 text-[26px] lg:text-[30px] text-start text-[#2B2F42]">
        Enter Your Details!
      </h1>
      <p className="nunitoregular italic text-[13px] lg:text-[14px] text-gray-500 mb-[24px] lg:mb-[70px] text-start text-[#2B2F42]">
        Please fill out your profile to access the application.
      </p>

      <div className="flex justify-center w-[100%] mb-6">
        <div
          className={`flex flex-col justify-center items-start cursor-pointer w-[25.51%] ${
            activeTab === 1 ? "text-[#FF4343]" : "text-[#2B2F42]"
          }`}
          onClick={() => setActiveTab(1)}
        >
          <div className="flex flex-col items-center">
            <img
              src="/media/svg/general/Group 2201.svg"
              className={`w-[26px] h-[32px] mb-[17px] ${
                activeTab === 1 &&
                "[filter:invert(36%)_sepia(75%)_saturate(7475%)_hue-rotate(333deg)_brightness(97%)_contrast(104%)]"
              }`}
            />
            <span className="nunitobold text-[14px] ">Summary</span>
          </div>
        </div>

        <div className="w-[2px] bg-[#ADADAD] h-[65px] rounded-[2px]"></div>

        <div
          className={`flex flex-col justify-center  items-center cursor-pointer  w-[48.98%] ${
            activeTab === 2 ? "text-[#FF4343]" : "text-[#2B2F42]"
          }`}
          onClick={() => setActiveTab(2)}
        >
          <img
            src="/media/svg/general/Group 2318.svg"
            alt="Education"
            className={`w-[26px] h-[32px] mb-[17px] ${
              activeTab === 2 &&
              "[filter:invert(36%)_sepia(75%)_saturate(7475%)_hue-rotate(333deg)_brightness(97%)_contrast(104%)]"
            }`}
          />
          <span className="nunitobold text-[14px]">Education</span>
        </div>

        <div className="w-[2px] bg-[#ADADAD] h-[65px] rounded-[2px]"></div>

        <div
          className={`flex flex-col justify-center items-end cursor-pointer  w-[25.51%] ${
            activeTab === 3 ? "text-[#FF4343]" : "text-[#2B2F42]"
          }`}
          onClick={() => setActiveTab(3)}
        >
          <div className="flex flex-col items-center">
            <img
              src="/media/svg/general/Group 2224.svg"
              alt="Your Links"
              className={`w-[26px] h-[32px] mb-[17px] ${
                activeTab === 3 &&
                "[filter:invert(36%)_sepia(75%)_saturate(7475%)_hue-rotate(333deg)_brightness(97%)_contrast(104%)]"
              }`}
            />

            <span className="nunitobold text-[14px]">Your Links</span>
          </div>
        </div>
      </div>

      <div className="tab-content mt-[45.4px]">
        {activeTab === 1 && (
          <Summary onSubmit={handleSummarySubmit} onBack={onBack} />
        )}
        {activeTab === 2 && (
          <Education onNext={handleEducationSubmit} onBack={onBack} />
        )}
        {activeTab === 3 && (
          <YourLinks onComplete={handleLinksSubmit} onBack={onBack} />
        )}
      </div>
    </div>
  );
};

export default CandidateRegistration1;
