import React, {useState, useEffect} from 'react';
import { InboxModel } from '../model';
import axios from 'axios';

const initialInboxData: InboxModel[] = [
    {
      id: "1",
      title: "Position Closed",
      text: "The position you applied for has been closed. We appreciate your interest in Assa Teknoloji.",
      isRead: false,
    },
    {
      id: "2",
      title: "Your application has been approved",
      text: "Your application has been successfully accepted! Stay tuned for the next steps with Assa Teknoloji.",
      isRead: false,
    },
    {
      id: "3",
      title: "Document request",
      text: "You are required to upload the necessary documents for the UX Researcher position. Please visit your profile to upload the document.",
      isRead: true,
    },
    {
      id: "4",
      title: "New message",
      text: "You have received a message from the HR team. Click to view the message.",
      isRead: true,
    },
    {
      id: "5",
      title: "Interview invitation",
      text: "You have an interview invitation for the UX Researcher position with Assa Teknoloji. Interview date: August 17, 2024, 10:00 AM.",
      isRead: true,
    },
    {
      id: "6",
      title: "Your application is under review",
      text: "Our HR team is reviewing your application for the UX Researcher position. You will receive feedback shortly.",
      isRead: true,
    },
  ];

const GET_MESSAGE_URL = "URL";
const PUT_MESSAGE_URL = "URL2";

//url leri ekle
//initialInboxData i sil yerine [] bunu koy

export const CandidateProfileInbox: React.FC = () => {

    const [message, setMessage] = useState<InboxModel[]>(initialInboxData);

    useEffect(()=>{

        axios.get(GET_MESSAGE_URL).then((response: any)=> {
            if (response.result != null) {
                setMessage(response.result);
            }     
        });
        
    },[]);

    const handleRead = (id: any) => {
        setMessage((prevMessages) =>
            prevMessages.map((msg, index) =>
              msg.id === id ? { ...msg, isRead: true } : msg
            )
        );

        axios.put(PUT_MESSAGE_URL,id);
    }


    return (
        <div className="max-w-[1425px] mx-auto p-6 bg-transparent border border-gray-400 rounded-[32px] shadow-lg mt-8">
            <h2 className="text-left text-[#2B2F42] font-nunito font-medium text-[20px] leading-[27.28px] mb-4">
                Inbox
            </h2>
            <hr className="border-black mb-6" />

            <div className="">
                {message.map(value => 
                    <div
                    className={`p-2 rounded-md flex items-center  ${
                      value.isRead
                        ? "border-b border-gray-400"
                        : "bg-red-100 border border-red-200 cursor-pointer"
                    }`}
                    onClick={()=> handleRead(value.id)}
                    >
                    <div
                      className={`mr-4 ${value.isRead ? "mr-8" : "w-3 h-3 bg-red-500 rounded-full"}`}
                    ></div>
                    <div>
                      <h3 className="font-semibold">
                        {value.title}
                      </h3>
                      <p className="text-gray-700">
                        {value.text}
                      </p>
                    </div>
                    </div>
                )}
            
            </div>
        </div>
    );
};

export default CandidateProfileInbox;
