import React, { useState } from "react";
import "./job.css";
import positionsEmptyImg from "@/../public/media/icons/positions-card.svg";
import Chart from "react-apexcharts";

const PageIcon = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M6.75665 13.7342C6.9496 14.365 6.46765 15 5.79615 15H4.2415C3.57 15 3.08805 14.365 3.281 13.7342L3.92445 11.6308C4.0698 11.1575 4.51435 10.8325 5.0184 10.8325C5.52245 10.8325 5.96785 11.1567 6.11235 11.6308L6.7558 13.7342H6.75665ZM17 8.7375V15.8333C17 18.1308 15.0935 20 12.75 20H4.25C1.90655 20 0 18.1308 0 15.8333V4.16667C0 1.86917 1.90655 0 4.25 0H8.08775C9.6764 0 11.1707 0.606667 12.2953 1.70833L15.2566 4.61333C16.3804 5.71417 17 7.17917 17 8.73833V8.7375ZM11.0934 2.88667C10.823 2.62167 10.523 2.3975 10.2 2.21583V5.83333C10.2 6.2925 10.5808 6.66667 11.05 6.66667H14.7399C14.5546 6.35083 14.325 6.05667 14.0556 5.79167L11.0942 2.88667H11.0934ZM15.3 8.7375C15.3 8.60167 15.2932 8.46667 15.2804 8.33333H11.05C9.6441 8.33333 8.5 7.21167 8.5 5.83333V1.68583C8.364 1.67333 8.2263 1.66667 8.08775 1.66667H5.95V2.5C5.95 2.96 5.57005 3.33333 5.1 3.33333C4.62995 3.33333 4.25 2.96 4.25 2.5V1.66667C2.8441 1.66667 1.7 2.78833 1.7 4.16667V15.8333C1.7 17.2117 2.8441 18.3333 4.25 18.3333H12.75C14.1559 18.3333 15.3 17.2117 15.3 15.8333V8.7375ZM5.1 6.66667C5.57005 6.66667 5.95 6.29333 5.95 5.83333V5C5.95 4.54 5.57005 4.16667 5.1 4.16667C4.62995 4.16667 4.25 4.54 4.25 5V5.83333C4.25 6.29333 4.62995 6.66667 5.1 6.66667ZM5.1 10C5.57005 10 5.95 9.62667 5.95 9.16667V8.33333C5.95 7.87333 5.57005 7.5 5.1 7.5C4.62995 7.5 4.25 7.87333 4.25 8.33333V9.16667C4.25 9.62667 4.62995 10 5.1 10Z"
        fill="black"
      />
    </svg>
  );
};

const ChevronRightIcon = () => {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.63332 8C1.42 8 1.20667 7.92159 1.04417 7.76558C0.718349 7.45276 0.718349 6.94712 1.04417 6.6343L3.79827 3.99011L1.14834 1.35552C0.829179 1.03709 0.838345 0.530657 1.16917 0.224234C1.50083 -0.082188 2.02831 -0.0733874 2.34747 0.243436L5.56572 3.44367C5.88154 3.75809 5.87738 4.25653 5.55572 4.56535L2.22248 7.76558C2.05998 7.92159 1.84665 8 1.63332 8Z"
        fill="#2B2F42"
      />
    </svg>
  );
};

const BarChart = () => {
  const options = {
    chart: {
      type: "bar" as const, // Correct type for ApexCharts
      height: 200,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        borderRadius: 4, // Single borderRadius definition
        endingShape: "rounded",
      },
    },
    colors: ["#B0B0B0", "#B0B0B0", "#4CAF50"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Low", "Medium", "High"],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Series 1",
      data: [20, 30, 60],
    },
  ];

  return (
    <div className="bar-chart absolute top-[-30px] left-[-12px]">
      <Chart
        options={options}
        series={series}
        type="bar"
        width={47}
        height={115}
      />
    </div>
  );
};

function CreatejobPositions() {
  const [activeTab, setActiveTab] = useState("active");

  return (
    <div className="cmax-w-[1800px] py-[33px] px-[10px] xxl:pl-[90px] xxl:pr-[61px] min-h-screen h-full bg-[#F4F4F4]">
      <div>
        <h1 className="nunitomedium text-[30px] text-[#2B2F42] mb-0">
          Positions
        </h1>
        <p className="nunitomedium text-[14px] text-[#2B2F4266] mb-0">
          Let's take a look at the positions.
        </p>
      </div>
      <div className="w-full border-b-[2px] border-b-[#B0B0B0] flex mt-[50px] cursor-pointer">
        <div
          className={`nunitobold800 w-[111px] text-center relative text-[14px] transition-all duration-400 ${
            activeTab === "active" ? "text-[#2B2F42]" : "text-[#2B2F4280]"
          }`}
          onClick={() => setActiveTab("active")}
        >
          ACTIVE JOBS
          {activeTab === "active" && (
            <div className="w-full h-[2px] bg-[#FF8E01] absolute transition-all duration-400"></div>
          )}
        </div>

        <div
          className={`nunitobold800 w-[129px] text-center relative text-[14px] transition-all duration-400 ${
            activeTab === "deactive" ? "text-[#2B2F42]" : "text-[#2B2F4280]"
          }`}
          onClick={() => setActiveTab("deactive")}
        >
          DEACTIVE JOBS
          {activeTab === "deactive" && (
            <div className="w-full h-[2px] bg-[#FF8E01] absolute transition-all duration-400"></div>
          )}
        </div>
      </div>
      <div className="flex flex-col xxl:flex-row xxl:items-center mt-[43px] gap-[8%]  mb-[12px]">
        <div className="nunitosbold text-[22px] text-[#2B2F42]">
          7 {activeTab === "active" ? "Active" : "Deactive"} Jobs{" "}
        </div>
        <div className="flex items-center gap-[10px] xxl:gap-[17%]">
          <div className="flex items-center gap-[11px]">
            <span className="nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              Sort by{" "}
            </span>{" "}
            <span className="flex items-center gap-[6px] text-[14px] nunitobold800 whitespace-nowrap">
              Creation Date{" "}
              <svg
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.5 0L8.39711 3H0.602886L4.5 0Z" fill="#2B2F42" />
                <path d="M4.5 8L0.602886 5L8.39711 5L4.5 8Z" fill="#2B2F42" />
              </svg>
            </span>
          </div>
          <div className="flex items-center gap-[11px]">
            <span className="nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              Status{" "}
            </span>{" "}
            <span className="flex items-center gap-[6px] text-[14px] nunitobold800 whitespace-nowrap">
              All Status{" "}
              <svg
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.5 0L8.39711 3H0.602886L4.5 0Z" fill="#2B2F42" />
                <path d="M4.5 8L0.602886 5L8.39711 5L4.5 8Z" fill="#2B2F42" />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-[18px]">
        <div className="w-full sm:w-[270px] h-max-content shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] rounded-[8px] border-t-[4px] border-t-[#B0B0B0]">
          <div className="px-[30px]">
            <div className="nunitobold800 text-[14px] text-[#2B2F4266] mt-[15px]">
              DESIGN
            </div>
            <div className="nunitobold800 text-[18px] text-[#2B2F42] mt-[20px]">
              Senior Product Designer
            </div>
            <div className="nunitobold800 text-[14px] text-[#2B2F4299] mt-[20px]">
              Candidates:
            </div>
            <div className="w-full h-[85px] mt-[30px]">
              <img
                className="w-full h-full object-cover"
                src={positionsEmptyImg}
                alt=""
              />
            </div>
            <div className="flex items-center gap-[10px] mt-[23px]">
              <div className="nunitosbold text-[14px] text-[#2B2F4266]">
                OptiRecruits
              </div>
              <div className="w-[5px] h-[5px] bg-[#2B2F4266] rounded-full"></div>
              <div className="nunitosbold text-[14px] text-[#2B2F4266]">
                On-Site
              </div>
            </div>
          </div>
          <div className="px-[30px] py-[15px] mt-[24px] flex justify-between border-t-[1px] border-t-[#B0B0B080]">
            <div className="flex items-center gap-[10px]">
              <PageIcon />
              <div className="nunitosbold text-[10px] text-[#2B2F4280]">
                Published
              </div>
            </div>
            <div className="flex items-center gap-[8px] ">
              <div className="nunitosbold text-[10px] text-[#2B2F4280]">
                See Details
              </div>
              <ChevronRightIcon />
            </div>
          </div>
        </div>

        <div
          className={`w-full sm:w-[270px] h-max-content shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] rounded-[8px] border-t-[4px] ${
            activeTab === "active" ? "border-t-[#34B53A]" : "border-t-[#FF4343]"
          }`}
        >
          <div className="px-[30px]">
            <div className="nunitobold800 text-[14px] text-[#2B2F4266] mt-[15px]">
              DESIGN
            </div>
            <div className="nunitobold800 text-[18px] text-[#2B2F42] mt-[20px]">
              Senior Product Designer
            </div>
            <div className="nunitobold800 text-[14px] text-[#2B2F4299] mt-[20px]">
              Candidates:
            </div>
            <div
              className={`w-full h-[85px] mt-[30px]  ${
                activeTab === "active" ? "bg-[#34B53A1A]" : "bg-[#FF43431A]"
              } p-[15px] shadow-[0px_1px_4px_0px_rgba(52,181,58,0.25)] rounded-[8px] flex gap-[15px]`}
            >
              <div className="flex items-center gap-[10px]">
                <div className="w-[24px] h-[55px]  relative">
                  <BarChart />
                </div>
                <div>
                  <div className="nunitobold800 text-[14px] text-[#2B2F42]">
                    TOTAL
                  </div>
                  <div className="nunitobold800 text-[22px] text-[#2B2F42]">
                    128
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-[10px]">
                <div className="w-[24px] h-[55px]  relative">
                  <BarChart />
                </div>
                <div>
                  <div className="nunitobold800 text-[14px] text-[#2B2F42]">
                    NEW
                  </div>
                  <div className="nunitobold800 text-[22px] text-[#2B2F42]">
                    8
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-[10px] mt-[23px]">
              <div className="nunitosbold text-[14px] text-[#2B2F4266]">
                OptiRecruits
              </div>
              <div className="w-[5px] h-[5px] bg-[#2B2F4266] rounded-full"></div>
              <div className="nunitosbold text-[14px] text-[#2B2F4266]">
                On-Site
              </div>
            </div>
          </div>
          <div className="px-[30px] py-[15px] mt-[24px] flex justify-between border-t-[1px] border-t-[#B0B0B080]">
            <div className="flex items-center gap-[10px]">
              <PageIcon />
              <div className="nunitosbold text-[10px] text-[#2B2F4280]">
                Published
              </div>
            </div>
            <div className="flex items-center gap-[8px] ">
              <div className="nunitosbold text-[10px] text-[#2B2F4280]">
                See Details
              </div>
              <ChevronRightIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatejobPositions;
