import React, { useState, useEffect } from "react";
import axios from "axios";

interface User {
  id: number;
  username: string;
  name: string;
  role: string;
  emailAddress: string;
  status: "0" | "1" | "2";
  roles : Role[]
}
 

export interface Role {
  roleId: number
  roleName: string
}

const AdminManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"0" | "1" | "2">("0");
  const [users, setUsers] = useState<User[]>([]);  


  
  const API_URL = import.meta.env.VITE_APP_API_URL; 
  const API_GET_USERS = `${API_URL}/services/app/User/GetUsers`;  
  const API_UPDATE_STATUS = `${API_URL}/services/app/User/SetUserStatus`; 
 
  const fetchUsers = async () => {
    
       axios.post(API_GET_USERS, {},{
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        setUsers(response.data.result.items);
       }); 
     
  };
 
  useEffect(() => {
    fetchUsers();
  }, [activeTab]);
 
  const updateUserStatus = async (userId : number, newStatus: number) => {
   
      await axios.post(API_UPDATE_STATUS +"?userId="+ userId+ "&status=" + newStatus,{},{
        headers: {
            'Content-Type': 'application/json'
        }
    }); 
      fetchUsers();
     
  };
 
  const filteredUsers = users.filter((user) => user.status.toString() === activeTab);
 


  return (
    <div className="max-w-[1800px] px-[50px] py-[33px] min-h-screen h-full bg-[#F4F4F4] rounded-[48px] border-2 shadow-lg mt-[50px] ml-10">
     
      <div className="relative flex flex-col justify-center items-center mb-10">
        <div className="absolute top-[-70px] left-[50%] transform translate-x-[-50%] bg-white shadow-lg rounded-full px-10 py-3">
          <img src="/media/svg/general/opti-recruits.svg" alt="Opti Recruits Logo" className="w-[250px] h-[50px]" />
        </div>

        <div className="text-center mt-[20px]">
          <h1 className="text-[36px] font-bold text-[#2B2F42]">Administration</h1>
          <p className="text-gray-500">Administration Management</p>
        </div>
      </div>

      {/* Main Section */}
      <div className="rounded-[32px] shadow-lg p-8 mb-10">
        {/* Tabs */}
        <div className="flex space-x-6 mb-6">
          <button
            className={`${activeTab === "0" ? "bg-red-500 text-white" : "bg-[#FFEEEE] text-red-500"} rounded-lg px-6 py-2`}
            onClick={() => setActiveTab("0")}
          >
            Pending Users
          </button>
          <button
            className={`${activeTab === "1" ? "bg-red-500 text-white" : "bg-[#FFEEEE] text-red-500"} rounded-lg px-6 py-2`}
            onClick={() => setActiveTab("1")}
          >
            Approved Users
          </button>
          <button
            className={`${activeTab === "2" ? "bg-red-500 text-white" : "bg-[#FFEEEE] text-red-500"} rounded-lg px-6 py-2`}
            onClick={() => setActiveTab("2")}
          >
            Denied Users
          </button>
        </div>
 

        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="py-2 px-4 text-left font-semibold border-b">Username</th>
                <th className="py-2 px-4 text-left font-semibold border-b">Name</th>
                <th className="py-2 px-4 text-left font-semibold border-b">Role</th>
                <th className="py-2 px-4 text-left font-semibold border-b">Email</th>
                <th className="py-2 px-4 text-left font-semibold border-b">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, idx) => (
                <tr key={idx} className="border-b">
                  <td className="py-6 px-4 flex items-center">
                    <img
                      src="/media/svg/general/avatar.svg"
                      alt="User Avatar"
                      className="w-[50px] h-[50px] rounded-full mr-4"
                    />
                    {user.username}
                  </td>
                  <td className="py-6 px-4">{user.name}</td>
                  <td className="py-6 px-4">{user.roles[0].roleName}</td>
                  <td className="py-6 px-4">{user.emailAddress}</td>
                  <td className="py-6 px-4">
                    {activeTab === "0" && (
                      <>
                        <button
                          className="bg-green-500 text-white rounded-full px-4 py-2 mr-2"
                          onClick={() => updateUserStatus(user.id, 1)}
                        >
                          Approve
                        </button>
                        <button
                          className="bg-red-500 text-white rounded-full px-8 py-2"
                          onClick={() => updateUserStatus(user.id, 2)}
                        >
                          Deny
                        </button>
                      </>
                    )}
                    {activeTab === "1" && (
                      <button
                        className="bg-red-500 text-white rounded-full px-8 py-2"
                        onClick={() => updateUserStatus(user.id, 2)}
                      >
                        Deny
                      </button>
                    )}
                    {activeTab === "2" && (
                      <button
                        className="bg-green-500 text-white rounded-full px-8 py-2"
                        onClick={() => updateUserStatus(user.id, 1)}
                      >
                        Approve
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Lower Boxes Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
        {/* Box 1 - Account & User */}
        <div className="rounded-[24px] shadow-lg p-8 flex flex-col justify-between">
          <div className="flex justify-between items-start">
            <div>
              <h4 className="text-[20px] font-semibold">Account & User</h4>
              <p className="text-gray-500">Manage your account details, users, candidates.</p>
            </div>
            <img src="/media/svg/general/account-user.svg" alt="Account & User" className="w-[100px] h-[100px]" />
          </div>
          <div className="mt-6 border-t pt-4">
            <button className="text-red-500 font-bold">Manage your account</button>
          </div>
        </div>

        {/* Box 2 - Subscription */}
        <div className="rounded-[24px] shadow-lg p-8 flex flex-col justify-between">
          <div className="flex justify-between items-start">
            <div>
              <h4 className="text-[20px] font-semibold">Subscription</h4>
              <p className="text-gray-500">Manage your subscription, payment methods, and access your invoices.</p>
            </div>
            <img src="/media/svg/general/subscription.svg" alt="Subscription" className="w-[130px] h-[97px]" />
          </div>
          <div className="mt-6 border-t pt-4">
            <button className="text-red-500 font-bold">Manage your subscription</button>
          </div>
        </div>

        {/* Box 3 - Job Boards */}
        <div className="rounded-[24px] shadow-lg p-8 flex flex-col justify-between">
          <div className="flex justify-between items-start">
            <div>
              <h4 className="text-[20px] font-semibold">Job Boards</h4>
              <p className="text-gray-500">Manage your job boards and publish your open positions.</p>
            </div>
            <img src="/media/svg/general/job-boards.svg" alt="Job Boards" className="w-[102px] h-[103px]" />
          </div>
          <div className="mt-6 border-t pt-4">
            <button className="text-red-500 font-bold">Manage your job boards</button>
          </div>
        </div>

        {/* Box 4 - Features */}
        <div className="rounded-[24px] shadow-lg p-8 flex flex-col justify-between">
          <div className="flex justify-between items-start">
            <div>
              <h4 className="text-[20px] font-semibold">Features</h4>
              <p className="text-gray-500">Manage duplicate detection, vendors, employees, and other settings.</p>
            </div>
            <img src="/media/svg/general/features.svg" alt="Features" className="w-[107px] h-[103px]" />
          </div>
          <div className="mt-6 border-t pt-4">
            <button className="text-red-500 font-bold">Manage features</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminManagement;
