import React from "react";
import RecommendedCandidatesCards from "./recommendedCandidatesCards";

function RecommendedCandidates() {
  return (
    <div className="relative flex flex-col w-full">
      <div className="flex flex-col">
        <div className="w-[100%] px-[5px] sm:px-[10px] mid-lg:px-[38px] py-[5px] sm:py-[10px] mid-lg:py-[26px] rounded-[32px] flex flex-col bg-transparent sm:bg-[#F4F4F4] h-[447px]  shadowds1 relative overflow-y-auto">
          <div className="flex flex-wrap gap-[2%]">
            <div className="w-full lg:w-[49%]">
              <RecommendedCandidatesCards />
            </div>

            <div className="w-full lg:w-[49%]">
              <RecommendedCandidatesCards />
            </div>

            <div className="w-full lg:w-[49%]">
              <RecommendedCandidatesCards />
            </div>

            <div className="w-full lg:w-[49%]">
              <RecommendedCandidatesCards />
            </div>

            <div className="w-full lg:w-[49%]">
              <RecommendedCandidatesCards />
            </div>

            <div className="w-full lg:w-[49%]">
              <RecommendedCandidatesCards />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommendedCandidates;
