/* eslint-disable @typescript-eslint/no-explicit-any */

import { CurrentUser } from "@/api/models/Login/CurrentUser";

const AUTH_LOCAL_STORAGE_KEY = "assa-marketplace-token";

const getAuth = (): string | undefined => {
  if (!localStorage) {
    return;
  }
  try {
    let token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (token) {
      return token;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setLocalStorageAuth = (auth: string) => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, auth);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getCurrentUser = (): CurrentUser | undefined => {
  if (!localStorage) {
    return;
  }
  try {
    const user = localStorage.getItem('currentUser');
    if (user) {
      const parsedUser: CurrentUser = JSON.parse(user);
      return parsedUser;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
  return undefined;
};

const setLocalStorageCurrentUser = (user: CurrentUser | undefined) => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.setItem('currentUser', JSON.stringify(user));
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export { getAuth, getCurrentUser, setLocalStorageCurrentUser, setLocalStorageAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
