import React from "react";
import { Link } from "react-router-dom";

const OfferAndOnboarding: React.FC = () => {
  return (
    <Link to="/onboarding">
      <div className="flex-1 py-[19px] px-[40px] rounded-[32px] shadow-custom flex flex-col gap-[18px] h-full ">
        {/* Header */}

        <h2 className="nunitomedium text-[30px] text-[#2B2F42] mb-0">
          Offer and Onboarding Information
        </h2>

        {/* Spotify Info */}
        <div className="flex items-center gap-[10px]">
          <img
            src="/media/dashboard/spotify.svg"
            alt="Spotify Logo"
            className="w-[44px] h-[44px]"
          />
          <p className="nunitosbold text-[10px] text-[#2B2F42] mb-0">Spotify</p>
          <div className="flex items-center gap-[6px]">
            <div className="flex justify-center items-center bg-[#c9c9c9] gap-[6px] px-[6px] py-[3px] rounded-full">
              <img
                src="/media/svg/general/Ellipse 67.svg"
                alt="Zeynep"
                className="w-[14px] h-[14px] rounded-full"
              />
              <span className="nunitomedium text-[10px] text-[#F9F9F9]">
                Zeynep
              </span>
            </div>
            <div className="nunitomedium bg-[#36C5F1] text-[#F9F9F9] text-[10px] font-medium px-[6px] py-[3px] rounded-full flex items-center justify-center">
              Admin Ad.
            </div>
          </div>
        </div>

        {/* List Items */}
        <div className="flex flex-col gap-[10px] w-full">
          {/* Item 1 */}
          <div className="grid grid-cols-1 sm:grid-cols-5 items-center gap-4">
            {/* Column 1: Image and Name */}
            <div className="flex items-center gap-[10px]">
              {/* Image */}
              <img
                src="/media/svg/general/olenna.svg"
                alt="Olenna"
                className="w-[26px] h-[26px] rounded-full ml-[0px] sm:ml-[9px]"
              />
              {/* Name */}
              <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] mb-0">
                Olenna
              </p>
            </div>

            {/* Column 2: Role */}
            <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] whitespace-nowrap mb-0 text-left sm:text-center">
              UI Designer
            </p>

            {/* Column 3: Secondary Name */}
            <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] mb-0 text-left sm:text-center">
              Olenna
            </p>

            {/* Column 4: Status */}
            <div className="nunitosbold bg-[#FF8E0126] text-[#FF8E01] text-[10px] px-3 py-1 rounded-full text-center w-full">
              Pending
            </div>

            {/* Column 5: Options */}
            <div className="flex justify-start sm:justify-end">
              <img
                src="/media/svg/general/more_horiz.svg"
                alt="Options"
                className="w-8 h-8"
              />
            </div>
          </div>

          {/* Item 2 */}
          <div className="grid grid-cols-1 sm:grid-cols-5 items-center gap-4">
            {/* Column 1: Image and Name */}
            <div className="flex items-center gap-[10px]">
              <img
                src="/media/svg/general/aoife.svg"
                alt="Aoife"
                className="w-[26px] h-[26px] rounded-full ml-[0px]  sm:ml-[9px]"
              />
              <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] mb-0">
                Aoife
              </p>
            </div>

            {/* Column 2: Role */}
            <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] whitespace-nowrap mb-0 text-left sm:text-center">
              Visual Designer
            </p>

            {/* Column 3: Secondary Name */}
            <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] mb-0 text-left sm:text-center">
              Aoife
            </p>

            {/* Column 4: Status */}
            <div className="nunitosbold bg-[#34B53A26] text-[#34B53A] text-[10px] px-3 py-1 rounded-full text-center w-full">
              Approved
            </div>

            {/* Column 5: Options */}
            <div className="flex justify-start sm:justify-end">
              <img
                src="/media/svg/general/more_horiz.svg"
                alt="Options"
                className="w-8 h-8"
              />
            </div>
          </div>

          {/* Item 3 */}
          <div className="grid grid-cols-1 sm:grid-cols-5 items-center gap-4">
            {/* Column 1: Image and Name */}
            <div className="flex items-center gap-[10px]">
              <img
                src="/media/svg/general/maebh.svg"
                alt="Maebh"
                className="w-[26px] h-[26px] rounded-full sm:ml-[0px] ml-[0px] sm:ml-[9px]"
              />
              <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] mb-0">
                Maebh
              </p>
            </div>

            {/* Column 2: Role */}
            <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] whitespace-nowrap mb-0 text-left sm:text-center">
              Motion Designer
            </p>

            {/* Column 3: Secondary Name */}
            <p className="nunitosbold text-[10px] leading-[13.64px] text-[#2B2F42] mb-0 text-left sm:text-center">
              Maebh
            </p>

            {/* Column 4: Status */}
            <div className="nunitosbold bg-[#FE000026] text-[#FE0000] text-[10px] px-3 py-1 rounded-full text-center w-full">
              Declined
            </div>

            {/* Column 5: Options */}
            <div className="flex justify-start sm:justify-end">
              <img
                src="/media/svg/general/more_horiz.svg"
                alt="Options"
                className="w-8 h-8"
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default OfferAndOnboarding;
