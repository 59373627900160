export enum jobtitle{
    UX_UI_Designer = "UX/UI Designer"
}

export enum department {
    design = "Design"
}

export enum company {
    slack = "Slack"
}

export enum workplace {
    onsite = "On-site"
}

export enum jobType {
    fullTime = "Full time",
    partTime = "Part time",
    internship = "Internship",
    temporary = "Temporary",
    contract = "Contract"
}

export enum quota {
    first = "0-10",
    second = "10-50",
    third = "+50"
}
