import React from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as yup from "yup";
import plus from "@/_metronic/assets/registerlogin/plus.svg";

interface EducationProps {
  onNext: (data: EducationInfo[]) => void;
  onBack: () => void;
}

interface EducationInfo {
  degree: string;
  university: string;
  department: string;
  startDate: string;
  graduationDate: string;
  gpa: string;
}

const validationSchema = yup.object().shape({
  educations: yup.array().of(
    yup.object().shape({
      degree: yup.string().required("Degree is required"),
      university: yup.string().required("University name is required"),
      department: yup.string().required("Department is required"),
      startDate: yup.string().required("Start date is required"),
      graduationDate: yup.string().required("Graduation date is required"),
      gpa: yup
        .number()
        .typeError("GPA must be a number")
        .min(0, "GPA cannot be lower than 0")
        .max(4, "GPA cannot be higher than 4")
        .nullable(),
    })
  ),
});

const Education: React.FC<EducationProps> = ({ onNext, onBack }) => {
  const formik = useFormik({
    initialValues: {
      educations: [
        {
          degree: "Master's Degree",
          university: "CCT College",
          department: "Computing & IT",
          startDate: "1990-01-01",
          graduationDate: "1990-01-01",
          gpa: "4.0",
        },
      ],
    },
    validationSchema,
    onSubmit: (values) => {
      onNext(values.educations);
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full max-w-[750px] mx-auto rounded-lg"
      >
        <FieldArray
          name="educations"
          render={({ push }) => (
            <>
              {formik.values.educations.map((education, index) => (
                <div key={index} className="mb-6">
                  <div className="mb-4">
                    <label
                      htmlFor={`educations.${index}.degree`}
                      className="block text-[22px] nunitosbold text-[#2B2F42]"
                    >
                      Degree *
                    </label>
                    <select
                      id={`educations.${index}.degree`}
                      name={`educations.${index}.degree`}
                      value={education.degree}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                        typeof formik.errors.educations?.[index] === "object" &&
                        formik.errors.educations?.[index] !== null &&
                        formik.touched.educations?.[index]?.degree &&
                        formik.errors.educations?.[index]?.degree
                          ? "border-[#FE0000]"
                          : "border-[#ADADAD]"
                      } select-input`}
                    >
                      <option value="">Select degree</option>
                      <option value="Bachelor's Degree">
                        Bachelor's Degree
                      </option>
                      <option value="Master's Degree">Master's Degree</option>
                      <option value="PhD">PhD</option>
                    </select>
                    {typeof formik.errors.educations?.[index] === "object" &&
                      formik.errors.educations?.[index] !== null &&
                      formik.errors.educations?.[index]?.degree &&
                      formik.touched.educations?.[index]?.degree && (
                        <div className="text-[#FE0000] text-[14px] mt-[7px]">
                          {formik.errors.educations[index].degree}
                        </div>
                      )}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor={`educations.${index}.university`}
                      className="block text-[22px] nunitosbold text-[#2B2F42]"
                    >
                      University Name *
                    </label>
                    <input
                      type="text"
                      id={`educations.${index}.university`}
                      name={`educations.${index}.university`}
                      placeholder="CCT College"
                      value={education.university}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                        formik.touched.educations?.[index]?.university &&
                        typeof formik.errors.educations?.[index] === "object" &&
                        formik.errors.educations?.[index] !== null &&
                        formik.errors.educations[index].university
                          ? "border-[#FE0000]"
                          : "border-[#ADADAD]"
                      }`}
                    />
                    {formik.touched.educations?.[index]?.university &&
                      typeof formik.errors.educations?.[index] === "object" &&
                      formik.errors.educations?.[index] !== null &&
                      formik.errors.educations?.[index]?.university && (
                        <div className="text-[#FE0000] text-[14px] mt-[7px]">
                          {formik.errors.educations[index].university}
                        </div>
                      )}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor={`educations.${index}.department`}
                      className="block text-[22px] nunitosbold text-[#2B2F42]"
                    >
                      Department *
                    </label>
                    <input
                      type="text"
                      id={`educations.${index}.department`}
                      name={`educations.${index}.department`}
                      placeholder="Computing & IT"
                      value={education.department}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                        formik.touched.educations?.[index]?.department &&
                        typeof formik.errors.educations?.[index] === "object" &&
                        formik.errors.educations?.[index] !== null &&
                        formik.errors.educations?.[index]?.department
                          ? "border-[#FE0000]"
                          : "border-[#ADADAD]"
                      }`}
                    />
                    {formik.touched.educations?.[index]?.department &&
                      typeof formik.errors.educations?.[index] === "object" &&
                      formik.errors.educations?.[index] !== null &&
                      formik.errors.educations?.[index]?.department && (
                        <div className="text-[#FE0000] text-[14px] mt-[7px]">
                          {formik.errors.educations[index].department}
                        </div>
                      )}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor={`educations.${index}.startDate`}
                      className="block text-[22px] nunitosbold text-[#2B2F42]"
                    >
                      Start Date *
                    </label>
                    <input
                      type="date"
                      id={`educations.${index}.startDate`}
                      name={`educations.${index}.startDate`}
                      value={education.startDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px]  ${
                        formik.touched.educations?.[index]?.startDate &&
                        typeof formik.errors.educations?.[index] === "object" &&
                        formik.errors.educations?.[index] !== null &&
                        formik.errors.educations?.[index]?.startDate
                          ? "border-[#FE0000]"
                          : "border-[#ADADAD]"
                      } date-input`}
                    />
                    {formik.touched.educations?.[index]?.startDate &&
                      typeof formik.errors.educations?.[index] === "object" &&
                      formik.errors.educations?.[index] !== null &&
                      formik.errors.educations?.[index]?.startDate && (
                        <div className="text-[#FE0000] text-[14px] mt-[7px]">
                          {formik.errors.educations[index].startDate}
                        </div>
                      )}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor={`educations.${index}.graduationDate`}
                      className="block text-[22px] nunitosbold text-[#2B2F42]"
                    >
                      Graduation Date *
                    </label>
                    <input
                      type="date"
                      id={`educations.${index}.graduationDate`}
                      name={`educations.${index}.graduationDate`}
                      value={education.graduationDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px]  ${
                        formik.touched.educations?.[index]?.graduationDate &&
                        typeof formik.errors.educations?.[index] === "object" &&
                        formik.errors.educations?.[index] !== null &&
                        formik.errors.educations?.[index]?.graduationDate
                          ? "border-[#FE0000]"
                          : "border-[#ADADAD]"
                      } date-input`}
                    />
                    {formik.touched.educations?.[index]?.graduationDate &&
                      typeof formik.errors.educations?.[index] === "object" &&
                      formik.errors.educations?.[index] !== null &&
                      formik.errors.educations?.[index]?.graduationDate && (
                        <div className="text-[#FE0000] text-[14px] mt-[7px]">
                          {formik.errors.educations[index].graduationDate}
                        </div>
                      )}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor={`educations.${index}.gpa`}
                      className="block text-[22px] nunitosbold text-[#2B2F42]"
                    >
                      Current GPA (Grade Point Average)
                    </label>
                    <input
                      type="text"
                      id={`educations.${index}.gpa`}
                      name={`educations.${index}.gpa`}
                      placeholder="4.0"
                      value={education.gpa}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                        formik.touched.educations?.[index]?.gpa &&
                        typeof formik.errors.educations?.[index] === "object" &&
                        formik.errors.educations?.[index] !== null &&
                        formik.errors.educations?.[index]?.gpa
                          ? "border-[#FE0000]"
                          : "border-[#ADADAD]"
                      }`}
                    />
                    {formik.touched.educations?.[index]?.gpa &&
                      typeof formik.errors.educations?.[index] === "object" &&
                      formik.errors.educations?.[index] !== null &&
                      formik.errors.educations?.[index]?.gpa && (
                        <div className="text-[#FE0000] text-[14px] mt-[7px]">
                          {formik.errors.educations[index].gpa}
                        </div>
                      )}
                  </div>
                </div>
              ))}
              <div className="flex justify-end mb-6">
                <button
                  type="button"
                  onClick={() =>
                    push({
                      degree: "",
                      university: "",
                      department: "",
                      startDate: "",
                      graduationDate: "",
                      gpa: "",
                    })
                  }
                  className="nunitoregular flex gap-[13px] items-center text-[#2B2F42] text-[14px]"
                >
                  Add another
                  <img src={plus} alt="plus" />
                </button>
              </div>
            </>
          )}
        />

        <div className="flex gap-[20px] sm:gap-[35px] mt-[30px]">
          <button
            type="button"
            onClick={onBack}
            className="w-100 h-[60px] sm:h-[79.55px] border-[2px] border-[#D9D9D9]  rounded-[40px] nunitomedium text-[#B0B0B0] text-[18px] sm:text-[22px]"
          >
            Back
          </button>
          <button
            type="submit"
            className="w-100 h-[60px] sm:h-[79.55px] border-[2px] border-[#FE0000] bg-[#FE0000] hover:bg-red-500 rounded-[40px] nunitomedium text-[#F9F9F9] text-[18px] sm:text-[22px]"
          >
            Next
          </button>
        </div>
        <p className="nunitosbold text-center mt-[30px] text-[18px] sm:text-[22px]">
          Back to{" "}
          <a href="/" className="text-[#02A0FC]">
            Home
          </a>
        </p>
      </form>
    </FormikProvider>
  );
};

export default Education;
