import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import see from "@/_metronic/assets/registerlogin/see.svg";
import hide from "@/_metronic/assets/registerlogin/hide.svg";
import zxcvbn from "zxcvbn";
import ReactInputMask from "react-input-mask";

interface CompanyRegistrationProps {
  onNext: (data: any) => void;
}

const validationSchema = Yup.object({
  fullname: Yup.string().required("Fullname is required"),
  organizationName: Yup.string().required("Organization Name is required"),
  companyEmail: Yup.string()
    .email("Invalid email address")
    .required("Company Email is required"),
  confirmCompanyEmail: Yup.string()
    .oneOf([Yup.ref("companyEmail"), undefined], "Emails must match")
    .required("Confirm Company Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
  phone: Yup.string()
    .matches(/^[0-9+\- ]+$/, "Invalid phone number format")
    .required("Phone number is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  postCode: Yup.string().required("Post Code is required"),
  address: Yup.string().required("Address is required"),
  termsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

interface PasswordStrengthBarProps {
  password: string;
}

const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({
  password,
}) => {
  const [passwordStrength, setPasswordStrength] = useState(0);

  useEffect(() => {
    if (password) {
      const result = zxcvbn(password);
      setPasswordStrength(result.score);
    } else {
      setPasswordStrength(0);
    }
  }, [password]);

  const getBarStyle = (index: number) => {
    const styles = [
      { width: "18.22%", backgroundColor: "#FE0000" },
      { width: "11.68%", backgroundColor: "#FF8E01" },
      { width: "11.68%", backgroundColor: "#36C5F1" },
      { width: "18.22%", backgroundColor: "#34B53A" },
    ];

    const activeStyle = styles[index];
    return {
      width: activeStyle.width,
      backgroundColor:
        passwordStrength >= index + 1 ? activeStyle.backgroundColor : "#D9D9D9",
      transition: "background-color 0.4s ease",
    };
  };

  return (
    <div className="w-full h-[6px] mt-[7px] flex gap-[10px]">
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          className="h-full rounded-[20px]"
          style={getBarStyle(index)}
        ></div>
      ))}
    </div>
  );
};

const CompanyRegistration: React.FC<CompanyRegistrationProps> = ({
  onNext,
}) => {
  const formik = useFormik({
    initialValues: {
      fullname: "John Doe",
      organizationName: "Tech Solutions",
      companyEmail: "john.doe@techsolutions.com",
      confirmCompanyEmail: "john.doe@techsolutions.com",
      password: "password123",
      confirmPassword: "password123",
      phone: "+90 555 555 5555",
      country: "Turkey",
      city: "Istanbul",
      postCode: "34000",
      address: "123 Main Street, Istanbul, Turkey",
      termsAccepted: true,
    },
    validationSchema,
    onSubmit: (values) => {
      onNext(values);
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col w-full h-full mt-[36px]">
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4 flex flex-col gap-[10px]">
          <div className="w-full">
            <label
              htmlFor="fullname"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Fullname *
            </label>
            <input
              type="text"
              id="fullname"
              {...formik.getFieldProps("fullname")}
              placeholder="Emre Yıldız"
              {...formik.getFieldProps("company")}
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.fullname && formik.errors.fullname
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            {formik.touched.fullname && formik.errors.fullname ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.fullname}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="organizationName"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Organization Name *
            </label>
            <input
              type="text"
              id="organizationName"
              {...formik.getFieldProps("organizationName")}
              placeholder="Opti Recruits"
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.organizationName &&
                formik.errors.organizationName
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            {formik.touched.organizationName &&
            formik.errors.organizationName ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.organizationName}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="companyEmail"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Company Email Address *
            </label>
            <input
              type="email"
              id="companyEmail"
              {...formik.getFieldProps("companyEmail")}
              placeholder="meyildiz@example.com"
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.companyEmail && formik.errors.companyEmail
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            {formik.touched.companyEmail && formik.errors.companyEmail ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.companyEmail}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="confirmCompanyEmail"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Confirm Company Email Address *
            </label>
            <input
              type="email"
              id="confirmCompanyEmail"
              {...formik.getFieldProps("confirmCompanyEmail")}
              placeholder="meyildiz@example.com"
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.confirmCompanyEmail &&
                formik.errors.confirmCompanyEmail
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            {formik.touched.confirmCompanyEmail &&
            formik.errors.confirmCompanyEmail ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.confirmCompanyEmail}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="password"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Password *
            </label>
            <div className="relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="+8 characters"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                  formik.touched.password && formik.errors.password
                    ? "border-[#FE0000]"
                    : "border-[#ADADAD]"
                }`}
              />
              <img
                className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                  showPassword ? "hidden" : "block"
                }`}
                src={see}
                alt="Show password"
                onClick={togglePasswordVisibility}
              />
              <img
                className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                  showPassword ? "block" : "hidden"
                }`}
                src={hide}
                alt="Hide password"
                onClick={togglePasswordVisibility}
              />
            </div>
            <PasswordStrengthBar password={formik.values.password} />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.password}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="confirmPassword"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Confirm Password *
            </label>
            <div className="relative">
              <input
                id="confirmPassword"
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                placeholder="+8 characters"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? "border-[#FE0000]"
                    : "border-[#ADADAD]"
                }`}
              />
              <img
                className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                  showPassword ? "hidden" : "block"
                }`}
                src={see}
                alt="Show password"
                onClick={togglePasswordVisibility}
              />
              <img
                className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                  showPassword ? "block" : "hidden"
                }`}
                src={hide}
                alt="Hide password"
                onClick={togglePasswordVisibility}
              />
            </div>
            <PasswordStrengthBar password={formik.values.confirmPassword} />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="phone"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Phone *
            </label>
            <ReactInputMask
              mask="+90 999 999 9999"
              id="phone"
              placeholder="+90 501 016 3673"
              {...formik.getFieldProps("phone")}
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.phone && formik.errors.phone
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.phone}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="country"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Country *
            </label>
            <select
              id="country"
              {...formik.getFieldProps("country")}
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.country && formik.errors.country
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              } select-input`}
            >
              <option value="Turkey">Turkey</option>
              <option value="USA">USA</option>
              <option value="Germany">Germany</option>
            </select>

            {formik.touched.country && formik.errors.country ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.country}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="city"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              City *
            </label>
            <select
              id="city"
              {...formik.getFieldProps("city")}
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.city && formik.errors.city
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              } select-input`}
            >
              <option value="Istanbul">Istanbul</option>
              <option value="Ankara">Ankara</option>
              <option value="Izmir">Izmir</option>
            </select>
            {formik.touched.city && formik.errors.city ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.city}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="address"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Address *
            </label>
            <textarea
              id="address"
              {...formik.getFieldProps("address")}
              placeholder="Esenyurt 34513 Plaza 23/6, Sokak No:4A Daire 32"
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[77px] border-[1px] px-[16px] py-[8px] rounded-[3px] resize-none ${
                formik.touched.address && formik.errors.address
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.address}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <label
              htmlFor="postCode"
              className="block text-[22px] nunitosbold text-[#2B2F42]"
            >
              Post Code *
            </label>
            <input
              type="text"
              id="postCode"
              {...formik.getFieldProps("postCode")}
              placeholder="34500"
              className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                formik.touched.postCode && formik.errors.postCode
                  ? "border-[#FE0000]"
                  : "border-[#ADADAD]"
              }`}
            />
            {formik.touched.postCode && formik.errors.postCode ? (
              <div className="text-[#FE0000] text-[14px] mt-[7px]">
                {formik.errors.postCode}
              </div>
            ) : null}
          </div>
        </div>

        <div className="my-[30px]">
          <div className="flex items-center gap-[13px]">
            <input
              type="checkbox"
              id="termsAccepted"
              name="termsAccepted"
              checked={formik.values.termsAccepted}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="mr-2 leading-tight shrink-0 checkbox "
            />
            <label
              htmlFor="termsAccepted"
              className="nunitoregular text-[14px] text-[#2B2F42]"
            >
              I agree to OptiRecruit's{" "}
              <a href="#" className="nunitoregular text-[#02A0FC]">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" className="nunitoregular text-[#02A0FC]">
                Privacy Policy
              </a>
              .
            </label>
          </div>
          {formik.touched.termsAccepted && formik.errors.termsAccepted && (
            <div className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.termsAccepted}
            </div>
          )}
        </div>

        <button
          type="submit"
          className="nunitomedium w-full h-[60px] sm:h-[79.55px] bg-[#FE0000] text-white p-3 rounded-[40px] text-[18px] sm:text-[22px] hover:bg-red-600"
        >
          Next
        </button>

        <p className="nunitosbold text-[22px] mt-[30px] text-center">
          Already have an account?{" "}
          <a href="/auth" className="text-[#02A0FC]">
            Sign in.
          </a>
        </p>
      </form>
    </div>
  );
};

export default CompanyRegistration;
