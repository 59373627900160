import React, { useState } from "react";

interface TabbarProps {
  type: (accountType: string) => void;
}

const Tabbar: React.FC<TabbarProps> = ({ type }) => {
  const [accountType, setAccountType] = useState("candidate");

  const handleTypeChange = (newType: string) => {
    setAccountType(newType);
    type(newType);
  };

  return (
    <div>
      <div className="w-100 xl:w-[100%] 2xl:w-[643px]  px-[10px] 2xl:px-0 max-w-[643px] rounded-lg">
        <div className="flex justify-start mb-10">
          <img
            src="/media/svg/general/Group 2449.svg"
            alt="Opti Recruits"
            className="w-[230px] h-[60px]"
          />
        </div>

        <h1 className="nunitobold mb-1 text-[26px] lg:text-[30px] text-start text-[#2B2F42]">
          Ready to join us? Sign up now!
        </h1>
        <p className="nunitoregular italic text-[13px] lg:text-[14px] text-gray-500 mb-[24px] lg:mb-[70px] text-start text-[#2B2F42]">
          Unlimited free trial. No credit card required.
        </p>

        <div className="flex justify-between items-center">
          <div
            className={`relative ${
              accountType === "advisor" ? "w-[35.77%]" : "w-[28.77%]"
            }`}
          >
            <button
              className={`w-full border-[1px] rounded-[10px] px-[7px] sm:px-5 sm:py-4 text-lg font-bold ${
                accountType === "advisor"
                  ? "h-[100px]  border-[#FF4343] bg-[#FFEAEA]"
                  : "h-[80px]  border-[#ADADAD]  bg-white"
              }`}
              onClick={() => handleTypeChange("advisor")}
            >
              <div className="flex flex-col sm:flex-row text-[25px] items-center">
                <div
                  className={`${
                    accountType === "advisor"
                      ? "text-[#FF4343]"
                      : "text-[#FF4343]"
                  }`}
                >
                  {accountType === "advisor" ? "●" : "○"}
                </div>
                <div className=" nunitobold text-[13px] sm:text-[22px] text-[#2B2F42] flex-1">
                  Advisor <br />
                  Account
                </div>
              </div>
            </button>
            <p className="w-[100%] mx-auto nunitoregular text-sm text-[10px] text-[#2B2F42] text-center mt-[6px]">
              Check this box to register as a advisor and unlock benefits!
            </p>
          </div>
          <div
            className={`relative ${
              accountType === "candidate" ? "w-[35.77%]" : "w-[28.77%]"
            }`}
          >
            <button
              className={`w-full border-[1px] rounded-[10px] px-[7px] sm:px-5 sm:py-4 text-lg font-bold ${
                accountType === "candidate"
                  ? "h-[100px]  border-[#FF4343] bg-[#FFEAEA]"
                  : " h-[80px] border-[#ADADAD]  bg-white"
              }`}
              onClick={() => handleTypeChange("candidate")}
            >
              <div className="flex flex-col sm:flex-row text-[25px] items-center">
                <div
                  className={`${
                    accountType === "advisor"
                      ? "text-[#FF4343]"
                      : "text-[#FF4343]"
                  }`}
                >
                  {accountType === "candidate" ? "●" : "○"}
                </div>
                <div className="nunitobold text-[13px] sm:text-[22px] text-[#2B2F42] flex-1">
                  Candidate <br /> Account
                </div>
              </div>
            </button>
            <p className="w-[100%] mx-auto nunitoregular text-sm text-[10px] text-[#2B2F42] text-center mt-[6px]">
              Check this box to register as a candidate and unlock benefits!
            </p>
          </div>
          <div
            className={`relative ${
              accountType === "company" ? "w-[35.77%]" : "w-[28.77%]"
            }`}
          >
            <button
              className={`w-full border-[1px] border-[#ADADAD] rounded-[10px] px-[7px] sm:px-5 sm:py-4 text-lg font-bold ${
                accountType === "company"
                  ? " h-[100px] border-[#FF4343] bg-[#FFEAEA]"
                  : " h-[80px] border-[#ADADAD]  bg-white"
              }`}
              onClick={() => handleTypeChange("company")}
            >
              <div className="flex flex-col sm:flex-row text-[25px] items-center">
                <div
                  className={`${
                    accountType === "advisor"
                      ? "text-[#FF4343]"
                      : "text-[#FF4343]"
                  }`}
                >
                  {accountType === "company" ? "●" : "○"}
                </div>
                <div className="nunitobold text-[13px] sm:text-[22px] text-[#2B2F42] flex-1">
                  Company <br /> Account
                </div>
              </div>
            </button>
            <p className="w-[100%] mx-auto nunitoregular text-sm text-[10px] text-[#2B2F42] text-center mt-[6px]">
              Check this box to register as a company and unlock benefits!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabbar;
