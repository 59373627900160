import "./dashboard.css";

import React, { useEffect, useState } from "react";

import DashboardComponent1 from "./components/component-1";
import DashboardComponent2 from "./components/component-2";
import ProfileViewers from "./components/profile-viewers";
import RecommendedJob from "./components/component-3";
import { RecommendedJobModel } from "./models/recommended-job-model";
import ResumeEvaluation from "./components/resume-evaluation";
import UpcomingScheduleComponentCandidate from "./components/UpcomingScheduleComponentCandidate";
import axios from "axios";
import { useAuth } from "@/app/modules/auth/core/Auth";

// State için varsayılan değerler
const defaultRecommendedJob: RecommendedJobModel = {
  jobTitle: "",
  location: "",
  employeeCount: "",
  daysAgo: "",
  image: "",
  percentage: "",
  company: "",
  type: "",
  companyLogo: "",
};

function CandidateDashboard() {
  const [employers, setEmployers] = useState<string[]>([]);
  const [percentage, setPercentage] = useState<number>(0);
  const [viewed, setViewed] = useState<number>(0);
  const [number, setNumber] = useState<number>(0);
  const [recommendedJob, setRecommendedJob] = useState<RecommendedJobModel>(
    defaultRecommendedJob
  );
  const { currentUser } = useAuth();
  const [selected, setSelected] = useState("30 days");

  const isSelected = (value: string) =>
    selected === value
      ? "text-[#f9f9f9] bg-[#FE0000E6] shadow-custom-red"
      : "text-[#2B2F42] shadow-custom-black bg-opacity-0";

  const API_GET_EMPLOYERS = "https://api.example.com/employers";
  const API_GET_PROFILE_VIEW = "https://api.example.com/profile-view";
  const API_GET_COMPLETED_RECRUITMENTS =
    "https://api.example.com/completed-recruitments";
  const API_GET_RECOMMENDED_JOB = "https://api.example.com/recommended-job";

  useEffect(() => {
    const fetchEmployers = async () => {
      try {
        const response = await axios.get<string[]>(API_GET_EMPLOYERS);
        setEmployers(response.data);
      } catch (error) {
        console.error("Error fetching employers:", error);
      }
    };

    const fetchProfileView = async () => {
      try {
        const response = await axios.get<{
          percentage: number;
          viewed: number;
        }>(API_GET_PROFILE_VIEW);
        setPercentage(response.data.percentage);
        setViewed(response.data.viewed);
      } catch (error) {
        console.error("Error fetching profile viewers:", error);
      }
    };

    const fetchCompletedRecruitments = async () => {
      try {
        const response = await axios.get<{ number: number }>(
          API_GET_COMPLETED_RECRUITMENTS
        );
        setNumber(response.data.number);
      } catch (error) {
        console.error("Error fetching completed recruitments:", error);
      }
    };

    const fetchRecommendedJob = async () => {
      try {
        const response = await axios.get<RecommendedJobModel>(
          API_GET_RECOMMENDED_JOB
        );
        setRecommendedJob(response.data);
      } catch (error) {
        console.error("Error fetching recommended job:", error);
      }
    };

    fetchEmployers();
    fetchProfileView();
    fetchCompletedRecruitments();
    fetchRecommendedJob();
  }, []);

  return (
    <>
      <div className="max-w-[1800px] pt-0 sm:pt-[33px] py-[33px] min-h-screen h-full bg-[#F4F4F4]">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-[20px]">
          <div className="flex flex-col gap-[5px]">
            <h4 className="nunitomedium text-[30px] text-[#2B2F42] mb-0">
              Good morning, {currentUser ? currentUser.name : "Guest"}
            </h4>
            <h4 className="nunitomedium text-[14px] text-[#2B2F4299] mb-0">
              Take a look at your work done during the month.
            </h4>
          </div>
        </div>

        <div className="flex flex-col xl-custom:flex-row justify-between ">
          <div className="flex-1 min-w-[56.15%] pr-0 lg:pr-[20px]">
            <div className="flex flex-col lg:flex-row gap-[20px]">
              <DashboardComponent1
                title="Search Jobs"
                percentagevalue="+21 Jobs"
                description="%80 and more matched with your profile"
                route="/jobs"
              />
              <DashboardComponent2
                title="My Applications"
                count="17"
                description="During this month, you applied for positions at multiple companies."
                icon="page-user"
                route="/candidate-management"
              />
              <DashboardComponent2
                title="Viewed Jobs"
                count="6"
                description="During this month, you’re application viewed."
                icon="eyes"
                route="/candidate-management"
              />
            </div>
            <div className="col-span-1 md:col-span-3 border lg:p-4 mt-10">
              <h5
                className={
                  "nunitomedium sm:text-start text-[30px] text-[#2B2F42]"
                }
              >
                AI-powered CV Assessment
              </h5>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-[31px]  !mt-0">
              <ResumeEvaluation
                employers={["Company A", "Company B", "Company C"]}
                percentage={75}
              />

              <ProfileViewers percentage={percentage} viewed={viewed} />
            </div>

            <div className="col-span-1 md:col-span-3 border p-4 pb-[21px]">
              <h5
                className={
                  "nunitomedium sm:text-start text-[30px] text-[#2B2F42]"
                }
              >
                Recommended Jobs
              </h5>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-[31px] col-span-1 md:col-span-3">
              <RecommendedJob data={recommendedJob} />
              <RecommendedJob data={recommendedJob} />
            </div>
          </div>

          <div className="w-full xl-custom:w-[400px] shrink-0 min-w-[18.65%] mt-[16px] lg:mt-[0px]">
            <div className="flex flex-row gap-[18px] mt-[10px] lg:mt-0">
              <div
                onClick={() => setSelected("30 days")}
                className={`flex-1 px-[28px] select-none cursor-pointer text-[14px] py-[10px] rounded-[32px] flex justify-center items-center h-[52px] transition duration-400 text-center ${isSelected(
                  "30 days"
                )}`}
              >
                30 days
              </div>

              <div
                onClick={() => setSelected("90 days")}
                className={`flex-1 px-[28px] select-none cursor-pointer text-[14px] py-[10px] rounded-[32px] flex justify-center items-center h-[52px] transition duration-400 text-center ${isSelected(
                  "90 days"
                )}`}
              >
                90 days
              </div>

              <div
                onClick={() => setSelected("6 months")}
                className={`flex-1 px-[28px] select-none cursor-pointer text-[14px] py-[10px] rounded-[32px] flex justify-center items-center h-[52px] transition duration-400 text-center ${isSelected(
                  "6 months"
                )}`}
              >
                6 months
              </div>
            </div>
            <UpcomingScheduleComponentCandidate />
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateDashboard;
