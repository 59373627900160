// CandidateCard.tsx

import React from 'react';

interface CandidateCardProps {
    candidate: {
        name: string;
        surname: string;
        job: string;
        status: string;
        date: string;
        rating: string;
        percentage: string;
    };
    index: number;
}

const CandidateCard: React.FC<CandidateCardProps> = ({ candidate, index }) => {
    return (
        <div className={'flex flex-row'}>
            <div className={'pr-[25px]'}>
                <div className={'flex mt-[24px] w-[315px] flex-col'}>
                    <div className={'w-[284px] relative pb-[11px] h-[143px] bgc1 rounded-[20px]'}>
                        <div className={'flex flex-row w-full items-start justify-between'}>
                            <div className={'w-full pt-[10px] pl-[6px] flex-row flex gap-[21px]'}>
                                <div className={'relative w-fit h-fit'}>
                                    <img src="/media/svg/general/Ellipse 61.svg" className="w-[48px] h-[51px]" />
                                    <div className={'w-[15px] absolute right-0 bottom-0 h-[16px] rounded-full flex items-center justify-center bg-[#ADADAD]'}>
                                        <img src="/media/svg/general/Group 2138.svg" className="w-[8px] h-[9px]" />
                                    </div>
                                </div>
                                <div className={'flex flex-col'}>
                                    <h6 className={'nunitomedium text-[#2B2F42] text-[20px]'}>
                                        {candidate.name + " " + candidate.surname}
                                    </h6>
                                    <h6 className={'nunitomedium text-[#ADADAD] text-[10px]'}>
                                        {candidate.job}
                                    </h6>
                                    <div className={'w-[95px] gap-1 h-[17px] flex items-center justify-center rounded-[7px] bg-[#36C5F1]'}>
                                        <img src="/media/svg/general/Page-1.svg" className="w-[7.5px] h-[10px]" />
                                        <p className={'nunitomedium text-white text-[12px] mt-4'}>
                                            {candidate.status}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'flex flex-col items-end justify-end pt-[4px]'}>
                                <div className="checkbox-wrapper-15 pr-[18px] gap-[18px] items-center flex flex-row a15">
                                    <input className={'inp-cbx'} id={`cbx-${index}`} type="checkbox" style={{ display: "none" }} />
                                    <label className="cbx !border-yellow-50" htmlFor={`cbx-${index}`}>
                                        <span>
                                            <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                <polyline points="1 5 4 8 11 1"></polyline>
                                            </svg>
                                        </span>
                                    </label>
                                </div>
                                <div className={'mt-[15px] pr-[5px]'}>
                                    <div className={'flex flex-col gap-2'}>
                                        <h6 className={'nunitomedium -mb-2 leading-3 text-[14px] text-[#14ED44]'}>{candidate.percentage}</h6>
                                        <img src="/media/svg/general/Graphic.svg" className="w-[69px] h-[22px]" />
                                    </div>
                                </div>
                                <p className={'nunitoregular mt-1 pr-[5px] text-end text-[#ADADAD] text-[#2B2F42] text-[10px]'}>
                                    This Candidate recommended by AI
                                </p>
                            </div>
                        </div>
                        <div className={'w-full h-[1px] bg-[#D8D8D8] flex justify-between items-center -mb-1'}></div>
                        <div className={'w-full h-fit justify-between flex-row flex px-[18px] items-center'}>
                            <div className={'flex  gap-[9px] flex-row gap-1 items-center '}>
                                <img src="/media/svg/general/calendar_month (1).svg" className="w-[16px] h-[17px]" />
                                <h6 className={'nunitomedium text-[#ADADAD] text-[12px mt-2 '}>
                                    {candidate.date}
                                </h6>
                            </div>
                            <div className={'flex flex-row w-fit'}>
                                <img src="/media/svg/general/Group 2146.svg" className="w-[72px] h-[12px]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CandidateCard;
