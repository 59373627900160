import React from 'react';

interface CandidateFilterCardProps {
    name: string;
    title: string;
    location: string;
    skills: string[];
    matchPercentage: string;
    avatar: string;
    description: string;
}

const CandidateFilterCard: React.FC<CandidateFilterCardProps> = ({
    name,
    title,
    location,
    skills,
    matchPercentage,
    avatar,
    description,
}) => {
    return (
        <div className="flex justify-between p-4 border-b border-black-700 rounded-lg shadow-xl">
            {/* Sol ve Orta Kısım: Profil Bilgileri ve Yetenekler */}
            <div className="flex flex-col">
                {/* Üst Kısım: Profil Bilgileri ve Yetenekler */}
                <div className="flex items-start mb-4">
                    <img src={avatar} alt={name} className="w-20 h-20 rounded-full mr-4" />
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <div className="mr-4">
                                <h4 className="text-lg font-semibold">{name}</h4>
                                <p className="text-sm text-gray-600">{title}</p>
                            </div>
                            <div className="flex flex-wrap items-center">
                                {skills.map(skill => (
                                    <div key={skill} className="mb-2 flex items-center border-2 border-red-500 rounded-full px-3 py-1 mr-2">
                                        <span className="text-sm text-gray-700">{skill}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Alt Kısım: Konum ve Açıklama */}
                <div className="flex">
                    <div className="mr-4">
                        <p className="text-sm text-black-500">{location}</p>
                    </div>
                    <div className="flex-grow">
                        <p className="text-sm mt-2">{description}</p>
                    </div>
                </div>
            </div>

            {/* Sağ Kısım: Yüzde ve Grafik */}
            <div className="flex flex-col items-end">
                <span className="font-semibold text-green-500 text-lg">{matchPercentage}</span>
                <img src="/media/svg/general/Graphic.svg" alt="AI Recommendation" className="w-24 h-16 mt-2" />
                <p className="text-sm text-gray-500 mt-2 text-right">This Candidate recommended by AI</p>
            </div>
        </div>
    );
};

export default CandidateFilterCard;
