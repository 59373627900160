import React from "react";
import Chart from "react-apexcharts";
import { InterviewRecordsModel } from "../../model";
import avatar from "@/../public/media/avatars/300-1.jpg";

interface InterviewRecordProps {
  data: InterviewRecordsModel[];
}

const ScoreGaugeChart = () => {
  const options: ApexCharts.ApexOptions = {
    // ApexOptions türünü belirttik
    chart: {
      type: "radialBar" as const, // "radialBar" olduğunu belirtmek için "as const" kullanıyoruz
      offsetY: -20,
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "100%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 5,
            fontSize: "12px",
            fontWeight: "500",
            formatter: (val: number) => `%${val.toFixed(2)}`, // 'val' için tür belirtildi
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#000000"],
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: "#000000",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#14ED44",
            opacity: 1,
          },
        ],
      },
    },
    labels: ["Progress"],
  };

  const series = [90.35];

  return (
    <div className="gauge-chart absolute top-[20px] left-[-30px]">
      <Chart
        options={options}
        series={series}
        type="radialBar"
        width={140}
        height={140}
      />
    </div>
  );
};

const InterviewRecords: React.FC<InterviewRecordProps> = ({ data }) => {
  return (
    <div className="px-[22px] py-[27px] pt-0 rounded-[32px] shadow-[0px_1px_4px_rgba(0,0,0,0.25)] h-[417px] overflow-auto">
      <table className="w-full text-left">
        <thead className="border-b-[1px] border-b-[#000000] sticky top-0 z-10 bg-[#F4F4F4]">
          <tr>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] w-[20%] h-[75px] pt-[27px] whitespace-nowrap">
              Interview Candidates
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] w-[28%] h-[75px] pt-[27px] whitespace-nowrap">
              Type of Interview
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] h-[75px] pt-[27px] whitespace-nowrap">
              Status
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] h-[75px] pt-[27px] whitespace-nowrap">
              Score
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] h-[75px] pt-[27px] whitespace-nowrap">
              Date
            </th>
            <th className="nunitosbold text-[22px] text-[#2B2F42] pr-[32px] border-b-[1px] border-b-[#B0B0B066] h-[75px] pt-[27px] whitespace-nowrap">
              Time
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="hover:bg-gray-100">
            <td className="border px-0 py-4 flex items-center flex-nowrap nunitomedium text-[14px] text-[#2B2F42]">
              <img
                src={avatar}
                alt="Avatar"
                className="w-[52px] h-[52px] rounded-full mr-[8%] shrink-0"
              />
              <span className="">John Doe</span>
            </td>
            <td className="border px-0 py-4 nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              Video Conferencing
            </td>
            <td className="border px-0 py-4 nunitobold text-[14px] text-[#34B53A] whitespace-nowrap">
              Completed
            </td>
            <td className="relative h-[60px]">
              <ScoreGaugeChart />
            </td>
            <td className="border px-0 py-4 nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              {new Date().toLocaleDateString()}
            </td>
            <td className="border px-0 py-4 nunitomedium text-[14px] text-[#2B2F42] whitespace-nowrap">
              {new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InterviewRecords;
