import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

interface YourLinksProps {
  onBack: () => void;
  onComplete: (links: SocialLinks) => void;
}

interface SocialLinks {
  linkedin?: string;
  github?: string;
  behance?: string;
  dribbble?: string;
  website?: string;
}

const validationSchema = Yup.object().shape({
  linkedin: Yup.string().url("Please enter a valid LinkedIn URL").nullable(),
  github: Yup.string().url("Please enter a valid Github URL").nullable(),
  behance: Yup.string().url("Please enter a valid Behance URL").nullable(),
  dribbble: Yup.string().url("Please enter a valid Dribbble URL").nullable(),
  website: Yup.string().url("Please enter a valid Website URL").nullable(),
});

const YourLinks: React.FC<YourLinksProps> = ({ onBack, onComplete }) => {
  const formik = useFormik({
    initialValues: {
      linkedin: "",
      github: "",
      behance: "",
      dribbble: "",
      website: "",
    },
    validationSchema,
    onSubmit: (values) => {
      onComplete(values);
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="w-full max-w-[750px] mx-auto">
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="linkedin"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            LinkedIn
          </label>
          <input
            type="url"
            id="linkedin"
            {...formik.getFieldProps("linkedin")}
            placeholder="https://www.linkedin.com/in/yourprofileid"
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.linkedin && formik.errors.linkedin
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.linkedin && formik.errors.linkedin && (
            <p className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.linkedin}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="github"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Github
          </label>
          <input
            type="url"
            id="github"
            {...formik.getFieldProps("github")}
            placeholder="https://www.github.com/yourprofileid"
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.github && formik.errors.github
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.github && formik.errors.github && (
            <p className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.github}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="behance"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Behance
          </label>
          <input
            type="url"
            id="behance"
            {...formik.getFieldProps("behance")}
            placeholder="https://www.behance.net/yourprofileid"
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.behance && formik.errors.behance
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.behance && formik.errors.behance && (
            <p className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.behance}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="dribbble"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Dribbble
          </label>
          <input
            type="url"
            id="dribbble"
            {...formik.getFieldProps("dribbble")}
            placeholder="https://www.dribbble.com/yourprofileid"
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.dribbble && formik.errors.dribbble
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.dribbble && formik.errors.dribbble && (
            <p className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.dribbble}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="website"
            className="block text-[22px] nunitosbold text-[#2B2F42]"
          >
            Website
          </label>
          <input
            type="url"
            id="website"
            {...formik.getFieldProps("website")}
            placeholder="https://www.yourwebsite.com"
            className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
              formik.touched.website && formik.errors.website
                ? "border-[#FE0000]"
                : "border-[#ADADAD]"
            }`}
          />
          {formik.touched.website && formik.errors.website && (
            <p className="text-[#FE0000] text-[14px] mt-[7px]">
              {formik.errors.website}
            </p>
          )}
        </div>

        <div className="flex gap-[20px] sm:gap-[35px] mt-[30px]">
          <button
            type="button"
            onClick={onBack}
            className="w-100 h-[60px] sm:h-[79.55px] border-[2px] border-[#D9D9D9]  rounded-[40px] nunitomedium text-[#B0B0B0] text-[18px] sm:text-[22px]"
          >
            Back
          </button>
          <button
            type="submit"
            className="w-100 h-[60px] sm:h-[79.55px] border-[2px] border-[#FE0000] bg-[#FE0000] hover:bg-red-500 rounded-[40px] nunitomedium text-[#F9F9F9] text-[18px] sm:text-[22px]"
          >
            Complete
          </button>
        </div>

        <p className="nunitosbold text-center mt-[30px] text-[18px] sm:text-[22px]">
          Back to{" "}
          <a href="/" className="text-[#02A0FC]">
            Home
          </a>
        </p>
      </form>
    </div>
  );
};

export default YourLinks;
