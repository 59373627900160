import * as Yup from "yup";
import logosvg from "@/_metronic/assets/registerlogin/logo.svg";
import see from "@/_metronic/assets/registerlogin/see.svg";
import hide from "@/_metronic/assets/registerlogin/hide.svg";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// Güncellenmiş initialValues
const initialValues = {
  password: "",
  confirmPassword: "",
};

// Güncellenmiş Yup doğrulama şeması
const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
});

export function NewPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        setHasErrors(false);
        setLoading(false);
        setSubmitting(false);
        navigate("../../new-password-result");
      }, 1000);
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-100 xl:w-[100%] 2xl:w-[643px] px-[10px] 2xl:px-0 max-w-[643px] rounded-lg">
      <img
        className="select-none cursor-pointer mx-auto"
        src={logosvg}
        alt=""
      />

      <form className="form w-full" noValidate onSubmit={formik.handleSubmit}>
        <div className="flex flex-col w-full justify-start items-center">
          <h5 className="text-[#FF4343] text-center select-none mt-[41px] nunitoregular text-[40px] mb-0">
            Forgot Password?
          </h5>
          <h5
            className={
              "my-[70px] w-full text-start select-none cursor-pointer text-[#2B2F42] text-[16px] nunitoregular italic"
            }
          >
            Please create your new password below and re-enter it to confirm.
            Make sure to choose a secure password and keep this information
            safe.
          </h5>
          <div className="flex flex-col max-w-[1800px] w-full">
            <div className="mt-[54px] gap-[33px] flex flex-col w-full">
              <div className="flex w-full flex-col">
                <div className="w-full">
                  <label
                    htmlFor="password"
                    className="block text-[22px] nunitosbold text-[#2B2F42]"
                  >
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="+8 characters"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                        formik.touched.password && formik.errors.password
                          ? "border-[#FE0000]"
                          : "border-[#ADADAD]"
                      }`}
                    />
                    <img
                      className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                        showPassword ? "hidden" : "block"
                      }`}
                      src={see}
                      alt="Show password"
                      onClick={togglePasswordVisibility}
                    />
                    <img
                      className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                        showPassword ? "block" : "hidden"
                      }`}
                      src={hide}
                      alt="Hide password"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-[#FE0000] text-[14px] mt-[7px]">
                      {formik.errors.password}
                    </div>
                  )}
                </div>

                <div className="w-full mt-[30px]">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-[22px] nunitosbold text-[#2B2F42]"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      placeholder="+8 characters"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                          ? "border-[#FE0000]"
                          : "border-[#ADADAD]"
                      }`}
                    />
                    <img
                      className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                        showPassword ? "hidden" : "block"
                      }`}
                      src={see}
                      alt="Show password"
                      onClick={togglePasswordVisibility}
                    />
                    <img
                      className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${
                        showPassword ? "block" : "hidden"
                      }`}
                      src={hide}
                      alt="Hide password"
                      onClick={togglePasswordVisibility}
                    />
                  </div>

                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-[#FE0000] text-[14px] mt-[7px]">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>

                <div className="d-flex flex-wrap justify-content-center pb-lg-0 mt-[30px]">
                  <button
                    type="submit"
                    className="nunitomedium w-full h-[60px] sm:h-[79.55px] bg-[#FE0000] text-white p-3 rounded-[40px] text-[18px] sm:text-[22px] hover:bg-red-600"
                  >
                    <span className="indicator-label nunitomedium select-none text-white text-[22px]">
                      Submit
                    </span>
                    {loading && (
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <p className="nunitosbold text-center mt-[30px] text-[18px] sm:text-[22px]">
              Back to{" "}
              <a href="/auth" className="text-[#02A0FC]">
                Login
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}
