import logosvg from "@/_metronic/assets/registerlogin/logo.svg";
import check from "@/_metronic/assets/registerlogin/check.svg";

export function ForgotPasswordResult() {
  return (
    <div className="w-100 xl:w-[100%] 2xl:w-[643px] px-[10px] 2xl:px-0 max-w-[643px] rounded-lg">
      <img
        className={"select-none cursor-pointer mx-auto"}
        src={logosvg}
        alt=""
      />
      <img
        className={"select-none cursor-pointer mx-auto mt-[129px]"}
        src={check}
        alt=""
      />
      <h5
        className={
          "text-[#FF4343] text-center select-none mt-[41px] nunitoregular text-[40px] mb-0"
        }
      >
        We’ve sent a link to your email.
      </h5>
      <h5
        className={
          "my-[70px] w-full text-center select-none cursor-pointer text-[#2B2F42] text-[16px] nunitosbold mb-bold"
        }
      >
        Please check your inbox, spam or junk.
      </h5>
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <a
          href="/auth"
          id="kt_password_reset_submit"
          className="nunitomedium w-full h-[60px] sm:h-[79.55px] bg-[#FE0000] text-white p-3 rounded-[40px] text-[18px] sm:text-[22px] hover:bg-red-600 flex justify-center items-center"
        >
          <span className="indicator-labe nunitomedium select-none text-white text-[22px]">
            Login
          </span>
        </a>
      </div>
    </div>
  );
}
