import { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

interface Props {
  title: string;
  count: string;
  description: string;
  icon: string;
  route: string;
  caret: boolean;
  caretText: string;
}

function ComponentAdvisor2({
  title,
  count,
  description,
  icon,
  route,
  caret,
  caretText,
}: Props) {
  const [isCaretGreen, setIsCaretGreen] = useState(caret);
  const splitTitle = title.split(" ");
  return (
    <a href={route} className="flex-1 h-[230px] !block">
      <div
        className={
          "flex-1 cursor-pointer relative flex-col justify-between flex h-[230px] rounded-[32px] shadowds1 bg-[#F4F4F4]"
        }
      >
        <div className={"flex flex-row gap-[24px] "}>
          <div
            className={
              "w-[64px] h-[64px] rounded-full flex justify-center items-center bg-[#C5BDBD1A] shrink-0 rounded-full mt-[20px] ml-[20px] relative"
            }
          >
            <img
              src={toAbsoluteUrl("media/icons/" + icon + ".svg")}
              className="w-[24px] h-[24px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
          <h6
            className={
              "nunitomedium leading-[35px] text-[30px] select-none text-[#2B2F42] my-[20px] mb-0"
            }
          >
            {splitTitle.map((word, index) => (
              <span key={index}>
                {word}
                {index !== splitTitle.length - 1 && <br />}{" "}
              </span>
            ))}
          </h6>
        </div>
        <div className={"mb-[36px] flex flex-col w-[170px] ml-[21px]"}>
          <h6
            className={
              "nunitomedium w-full leading-10 select-none w-fit  text-start text-[30px] text-[#2B2F42]"
            }
          >
            {count}
          </h6>
          <h6
            className={
              "nunitomedium text-[#2B2F42] text-opacity-[72%] leading-4 select-none mt-1   text-start text-[14px] mb-0"
            }
          >
            {description}
          </h6>
        </div>
        <div className="flex items-center gap-[7px] z-20 absolute right-[23px] top-[118px]">
          <img
            src={toAbsoluteUrl(
              isCaretGreen
                ? "media/dashboard/caret-icon-green.svg"
                : "media/dashboard/caret-icon-red.svg"
            )}
            alt={isCaretGreen ? "Green caret" : "Red caret"}
          />
          <span className="nunitomedium text-[14px] text-[#2B2F42]">
            {caretText}
          </span>
        </div>
        <div className=" absolute z-20 right-[70px] bottom-[48px]  ">
          <div className="w-[40px] h-[40px] relative bg-[#C5BDBD1A] rounded-full ">
            <img
              className={
                "w-[18px] h-[18px] object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              }
              src={toAbsoluteUrl("media/dashboard/date2-icon.svg")}
            />
          </div>
        </div>
      </div>
    </a>
  );
}

export default ComponentAdvisor2;
