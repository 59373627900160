import axios from 'axios';
import { getAuth } from "@/helpers/AuthHelpers";

const API_URL = import.meta.env.VITE_APP_API_URL;

const httpClient = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpClient.interceptors.request.use(
  (config) => {
    const auth = getAuth();
    if (auth) {
      config.headers.Authorization = `Bearer ${auth}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token'in süresi dolmuş olabilir
      const path = window.location.pathname;
      const lastSegment = path.split('/').filter(Boolean).pop();
      console.log(lastSegment);
      if (lastSegment !== undefined && lastSegment !== "auth") {
        window.location.href = '/auth';
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;