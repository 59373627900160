import * as Yup from "yup";

import { getCurrentUser, login } from "@/api/services/userService";
import { useEffect, useState } from "react";

import ToastService from "@/services/toast.service";
import googleIcon from "@/_metronic/assets/registerlogin/google-icon.svg";
import hide from "@/_metronic/assets/registerlogin/hide.svg";
import linkedinIcon from "@/_metronic/assets/registerlogin/linkedin-icon.svg";
import logosvg from "@/_metronic/assets/registerlogin/logo.svg";
import see from "@/_metronic/assets/registerlogin/see.svg";
import { useAuth } from "../core/Auth";
import { useFormik } from "formik";
import xingIcon from "@/_metronic/assets/registerlogin/xing-icon.svg";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("The email field must be a valid email")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "admin@assatekno.com",
  password: "123qwe",
  rememberMe: false,
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);

      const { Data, Message, Status } = await login(
        values.email,
        values.password
      );

      if (values.rememberMe) {
        localStorage.setItem("atsLoginEmailUser", values.email);
      } else {
        localStorage.removeItem("atsLoginEmailUser");
      }

      if (Status === "Ok") {
        saveAuth(Data.Token);
        const user = await getCurrentUser();
        setCurrentUser(user);
      } else {
        ToastService.success(Message);
      }

      setLoading(false);
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    let atsLoginEmailUser = localStorage.getItem("atsLoginEmailUser");

    if (atsLoginEmailUser) {
      formik.setFieldValue("email", atsLoginEmailUser);
    }
  }, []);

  return (
    <div className="w-100 xl:w-[100%] 2xl:w-[643px] px-[10px] 2xl:px-0 max-w-[643px] rounded-lg">
      <img
        className={"select-none cursor-pointer mx-auto"}
        src={logosvg}
        alt=""
      />
      <form className="form w-100" onSubmit={formik.handleSubmit} noValidate>
        <div className={"flex flex-col w-full flex justify-start items-center"}>
          <h5
            className={
              "text-[#FF4343] text-center select-none mt-[41px] nunitoregular text-[40px] mb-0"
            }
          >
            Log In to your account
          </h5>
          <h5
            className={
              "mt-[35px] select-none cursor-pointer text-[#2B2F42] text-[16px] nunitosbold mb-0"
            }
          >
            Do not have an account?
            <a href="/auth/register" className={"text-[#02A0FC]"}>
              {" "}
              Sign Up
            </a>
          </h5>
          <div className="text-center flex flex-col gap-[20px] mt-[53px]">
            <div className="flex items-center gap-[30px]">
              <a
                href="https://www.linkedin.com/oauth/v2/authorization"
                className="w-[48px] h-[48px] rounded-full"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedinIcon}
                  alt="Linkedin Icon"
                  className="w-full h-full object-contain"
                />
              </a>

              <a
                href="https://api.xing.com/v1/authorize"
                className="w-[48px] h-[48px] rounded-full"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={xingIcon}
                  alt="Xing Icon"
                  className="w-full h-full object-contain"
                />
              </a>

              <a
                href="https://accounts.google.com/o/oauth2/v2/auth"
                className="w-[48px] h-[48px] rounded-full"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleIcon}
                  alt="Google Icon"
                  className="w-full h-full object-contain"
                />
              </a>
            </div>

            <h4 className="nunitoregular mb-0 text-[14px] leading-[19.1px] text-[#2B2F42]">
              or continue with
            </h4>
          </div>
          <div className={"flex flex-col max-w-[1800px] w-full gap-[22px] "}>
            <div className={"mt-[54px] flex flex-col w-full "}>
              <div>
                {formik.status && formik.status.loginFailed && (
                  <div className="text-[#FE0000] text-[14px] mt-[7px]">
                    <span role="alert">{formik.status.loginFailed}</span>
                  </div>
                )}
                <div className="fv-row">
                  <label className="block text-[22px] nunitosbold text-[#2B2F42]">
                    Email or Username
                  </label>
                  <input
                    placeholder="Email"
                    {...formik.getFieldProps("email")}
                    className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${formik.touched.email && formik.errors.email
                      ? "border-[#FE0000]"
                      : "border-[#ADADAD]"
                      }`}
                    type="email"
                    name="email"
                    autoComplete="off"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-[#FE0000] text-[14px] mt-[7px]">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                <div className="fv-row mt-[33px]">
                  <label
                    htmlFor="password"
                    className="block text-[22px] nunitosbold text-[#2B2F42]"
                  >
                    Password *
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="+8 characters"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full bg-transparent nunitoregular text-[14px] block w-[304px] h-[46px] border-[1px] px-[16px] py-[8px] rounded-[3px] ${formik.touched.password && formik.errors.password
                        ? "border-[#FE0000]"
                        : "border-[#ADADAD]"
                        }`}
                    />
                    <img
                      className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${showPassword ? "hidden" : "block"
                        }`}
                      src={see}
                      alt="Show password"
                      onClick={togglePasswordVisibility}
                    />
                    <img
                      className={`absolute right-[16px] top-1/2 transform -translate-y-1/2 cursor-pointer ${showPassword ? "block" : "hidden"
                        }`}
                      src={hide}
                      alt="Hide password"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-[#FE0000] text-[14px] mt-[7px]">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="w-full flex justify-between items-center mt-[22px] mb-[30px]">
                <div className="flex items-center gap-1.5">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    name="rememberMe"
                    checked={formik.values.rememberMe}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mr-2 leading-tight shrink-0 checkbox"
                  />
                  <label
                    htmlFor="rememberMe"
                    className="text-[14px] text-[#2B2F42] opacity-[70px] nunitoregular"
                  >
                    Remember Me
                  </label>
                </div>

                <a
                  className=" italic underline-none text-[14px] text-[#2B2F42] nunitobold"
                  href="/auth/forgot-password"
                >
                  Forgot Password?
                </a>
              </div>

              <div className="d-grid mb-10">
                <button
                  type="submit"
                  className="nunitomedium w-full h-[60px] sm:h-[79.55px] bg-[#FE0000] text-white p-3 rounded-[40px] text-[18px] sm:text-[22px] hover:bg-red-600"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && (
                    <span className="indicator-label text-[22px]">Login</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
