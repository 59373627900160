/* eslint-disable react-refresh/only-export-components */

import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { getAuth, getCurrentUser, removeAuth, setLocalStorageAuth, setLocalStorageCurrentUser } from "@/helpers/AuthHelpers";

import { CurrentUser } from '@/api/models/Login/CurrentUser';
import { LayoutSplashScreen } from '@/_metronic/layout/core'
import { WithChildren } from '@/_metronic/helpers'

type AuthContextProps = {
  auth: string | undefined
  saveAuth: (auth: string | undefined) => void
  currentUser: CurrentUser | undefined
  setCurrentUser: (user: CurrentUser | undefined) => void
  logout: () => void
}

const initAuthContextPropsState = {
  auth: getAuth(),
  saveAuth: () => { },
  currentUser: getCurrentUser(),
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<string | undefined>(getAuth())
  const [currentUser, setCurrentUserState] = useState<CurrentUser | undefined>(getCurrentUser())

  const setCurrentUser = (user: CurrentUser | undefined) => {
    setCurrentUserState(user)
    setLocalStorageCurrentUser(user)
  }

  const saveAuth = (auth: string | undefined) => {
    setAuth(auth)
    if (auth) {
      setLocalStorageAuth(auth)
    } else {
      removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem("currentUser")
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, currentUser, logout, setCurrentUser } = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!currentUser) {
          const user = await getCurrentUser()
          if (user) {
            setCurrentUser(user)
          }
        }
      } catch (error) {
        console.error(error)
        if (currentUser) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }
    }

    if (auth) {
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [auth, currentUser, logout, setCurrentUser])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
