import React, { useState } from 'react';
import axios, { HttpStatusCode } from 'axios';
import { AddCandidateModel } from '../model';
import { Role, Education, Company } from '../enums/candidateEnums';
import { STATUS_CODES } from 'http';

interface IAddCandidate {
    cancel: (value: boolean) => void;
}

const initialValues = {
    id: "",
    resume: '',
    position: Role.UI_Designer,
    company: '',
    birthdate: '1994-09-07',
    fullname: '',
    degree: Education.Bachelors_Degree,
    email: '',
    university: '',
    location: '',
    department: '',
    phoneCode: '',
    phone: '',
    linkedin: '',
    language: '',
    portfolio: '',
}

const API_URL = "API_URL";

//company isimleri eklenecek 
// api url eklenecek
//ülke kodları ekleneck
//pdf alan alan düzenlenecek


const AddCandidate: React.FC<IAddCandidate> = ({ cancel }) => {
    const [formData, setFormData] = useState<AddCandidateModel>(initialValues);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onHandleSave = () => {
        console.log(formData)

        axios.post(API_URL, formData)
            .then(response => {
                if(response.status === HttpStatusCode.Ok)
                    cancel(true);
            })
            .catch(error => {
                console.error("Error saving data", error);
            });
    };

    const onHandleCancel = () => {
        cancel(true);
    };

    return (
        <div className="p-8">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold text-red-600">Add Candidate</h2>
                <button className="text-2xl font-bold text-gray-600" onClick={onHandleCancel}>X</button>
            </div>
            <div className="mt-4 flex items-center">
                <div className="w-20 h-20 bg-red-100 rounded-full flex items-center justify-center">
                    <img src="/media/svg/general/addcandipp.svg" className="w-[105px] h-[105px] rounded-full" />
                </div>
                <p className="ml-4 font-semibold text-black text-lg">
                    Your candidate <span className="text-red-600 font-bold">%85.24</span> match with this position.
                </p>
            </div>

            <form className="grid grid-cols-4 gap-8 mt-6">
                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="resume">Resume/CV *</label>
                    <div className="flex items-center">
                        <input
                            type="text"
                            id="resume"
                            name="resume"
                            value={formData.resume}
                            onChange={handleInputChange}
                            placeholder={initialValues.resume} 
                            className="border border-gray-300 rounded-md p-2 w-full"
                            readOnly
                        />
                        <button className="ml-2 p-2 bg-gray-200 rounded-md">
                            <img src="/media/svg/general/attachment.svg" alt="Attach" />
                        </button>
                    </div>
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="position">Position *</label>
                    <select
                        id="position"
                        name="position"
                        value={formData.position}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded-md p-2 w-full">
                        {Object.values(Role).map((role, index) => (
                            <option key={index} value={role}>
                                {role}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="company">Company *</label>
                    <select
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded-md p-2 w-full">
                        {Object.values(Company).map((company, index) => (
                            <option key={index} value={company}>
                                {company}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col">
    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="birthdate">Birth Date *</label>
    <input
        type="date"
        id="birthdate"
        name="birthdate"
        value={formData.birthdate}
        onChange={handleInputChange}
        className="border border-gray-300 rounded-md p-2 w-full"
    />
</div>

            </form>

            <form className="grid grid-cols-2 gap-2 mt-2">
                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="fullname">Fullname *</label>
                    <input
                        type="text"
                        id="fullname"
                        name="fullname"
                        value={formData.fullname}
                        onChange={handleInputChange}
                        placeholder={initialValues.fullname} 
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="degree">Degree *</label>
                    <select
                        id="degree"
                        name="degree"
                        value={formData.degree}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded-md p-2 w-full">
                        {Object.values(Education).map((education, index) => (
                            <option key={index} value={education}>
                                {education}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="email">Email Address *</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder={initialValues.email}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="university">University Name *</label>
                    <input
                        type="text"
                        id="university"
                        name="university"
                        value={formData.university}
                        onChange={handleInputChange}
                        placeholder={initialValues.university} 
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="location">Location *</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                        placeholder={initialValues.location}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="department">Department *</label>
                    <input
                        type="text"
                        id="department"
                        name="department"
                        value={formData.department}
                        onChange={handleInputChange}
                        placeholder={initialValues.department}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="phone">Phone *</label>
                    <div className="flex items-center">
                        <select
                            id="phoneCode"
                            name="phoneCode"
                            value={formData.phoneCode}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-md p-2">
                            <option>{"+90"}</option>
                            {/* Diğer ülke kodları */}
                        </select>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder={initialValues.phone}
                            className="border border-gray-300 rounded-md p-2 w-full ml-2"
                        />
                    </div>
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="linkedin">LinkedIn</label>
                    <input
                        type="url"
                        id="linkedin"
                        name="linkedin"
                        value={formData.linkedin}
                        onChange={handleInputChange}
                        placeholder={initialValues.linkedin}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="language">Language</label>
                    <input
                        type="text"
                        id="language"
                        name="language"
                        value={formData.language}
                        onChange={handleInputChange}
                        placeholder={initialValues.language}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-[22px] font-semibold text-gray-700 mb-2" htmlFor="portfolio">Portfolio</label>
                    <input
                        type="url"
                        id="portfolio"
                        name="portfolio"
                        value={formData.portfolio}
                        onChange={handleInputChange}
                        placeholder={initialValues.portfolio}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
            </form>

            <div className="flex justify-end mt-6">
                <button className="bg-gray-300 text-gray-700 font-bold py-2 px-6 rounded-3xl mr-4" onClick={onHandleCancel}>Cancel</button>
                <button className="bg-red-500 text-white font-bold py-2 px-6 rounded-3xl" onClick={onHandleSave}>Add</button>
            </div>
        </div>
    );
};

export default AddCandidate;
