
let api = {
  login: '/Users/Login',
  currentUser: '/Users/GetCurrent',

  getUsers: '/services/app/User/GetUsers',
  setUserStatus: '/services/app/User/SetUserStatus',

  candidateGetAll: '/services/app/Candidate/GetAll',

  postsGetAll: '/services/app/Posts/GetAll',

  advisorRegistration: '/Users/RegisterAdvisor',
  candidateRegistration: '/Users/RegisterCandidate',
  companyRegistration: '/Users/RegisterCompany',
}

export { api };