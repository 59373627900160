import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";

interface CircularProgressProps {
  series: number;
}

const CircularProgress: React.FC<CircularProgressProps> = (props) => {
  const options = {
    chart: {
      type: "radialBar" as const,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "65%",
        },
        track: {
          background: "#FDEDED",
        },
        dataLabels: {
          show: false,
        },
      },
    },
    colors: ["#FF4D4F"],
    stroke: {
      lineCap: "round" as const,
    },
    labels: ["series"],
  };

  const series = [props.series];

  return (
    <div id="chart" className="relative w-full h-[278px] mx-auto">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        width={"100%"}
        height={"100%"}
      />
      <div className="w-[90px] h-[90px] bg-[#fe0000B3] rounded-full absolute top-[126px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
        <span className="nunitomedium text-[22px] text-[#f9f9f9]">
          {series}%
        </span>
      </div>
    </div>
  );
};

const CustomBarChart: React.FC = () => {
  const options = {
    chart: {
      type: "bar" as const,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
        distributed: true,
        borderRadius: 10,
      },
    },
    colors: [
      "#FF0202",
      "#FF0202",
      "#FF0202",
      "#FEA800",
      "#FEA800",
      "#36C5F1",
      "#36C5F1",
    ],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Red-1",
        "Red-2",
        "Red-3",
        "Orange-1",
        "Orange-2",
        "Blue-1",
        "Blue-2",
      ],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Values",
      data: [45, 45, 45, 30, 30, 20, 20],
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      width={"100%"}
      height={280}
      className="mx-auto"
    />
  );
};

interface JobPost {
  jobPost: string;
  experience: string;
  deadline: string;
  status: string;
  applicationrecords: number;
  companyLogo: string;
  company: string;
}

const data: JobPost[] = [
  {
    companyLogo: "/public/media/svg/general/discord.svg",
    jobPost: "UI/UX Designer",
    experience: "5-10 Year",
    deadline: "3 Days",
    status: "ACTIVE",
    applicationrecords: 125,
    company: "Discord",
  },
  {
    companyLogo: "/public/media/svg/general/spotify.svg",
    jobPost: "UX Designer",
    experience: "5-10 Year",
    deadline: "1 Day",
    status: "ACTIVE",
    applicationrecords: 125,
    company: "Spotify",
  },
  {
    companyLogo: "/public/media/svg/general/slack.svg",
    jobPost: "Visual Designer",
    experience: "5-10 Year",
    deadline: "3 Days",
    status: "DEACTIVE",
    applicationrecords: 125,
    company: "Slack",
  },
];

const JobPost: React.FC = () => {
  const statusBodyTemplate = (rowData: JobPost) => {
    const statusClass =
      rowData.status === "ACTIVE"
        ? "flex justify-center items-center w-[90px] h-[29px] bg-[#34B53A26] text-[14px] text-[#34B53A]  rounded-[20px] nunitobold"
        : "flex justify-center items-center w-[90px] h-[29px] bg-[#FE000026] text-[14px] text-[#FE0000] rounded-[20px] nunitobold";
    return <span className={statusClass}>{rowData.status}</span>;
  };

  const jobPostTemplate = (rowData: JobPost) => {
    return (
      <div className="flex items-center gap-[10px] px-[10px] lg:px-0 py-2 rounded">
        <div className="w-[70px] h-[70px] flex justify-center items-center bg-white rounded-full shrink-0">
          <img
            src={rowData.companyLogo}
            alt={rowData.jobPost}
            className="w-[40px] h-[40px]  "
          />
        </div>
        <div>
          <div className="nunitomedium text-[22px] text-[#2B2F42] leading-[30.01px] text-left">
            {rowData.jobPost}
          </div>
          <div className="nunitomedium text-[14px] text-[#2B2F4299]">
            {rowData.company}
          </div>
        </div>
      </div>
    );
  };

  const deadlineBodyTemplate = (rowData: JobPost) => {
    const isOneDay = rowData.deadline === "1 Day";
    return (
      <div className="flex flex-col items-start text-[18px] text-left px-[10px] lg:px-0 py-7 rounded">
        <div className="flex items-center leading-[20px]">
          <span>{rowData.deadline}</span>{" "}
          {isOneDay && (
            <img
              src="/public/media/svg/general/report.svg"
              alt="Report"
              className="w-[18px] h-[18px] ml-2"
            />
          )}
        </div>
        <div className="leading-[20px]">
          <span className="nunitomedium text-[14px] text-[#00000099]">
            Last
          </span>
        </div>
      </div>
    );
  };

  const experienceTemplate = (rowData: JobPost) => {
    return (
      <div className="flex justify-between items-center font-nunito text-[18px] leading-[30.01px] text-left px-[10px] lg:px-2 py-2 rounded ">
        <div className="flex flex-col">
          <div className="flex items-center leading-[20px]">
            <span>{rowData.applicationrecords}</span>
          </div>
          <div className="leading-[20px]">
            <span className="nunitomedium text-[14px] text-[#00000099]">
              Year
            </span>
          </div>
        </div>
      </div>
    );
  };

  const applicationTemplate = (rowData: JobPost) => {
    return (
      <div className="flex justify-between items-center font-nunito text-[18px] leading-[30.01px] text-left px-[10px] lg:px-2 py-2 rounded">
        <div className="flex flex-col">
          <div className="flex items-center leading-[20px]">
            <span>{rowData.applicationrecords}</span>
          </div>
          <div className="leading-[20px]">
            <span className="nunitomedium text-[14px] text-[#00000099]">
              Application
            </span>
          </div>
        </div>
        <i className="pi pi-chevron-right text-gray-400 text-xl"></i>
      </div>
    );
  };

  const rowClass = (data: JobPost) => {
    return {
      "custom-row": true,
      "bg-[#f4f4f4]": true,
    };
  };

  return (
    <div className="cmax-w-[1800px] py-[33px] px-[10px] xxl:pl-[90px] xxl:pr-[61px] min-h-screen h-full bg-[#F4F4F4]">
      <h1 className="nunitomedium text-[30px] text-[#2B2F42] mb-[5px]">
        Candidates
      </h1>
      <p className="nunitomedium text-[14px] text-[#2B2F4299] mb-[20px]">
        Let's compare the candidates.
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-6">
        <Link
          to="/job-postings"
          className="rounded-[32px] shadow-[0px_-1px_4px_#00000040] shadow-[1px_1px_4px_#00000040] p-6 flex flex-col items-center justify-center cursor-pointer border border-gray-300 bg-[#F4F4F4] "
        >
          <img
            src="/public/media/svg/general/creat.svg"
            alt="Create"
            className="w-[232px] h-[232px] mt-[] mb-0"
          />
          <img
            src="/public/media/svg/general/create-text.png"
            alt="Create"
            className="w-[127px] h-[44px] mb-0"
          />
        </Link>

        <div className="rounded-[32px] shadow-[0px_-1px_4px_#00000040] shadow-[1px_1px_4px_#00000040] p-[30px] flex flex-col  justify-between items-start border border-gray-300 bg-[#F4F4F4]">
          <h2 className="nunitomedium text-[22px] text-[#2B2F42] mb-0">
            Open ADS
          </h2>
          <CircularProgress series={65} />
          <div className="flex items-center justify-start gap-[22px] w-full">
            <div className="flex items-center">
              <span className="w-[14px] h-[14px] bg-[#FE0000] rounded-full mr-1 inline-block"></span>
              <span className="nunitomedium text-[14px] text-[#2B2F42]">
                Fullness
              </span>
            </div>
            <div className="flex items-center">
              <span className="w-[14px] h-[14px] bg-[#FFEAEA] rounded-full mr-1 inline-block"></span>
              <span className="nunitomedium text-[14px] text-[#2B2F42]">
                Empty
              </span>
            </div>
          </div>
        </div>

        <div className="rounded-[32px] shadow-[0px_-1px_4px_#00000040] shadow-[1px_1px_4px_#00000040] p-[30px] flex flex-col items-start justify-start border border-gray-300 bg-[#F4F4F4]">
          <h2 className="nunitomedium text-[22px] ] leading-[30px] text-left font-nunito mb-0">
            Completed Purchases
          </h2>
          <CustomBarChart />
          <div className="flex items-center flex-wrap justify-start gap-[19px] mt-4 w-full">
            <div className="flex items-center">
              <span className="w-[14px] h-[14px] bg-[#FF0202] rounded-full mr-1 inline-block"></span>
              <span className="nunitomedium text-[14px] text-[#2B2F42]">
                Total
              </span>
            </div>
            <div className="flex items-center">
              <span className="w-[14px] h-[14px] bg-[#FEA800]  rounded-full mr-1 inline-block"></span>
              <span className="nunitomedium text-[14px] text-[#2B2F42]">
                Evaluation
              </span>
            </div>
            <div className="flex items-center">
              <span className="w-[14px] h-[14px] bg-[#36C5F1]  rounded-full mr-1 inline-block"></span>
              <span className="nunitomedium text-[14px] text-[#2B2F42]">
                Recruitment
              </span>
            </div>
          </div>
        </div>

        <div className="rounded-[32px] shadow-[0px_-1px_4px_#00000040] shadow-[1px_1px_4px_#00000040] p-[30px] flex flex-col items-start justify-start border border-gray-300 bg-[#F4F4F4]">
          <h2 className="nunitomedium text-[22px] text-[#2B2F42] mb-0 leading-[30px] text-left mb-[8px]">
            Match Suitable Candidate
          </h2>
          <p className="nunitomedium text-[14px] text-[#00000066] mb-4">
            Matches based on candidates' CVs, skill sets and experience
          </p>
          <div className="flex flex-col gap-[24px] w-full">
            <div>
              <div className="flex justify-between mb-1">
                <span className="nunitomedium text-[14px] text-[#2B2F42]">
                  UI/UX Designer
                </span>
                <span className="nunitomedium text-[14px] text-[#FEA800]">
                  65,376
                </span>
              </div>
              <div className="w-full bg-[#FEA80033] h-[8px] rounded-[72px]">
                <div
                  className="bg-[#FEA800] h-[8px] rounded-[72px]"
                  style={{ width: "70%" }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-1">
                <span className="nunitomedium text-[14px] text-[#2B2F42]">
                  UX Designer
                </span>
                <span className="nunitomedium text-[14px] text-[#FE00C6]">
                  12,109
                </span>
              </div>
              <div className="w-full bg-[#FED7FA] h-[8px] rounded-[72px]">
                <div
                  className="bg-[#FE00C6] h-[8px] rounded-[72px]"
                  style={{ width: "20%" }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-1">
                <span className="nunitomedium text-[14px] text-[#2B2F42]">
                  Senior UI Designer
                </span>
                <span className="nunitomedium text-[14px] text-[#36C5F1]">
                  132,645
                </span>
              </div>
              <div className="w-full bg-[#36C5F133] h-[8px] rounded-[72px]">
                <div
                  className="bg-[#36C5F1] h-[8px] rounded-[72px]"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-1">
                <span className="nunitomedium text-[14px] text-[#2B2F42]">
                  Product Designer
                </span>
                <span className="nunitomedium text-[14px] text-[#FE0000]">
                  100,429
                </span>
              </div>
              <div className="w-full bg-[#FFEAEA] h-[8px] rounded-[72px]">
                <div
                  className="bg-[#FE0000] h-[8px] rounded-[72px]"
                  style={{ width: "80%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-[32px] shadow-[0px_-1px_4px_#00000040] shadow-[1px_1px_4px_#00000040] p-[33px]  bg-[#f4f4f4]">
        <DataTable
          value={data}
          rowClassName={rowClass}
          className="bg-[#f4f4f4]"
        >
          <Column
            field="jobPost"
            header="Job Post"
            body={jobPostTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42] leading-[30.01px] text-left px-[10px] lg:px-0 pb-[20px] rounded bg-[#f4f4f4]"
            className="nunitomedium text-[18px] text-[#2B2F42] border-t-2 border-b-2 p-0"
          />
          <Column
            field="experience"
            header="Experience"
            body={experienceTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42]  leading-[30.01px] text-left px-[10px] lg:px-0 pb-[20px]  rounded bg-[#f4f4f4]"
            className="nunitomedium text-[18px] text-[#2B2F42]  border-t-2 border-b-2"
            bodyClassName="font-nunito text-[18px] leading-[30.01px] text-left  px-0 py-2 rounded"
          />
          <Column
            field="deadline"
            header="Deadline"
            body={deadlineBodyTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42] leading-[30.01px] text-left px-[10px] lg:px-0 pb-[20px]  rounded bg-[#f4f4f4]"
            className="nunitomedium text-[18px] text-[#2B2F42] px-0 border-t-2 border-b-2"
          />
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42] leading-[30.01px] text-left   px-[10px] lg:px-0 pb-[20px] rounded bg-[#f4f4f4]"
            className="text-gray-800  border-t-2 border-b-2"
          />
          <Column
            field="applicationrecords"
            header="Application Records"
            body={applicationTemplate}
            headerClassName="nunitosbold  text-[22px] text-[#2B2F42] leading-[30.01px] text-left  px-[10px] lg:px-0 pb-[20px]  rounded bg-[#f4f4f4]"
            className="nunitomedium text-[18px] text-[#2B2F42] px-0 border-t-2 border-b-2"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default JobPost;
