import React, { useEffect } from "react";
import searchbg from "../../../_metronic/assets/registerlogin/searchbg.svg";
import illustbg from "../../../_metronic/assets/registerlogin/illust.svg";
import { Outlet } from "react-router-dom";
import "./registerlogin.css";

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  return (
    <>
      <div
        className={
          "flex flex-col justify-center pb-[140px] lg:pb-0 lg:flex-row w-full h-max short:h-[max-content]"
        }
      >
        <div
          className={`w-full md:min-w-[740px] hidden  lg:block   h-full lg:min-w-[500px] 2xl:w-[1300px] py-[50px]  lg:py-[110px] flex-col justify-start items-center bg-[#FF4343] relative`}
        >
          <div className={"z-20 w-full  h-full flex  flex-col items-center "}>
            <img
              className={"xl:w-fit   w-64 h-auto   lg:w-80"}
              src={illustbg}
              alt=""
            />
            <div className={" mt-[50px] lg:mt-[115px]"}>
              <h2
                className={
                  "nunitomedium text-white text-[30px] w-[380px] md:w-[600px] text-center lg:w-[400px] xl:w-[454px]  mb-0 leading-[40.92px]"
                }
              >
                We are the highest-rated ATS in the market, trusted by
                recruiters in 50+ countries!
              </h2>
            </div>
            <img
              className={
                "select-none w-full h-full  absolute lg:top-[-110px] top-0 object-cover z-10"
              }
              src={searchbg}
              alt=""
            />
          </div>
        </div>
        <div
          className={
            "w-full max-w-[1950px] min-h-screen z-20 order-1 sm:order-2  sm:px-[0vw] items-center xl:px-[0vw]  flex justify-start flex-col order-1 md:order-2"
          }
        >
          <div
            className={
              "w-100 xl:w-[100%] 2xl:w-[643px]  px-[10px] 2xl:px-0 max-w-[643px] rounded-lg py-[51px] lg:py-[102px]"
            }
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export { AuthLayout };
