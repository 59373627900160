import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title="Overflow"
        fontIcon="bi-house"
      />
      <SidebarMenuItem
        to="/candidates"
        icon="switch"
        title="Candidates"
        fontIcon="bi-person-add"
      />
      <SidebarMenuItem
        to="/job-postings-pre"
        icon="switch"
        title="Job Postings"
        fontIcon="bi-globe-americas"
      />
      <SidebarMenuItem
        to="/interview-management"
        icon="switch"
        title="Interview Management"
        fontIcon="bi-person-badge"
      />
      <SidebarMenuItem
        to="/temp"
        icon="switch"
        title="Reports"
        fontIcon="bi-file-earmark-text"
      />
      <SidebarMenuItem
        to="/temp2"
        icon="switch"
        title="Workflow"
        fontIcon="bi-diagram-3"
      />
      <SidebarMenuItem
        to="/temp3"
        icon="switch"
        title="Payment"
        fontIcon="bi-credit-card"
      />

      <div className="mt-[140px]">
        <SidebarMenuItem
          to="/temp4"
          icon="switch"
          title="Settings"
          fontIcon="bi-gear"
        />
        <SidebarMenuItem
          to="/temp4"
          icon="switch"
          title="Help"
          fontIcon="bi-question-circle"
        />
        <SidebarMenuItem
          to="/temp5"
          icon="switch"
          title="Log out"
          fontIcon="bi-box-arrow-in-right"
        />
      </div>

      {/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> 
      <SidebarMenuItem
        to="/candidates"
        icon="switch"
        title="Candidates"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/jobs"
        icon="switch"
        title="Jobs"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/job-postings"
        icon="switch"
        title="Job Postings"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/interview-management"
        icon="switch"
        title="Interview Management"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/onboarding"
        icon="switch"
        title="Onboarding"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/administration"
        icon="switch"
        title="Administration"
        fontIcon="bi-layers"
      />
      */}
    </>
  );
};

export { SidebarMenuMain };
