import { Link } from "react-router-dom";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenu2 = () => {
  return (
    <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
      <div
        id="kt_app_sidebar_menu_wrapper"
        className="app-sidebar-wrapper hover-scroll-overlay-y my-5 w-full"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
        data-kt-scroll-offset="5px"
        data-kt-scroll-save-state="true"
      >
        <div
          className="menu menu-column menu-rounded menu-sub-indention"
          id="kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
          <>
            <div className="px-[40px] mb-[19px]">
              <Link to="/job-postings">
                <div className="w-full h-[60px] rounded-[32px] bg-[#FE0000] py-[20px] pl-[22px] xxl:pl-[45px] relative">
                  <span className="nunitomedium text-[14px] text-[#F9F9F9]">
                    Create New Ad
                  </span>
                  <img
                    className="absolute top-1/2 -translate-y-1/2 right-[22px] hidden xxl:block"
                    src="@/../public/media/icons/circle-plus.svg"
                    alt=""
                  />
                </div>
              </Link>
            </div>
            <SidebarMenuItem
              to="/jobs"
              icon="element-11"
              title="Jobs"
              fontIcon="bi-award"
            />
            <SidebarMenuItem
              to="/jobs-positions"
              icon="element-12"
              title="Positions"
              fontIcon="bi-gear"
            />
            <SidebarMenuItem
              to="/jobs-companies"
              icon="element-13"
              title="Companies"
              fontIcon="bi-building"
            />
          </>
        </div>
      </div>
    </div>
  );
};

export { SidebarMenu2 };
