import React, { useState } from 'react';

import { useNavigate } from "react-router-dom";

const MyComponent = () => {
    const [inside, setInside] = useState({
        menu: false,
    });
    const navigate = useNavigate();

    const toggleMenu = () => {
        setInside(prev => ({ ...prev, menu: !prev.menu }));
    };

    return (
        <div className='w-full max-w-[1300px] py-[13px] px-[22px] flex flex-row justify-between items-center h-[64px] rounded-[40px] bg-white bg-opacity-[45%]'>
            <div className='flex items-center flex-row cursor-pointer hover:scale-110 transition-all duration-100 ease-in-out gap-[8px]'>
                <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                </svg>
                <svg width="146" height="15" viewBox="0 0 146 15" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                </svg>
            </div>
            <div className='lg:flex hidden flex-row gap-[26px] items-center'>
                <h5 className='font-normal text-[14px] text-[#E30019] select-none cursor-pointer'>Home</h5>
                <h5 className='font-normal text-[14px] text-[#E30019] select-none cursor-pointer text-opacity-60'>About Us</h5>
                <h5 className='font-normal text-[14px] text-[#E30019] select-none cursor-pointer text-opacity-60'>Search Jobs</h5>
                <h5 className='font-normal text-[14px] text-[#E30019] select-none cursor-pointer text-opacity-60'>Career Guide</h5>
                <h5 className='font-normal text-[14px] text-[#E30019] select-none cursor-pointer text-opacity-60'>Contact Us</h5>
            </div>
            <svg className='lg:hidden flex' width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.00049 13.5H45.0005M9.00049 27H45.0005M9.00049 40.5H45.0005" stroke="black" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <div className='lg:flex hidden flex-row gap-[24px]'>
                <div className='px-[22px] hover:bg-[#0032E3] group cursor-pointer py-[8px] rounded-[30px] border-[2px] border-[#0032E3]'>
                    <h5 className='text-[#0032E3] group-hover:text-white text-[14px] font-semibold pt-3'>Get Offer !</h5>
                </div>
                <div onMouseLeave={() => setInside({ ...inside, menu: false })} className='relative'>
                    <div onClick={toggleMenu} className={`px-[20px] cursor-pointer flex items-center py-[8px] ${inside.menu ? "rounded-b-[0px] rounded-t-[20px]" : "rounded-[30px]"} bg-[#E30019] bg-opacity-60`}>
                        <h5 className='text-white select-none font-semibold pt-3'>Login / Register</h5>
                        <svg className={`ml-2 transition-transform ${inside.menu && "rotate-180"}`} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6 6L11 1" stroke="#F9F9F9" strokeWidth="2" />
                        </svg>
                    </div>
                    {inside.menu && (
                        <div className='absolute top-10 left-0 w-[350px] py-[15px] flex flex-col justify-start items-center bg-white bg-opacity-100 rounded-[20px] shadow-lg'>
                            <div className='w-full flex justify-between px-[30px]'>
                                <div onClick={() => { navigate('/auth/register'); setInside({ ...inside, menu: false }); }} className='px-[36px] h-[35px] cursor-pointer flex items-center py-[8px] rounded-[30px] bg-[#E30019] bg-opacity-60'>
                                    <h5 className='text-white select-none font-normal pt-3'>Register</h5>
                                </div>
                                <div onClick={() => { navigate('/auth'); setInside({ ...inside, menu: false }); }} className='px-[36px] h-[35px] cursor-pointer flex items-center py-[8px] rounded-[30px] border-[1px] border-opacity-60 border-[#E30019] bg-opacity-60'>
                                    <h5 className='text-[#E30019] select-none font-normal pt-3'>Login</h5>
                                </div>
                            </div>
                            <div className='flex flex-col w-full gap-[20px] px-[30px] pt-4'>
                                <h5 className='normal text-[#E30019]'>Sign up free</h5>
                                <h5 className='normal text-[#E30019]'>Requitments solutions</h5>
                                <h5 className='normal text-[#E30019]'>Purchase Advertisement Package</h5>
                                <h5 className='normal text-[#E30019]'>Let Us Call You</h5>
                                <h5 className='normal text-[#E30019]'>Call Us: 0212 212 21 21</h5>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyComponent;
