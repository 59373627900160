import React, { useState } from "react";
import StartingRecruitment from "./component/startingRecruitment";
import HiredEmployees from "./component/hiredEmployees";
import OngoingRecruitment from "./component/ongoingRecruitment";

const OnboardingManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Start");

  const renderTabContent = () => {
    switch (activeTab) {
      case "Start":
        return <StartingRecruitment />;
      case "Ongoing":
        return <OngoingRecruitment />;
      case "Hire":
        return <HiredEmployees />;
      default:
        return null;
    }
  };

  return (
    <div className="max-w-[1800px] bg-white px-[50px] lg:pl-[90px] py-[33px] min-h-screen h-full bg-[#F4F4F4]">
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-[20px]">
        <div className="flex flex-col gap-[5px]">
          <h4 className="nunitomedium text-[30px] text-[#2B2F42]">
            Onboarding Management
          </h4>
          <h4 className="nunitomedium text-[14px] text-gray-600">
            Manage your recruitment process.{" "}
          </h4>
        </div>
      </div>
      <div className="flex justify-center space-x-4 mb-6">
        <button
          className={`px-6 py-2 rounded-[10px] ${
            activeTab === "Start"
              ? "bg-red-500 text-white"
              : "bg-[#FFEEEE] text-red-500"
          }`}
          onClick={() => setActiveTab("Start")}
        >
          Starting Recruitment
        </button>
        <button
          className={`px-6 py-2 rounded-[10px] ${
            activeTab === "Ongoing"
              ? "bg-red-500 text-white"
              : "bg-[#FFEEEE] text-red-500"
          }`}
          onClick={() => setActiveTab("Ongoing")}
        >
          Ongoing Recruitment
        </button>
        <button
          className={`px-6 py-2 rounded-[10px] ${
            activeTab === "Hire"
              ? "bg-red-500 text-white"
              : "bg-[#FFEEEE] text-red-500"
          }`}
          onClick={() => setActiveTab("Hire")}
        >
          Hired Employees
        </button>
      </div>

      {renderTabContent()}
    </div>
  );
};

export default OnboardingManagement;
