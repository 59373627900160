import React from "react";

interface ProgressBarProps {
  progress: number;
  progressColor: string;
  backgroundColor: string;
  text: string;
}

const PerformanceAnalysis1: React.FC = () => {
  const ProgressBar: React.FC<ProgressBarProps> = ({
    progress,
    progressColor,
    backgroundColor,
    text,
  }) => {
    return (
      <div className="flex flex-col items-end gap-[10px]">
        <span className="ml-4 text-gray-500">{text}</span>
        <div
          className="relative w-full h-[8px] rounded-[71px] overflow-hidden"
          style={{ backgroundColor: backgroundColor }}
        >
          <div
            className="absolute top-0 left-0 h-full rounded-full"
            style={{ width: `${progress}%`, backgroundColor: progressColor }}
          ></div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={
          "w-full pb-[40px] pr-[20px] rounded-[32px] flex flex-col bg-[#F4F4F4] min-h-[266px] pt-[27px] pl-[21px] shadowds1 relative"
        }
      >
        <div className={"flex flex-col lg:flex-col w-full "}>
          <div className={"flex flex-col lg:flex-row items-center"}>
            <img
              src={"/media/svg/general/Vector (1).svg"}
              className={"ml-6 mr-6 w-[60px] h-[60px]"}
            />
            <p className={"nunitoregular text-left text-[22px] text-[#2B2F42]"}>
              Companies' Listing <br /> Closure Statistics
            </p>
          </div>

          <div
            className={
              "flex flex-col gap-12 lg:gap-0 lg:flex-row justify-between items-center"
            }
          >
            <div className={"w-full flex flex-col gap-2 items-center"}>
              <div className="w-full flex justify-between py-[10px] xxl:py-[20px]">
                <div className="flex items-center gap-[5px] ">
                  <img
                    src={"/media/svg/general/spotify.svg"}
                    className={" w-[25px] h-[25px]"}
                  />
                  <div className="flex flex-col gap-[5px]">
                    <span className="nunitobold text-[#2B2F4266] text-[10px]">
                      Spotify
                    </span>
                    <span className="nunitoregular text-[#2B2F4266] text-[10px]">
                      12 adv
                    </span>
                  </div>
                </div>
                <div className="w-1/2">
                  <ProgressBar
                    progress={75}
                    progressColor="#14ED44"
                    backgroundColor="#14ED4433"
                    text="2 weeks"
                  />
                </div>
                <span className="flex items-center nunitoregular text-[#2B2F4266] text-[10px]">
                  2 weeks
                </span>
              </div>
              <div className="w-full h-[1px] bg-[#A1A3AA] bg-opacity-40"></div>

              <div className="w-full flex justify-between py-[10px] xxl:py-[20px]">
                <div className="flex items-center gap-[5px] ">
                  <img
                    src={"/media/svg/general/spotify.svg"}
                    className={" w-[25px] h-[25px]"}
                  />
                  <div className="flex flex-col gap-[5px]">
                    <span className="nunitobold text-[#2B2F4266] text-[10px]">
                      Spotify
                    </span>
                    <span className="nunitoregular text-[#2B2F4266] text-[10px]">
                      12 adv
                    </span>
                  </div>
                </div>
                <div className="w-1/2">
                  <ProgressBar
                    progress={50}
                    progressColor="#FEA800"
                    backgroundColor="#FEA80033"
                    text="6 weeks"
                  />
                </div>
                <span className="flex items-center nunitoregular text-[#2B2F4266] text-[10px]">
                  6 weeks
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceAnalysis1;
