import React, { useState } from "react";
import CreateJobPost1 from "./components/createjobpost1";
import CreateJobPost2 from "./components/createjobpost2";
import CreateJobPost3 from "./components/createjobpost3";
import CreateJobPost4 from "./components/createjobpost4";

const CreateJobPostLayout = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  return (
    <div className="px-[2%] lg:p-6 bg-[#f4f4f4] h-max-content lg:h-screen">
      <h1 className="nunitomedium text-[30px] leading-[35px] text-left mb-0">
        Create a New Ad
      </h1>
      <p className="nunitomedium text-[14px] text-[#2B2F4299] font-medium leading-[20px] text-left text-gray-600 mb-[25px]">
        Let's create a new ad.
      </p>

      <div className="flex flex-col lg:flex-row mb-0 bg-[#f4f4f4]">
        <div
          className={`flex-1 flex items-center cursor-pointer ${
            currentStep === 1 ? "text-green-500" : "text-gray-400"
          }`}
          onClick={() => handleStepChange(1)}
        >
          <div
            className={`w-full h-[120px] flex items-center justify-start p-4 ${
              currentStep === 1
                ? "border-2 border-green-500 bg-[#14ED4433]"
                : "border border-gray-300 bg-gray-100"
            } lg:rounded-l-[32px]`}
          >
            <div className="flex items-center justify-center bg-[#E2FBD7] w-[82px] h-[82px] rounded-full mr-3 shrink-0">
              <span className="text-[#00D95F] text-[40px] font-bold">1</span>
            </div>
            <div className="text-left">
              <p className="nunitomedium text-[#2B2F42] text-[22px] leading-[30.01px] mb-[2px]">
                Advert Title
              </p>
              <p className="text-[14px] text-[#2B2F4266] leading-[20px]">
                Proceed by entering the job title.
              </p>
              <div className="mt-2">
                <img
                  src="/public/media/svg/general/yesilalt.svg"
                  alt="green underline"
                  style={{
                    width: "240px",
                    height: "8px",
                    position: "relative",
                    top: "0px",
                    left: "0px",
                    gap: "0px",
                    opacity: "1",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex-1 flex items-center cursor-pointer ${
            currentStep === 2 ? "text-red-500" : "text-gray-400"
          }`}
          onClick={() => handleStepChange(2)}
        >
          <div
            className={`w-full h-[120px] flex items-center justify-start p-4 ${
              currentStep === 2
                ? "border-2 border-red-500 bg-[#FFDAD7]"
                : "border border-gray-300 bg-gray-100"
            }`}
          >
            <div className="flex items-center justify-center bg-[#FFDAD7] w-[82px] h-[82px] rounded-full mr-3 shrink-0">
              <span className="text-red-500 text-[40px] font-bold">2</span>
            </div>
            <div className="text-left">
              <p className="nunitomedium text-[#2B2F42] text-[22px] leading-[30.01px] mb-[2px]">
                Job Description
              </p>
              <p className="text-[14px] text-[#2B2F4266] leading-[20px]">
                Proceed by entering searched JDs.
              </p>
              <div className="mt-2">
                <img
                  src="/public/media/svg/general/kirmizialt.svg"
                  alt="red underline"
                  style={{
                    width: "240px",
                    height: "8px",
                    position: "relative",
                    top: "0px",
                    left: "0px",
                    gap: "0px",
                    opacity: "1",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex-1 flex items-center cursor-pointer ${
            currentStep === 3 ? "text-blue-500" : "text-gray-400"
          }`}
          onClick={() => handleStepChange(3)}
        >
          <div
            className={`w-full h-[120px] flex items-center justify-start p-4 ${
              currentStep === 3
                ? "border-2 border-blue-500 bg-[#D7EDFF]"
                : "border border-gray-300 bg-gray-100"
            }`}
          >
            <div className="flex items-center justify-center bg-[#D7EDFF] w-[82px] h-[82px] rounded-full mr-3 shrink-0">
              <span className="text-blue-500 text-[40px] font-bold">3</span>
            </div>
            <div className="text-left">
              <p className="nunitomedium text-[#2B2F42] text-[22px] leading-[30.01px] mb-[2px]">
                Questions
              </p>
              <p className="text-[14px] text-[#2B2F4266] leading-[20px]">
                Proceed by entering questions.
              </p>
              <div className="mt-2">
                <img
                  src="/public/media/svg/general/mavialt.svg"
                  alt="blue underline"
                  style={{
                    width: "240px",
                    height: "8px",
                    position: "relative",
                    top: "0px",
                    left: "0px",
                    gap: "0px",
                    opacity: "1",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex-1 flex items-center cursor-pointer ${
            currentStep === 4 ? "text-yellow-500" : "text-gray-400"
          }`}
          onClick={() => handleStepChange(4)}
        >
          <div
            className={`w-full h-[120px] flex items-center justify-start p-4 ${
              currentStep === 4
                ? "border-2 border-yellow-500 bg-[#ED891433]"
                : "border border-gray-300 bg-gray-100"
            } lg:rounded-r-[32px]`}
          >
            <div className="flex items-center justify-center bg-[#FFF7D7] w-[82px] h-[82px] rounded-full mr-3 shrink-0">
              <span className="text-yellow-500 text-[40px] font-bold">4</span>
            </div>
            <div className="text-left">
              <p className="nunitomedium text-[#2B2F42] text-[22px] leading-[30.01px] mb-[2px]">
                Publish Post
              </p>
              <p className="text-[14px] text-[#2B2F4266] leading-[20px]">
                Publish your post with other channels.
              </p>
              <div className="mt-2">
                <img
                  src="/public/media/svg/general/turuncualt.svg"
                  alt="yellow underline"
                  style={{
                    width: "240px",
                    height: "8px",
                    position: "relative",
                    top: "0px",
                    left: "0px",
                    gap: "0px",
                    opacity: "1",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {currentStep === 1 && <CreateJobPost1 />}
        {currentStep === 2 && <CreateJobPost2 />}
        {currentStep === 3 && <CreateJobPost3 />}
        {currentStep === 4 && <CreateJobPost4 />}
      </div>
    </div>
  );
};

export default CreateJobPostLayout;
