import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateJobPost3 = () => {
  const navigate = useNavigate();
  const [showQuestionInput, setShowQuestionInput] = useState(false);

  const handleNext = () => {
    navigate("/createjobpostlayout/step4");
  };

  const handleShowQuestionInput = () => {
    setShowQuestionInput(true);
  };

  return (
    <div className="p-[10px] lg:p-6 pb-[120px]">
      <h2 className="nunitomedium text-[22px] text-[#2B2F42] leading-[30.01px] text-left mb-2">
        Additional Questions
      </h2>
      <p className="nunitomedium text-[14px] text-[#000000] leading-[20px] text-left mb-4">
        Choose one or more questions to ask for your candidate.
      </p>

      <div className="w-full h-full rounded-[20px] border border-gray-300 px-[16px] lg:px-[53px] py-[16px] lg:py-[44px] overflow-y-auto shadow-[0px_1px_10px_0px_rgba(0,0,0,0.25)]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[200px] gap-y-6">
          <div className="space-y-4 justify-center items-center">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
              />
              <div className="w-full">
                <p className="nunitomedium text-[14px] text-[#2B2F42] leading-[20px] text-left">
                  How many years of work experience do you have with Figma?*
                </p>
                <input
                  type="text"
                  className="block w-full lg:w-[421px] h-[36px] p-2 border-2 border-[#B0B0B0] bg-transparent rounded-[10px] mt-2"
                  placeholder=""
                />
              </div>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
              />
              <div className="w-full">
                <p className="nunitomedium text-[14px] text-[#2B2F42] leading-[20px] text-left">
                  How many years of work experience do you have with AdobeXD?*
                </p>
                <input
                  type="text"
                  className="block w-full lg:w-[421px] h-[36px] p-2 border-2 border-[#B0B0B0] bg-transparent rounded-[10px] mt-2"
                  placeholder=""
                />
              </div>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
              />
              <div className="w-full">
                <p className="nunitomedium text-[14px] text-[#2B2F42] leading-[20px] text-left">
                  How many years of work experience do you have with HTML and
                  CSS?*
                </p>
                <input
                  type="text"
                  className="block w-full lg:w-[421px] h-[36px] p-2 border-2 border-[#B0B0B0] bg-transparent rounded-[10px] mt-2"
                  placeholder=""
                />
              </div>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
              />
              <div className="w-full">
                <p className="nunitomedium text-[14px] text-[#2B2F42] leading-[20px] text-left">
                  How many years of work experience do you have with
                  Prototyping?*
                </p>
                <input
                  type="text"
                  className="block w-full lg:w-[421px] h-[36px] p-2 border-2 border-[#B0B0B0] bg-transparent rounded-[10px] mt-2"
                  placeholder=""
                />
              </div>
            </label>

            <label className="flex items-center w-full">
              <input
                type="checkbox"
                className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
              />
              <div className="w-full">
                <p className="nunitomedium text-[14px] text-[#2B2F42] leading-[20px] text-left">
                  What is your expectations of Salary?*
                </p>
                <input
                  type="text"
                  className="block w-full lg:w-[421px] h-[36px] p-2 border-2 border-[#B0B0B0] bg-transparent rounded-[10px] mt-2"
                  placeholder=""
                />
              </div>
            </label>
          </div>

          <div className="space-y-4 justify-center items-center">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
              />
              <div>
                <p className="nunitomedium text-[14px] text-[#2B2F42] leading-[20px] text-left">
                  Have you completed the following level of education:
                  Bachelor’s Degree?*
                </p>
                <div className="mt-2 space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="education"
                      className="form-radio h-5 w-5 text-red-600"
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="education"
                      className="form-radio h-5 w-5 text-red-600"
                    />
                    <span className="ml-2">No</span>
                  </label>
                </div>
              </div>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
              />
              <div>
                <p className="nunitomedium text-[14px] text-[#2B2F42] leading-[20px] text-left">
                  Are you legally authorized to work in Germany?*
                </p>
                <div className="mt-2 space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="authorized"
                      className="form-radio h-5 w-5 text-red-600"
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="authorized"
                      className="form-radio h-5 w-5 text-red-600"
                    />
                    <span className="ml-2">No</span>
                  </label>
                </div>
              </div>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
              />
              <div>
                <p className="nunitomedium text-[14px] text-[#2B2F42] leading-[20px] text-left">
                  Will you now, or in the future, require sponsorship for
                  employment visa status (e.g.)?*
                </p>
                <div className="mt-2 space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="visa"
                      className="form-radio h-5 w-5 text-red-600"
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="visa"
                      className="form-radio h-5 w-5 text-red-600"
                    />
                    <span className="ml-2">No</span>
                  </label>
                </div>
              </div>
            </label>

            {showQuestionInput && (
              <label className="flex flex-col items-center">
                <div className="w-full flex items-center">
                  <input
                    type="checkbox"
                    className="h-[20px] w-[20px] mr-4 mt-1 checkbox shrink-0"
                  />
                  <input
                    type="text"
                    className="block w-[530px] h-[36px] p-2 border-2 border-[#B0B0B0] bg-transparent rounded-[10px] mt-2"
                    placeholder="Write your question here."
                  />
                </div>
              </label>
            )}

            <div className="w-full flex justify-end">
              <button
                onClick={handleShowQuestionInput}
                className="bg-[#FE0000] text-white text-[14px] w-[130px] h-[50px] rounded-lg mt-2 ml-auto mr-0 lg:mr-12"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <button onClick={handleNext}>
          <img
            src="/media/svg/general/ileriok.svg"
            alt="Next"
            className="w-30 h-30"
          />
        </button>
      </div>
    </div>
  );
};

export default CreateJobPost3;
